<template>
  <div class="tack-tooptip">
    <div v-interact class="header">
      <span class="title">{{$t('operation.Hint')}}</span>
      <span class="iconfont icon-guanbi" @click="$emit('closeTip')" />
    </div>
    <div class="body">
      <span class="tip">确定一键起飞吗？</span>
      <!-- {{lineName}} -->
    </div>
    <div class="form_btn">
      <div class="btn_cancel" @click="$emit('closeTip')">{{$t('operation.Cancel')}}</div>
      <div class="btn_send" @click="$emit('confirmTip')">{{$t('operation.Confirm')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlightTaskTooptip',
  data() {
    return {};
  },

  mounted() {},

  methods: {
    confirm() {}
  }
};
</script>

<style lang="scss" scoped>
.tack-tooptip {
  width: 323px;
  height: 170px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #3bc1e5;
  box-sizing: content-box;
  position: absolute;
  top: calc(50% - 450px);
  left: calc(50% - 450px);
  z-index: 999;
  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px 4px 0 0;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }
    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .body {
    height: calc(100% - 85px);
    width: 317px;
    margin: 0 auto;
    box-sizing: border-box;
    border-bottom: 1px solid #494949;
    border-top: 1px solid #494949;
    display: flex;
    justify-content: center;
    align-items: center;
    .tip {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #b3bbc5;
      letter-spacing: 0;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
  .form_btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    position: absolute;
    bottom: 10px;
    width: 317px;
    .btn_cancel,
    .btn_send {
      box-sizing: border-box;
      height: 32px;
      padding: 0 20px 0 20px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
