<template>
  <div class="cpt-observe-stat-box">
    <div class="stat-box-inner">
      <!-- <div class="filed">
        <div class="label">海拔</div>
        <div class="value">{{ data.altitude || 0 }}</div>
        <div class="unit">m</div>
      </div>
      <div class="filed">
        <div class="label">{{$t('home.height')}}</div>
        <div class="value">{{ data.height || 0 }}</div>
        <div class="unit">m</div>
      </div> -->
      <div class="filed">
        <div class="label">{{$t('home.flightTime')}}</div>
        <div class="value">{{ data.flyTime || 0 }}</div>
        <div class="unit">min</div>
      </div>
      <div class="filed">
        <div class="label">{{$t('home.flightMileage')}}</div>
        <div class="value">
          {{ parseInt(data.flyDistance) || 0 }}
        </div>
        <div class="unit">m</div>
      </div>
      <div class="filed">
        <div class="label">{{$t('home.startingDistance')}}</div>
        <div class="value">
          {{ data.distanceToHome || 0 }}
        </div>
        <div class="unit">m</div>
      </div>
      <div class="filed">
        <BatteryIcon :num="parseInt(data.battaryRemain)" />
        <div class="label" style="margin-left: 4px">{{$t('home.electricQuantity')}}</div>
        <div class="value">
          {{ parseInt(data.battaryRemain) || 0 }}
        </div>
        <div class="unit">%</div>
      </div>
    </div>
  </div>
</template>

<script>
import BatteryIcon from './battery_icon';
export default {
  components: {
    BatteryIcon
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-observe-stat-box {
  height: 45px;
  // position: relative;
  // width: 650px;
  // left: 115px;
  // &::before {
  //   content: " ";
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   height: 1px;
  //   background-image: linear-gradient(
  //     270deg,
  //     rgba(0, 117, 221, 0) 0%,
  //     #23b8ff 50%,
  //     rgba(0, 117, 221, 0) 100%
  //   );
  // }
  .stat-box-inner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 58px;
    .filed {
      display: flex;
      align-items: center;
      // margin-right: 10px;
      .label {
        font-family: Microsoft YaHei;
        font-size: 10px;
        color: #b3bbc5;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        white-space: nowrap;
      }
      .value {
        font-family: UniDreamLED;
        font-size: 24px;
        color: #08ffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        margin: 0 4px;
        // width: 70px;
        overflow: hidden;
        white-space: nowrap;
      }
      .unit {
        font-family: UniDreamLED;
        font-size: 14px;
        margin-right: 5px;
        color: #c9d2db;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
    }
  }
}
</style>
