<template>
  <div
    class="cpt-observe-mspace-dashboard"
    :class="{ collapse, hangar: currentEagle }"
  >
    <!-- <div class="bd-content-left"> -->
    <!-- <Battery :data="battery" /> -->
    <!-- <div class="bd-content-left-top-bd" />
      <div class="right-content-box">
        <Stat :data="stat" />
        <div class="left-container">
          <Speed :data="speed" />
          <Height :data="height" />
          <Pitchyaw :data="pitchyaw" />
        </div>
      </div>
    </div>-->

    <div v-interact class="bd-content-right">
      <div class="centent-box">
        <div
          class="hd-title"
          :class="
            show_ms == 2 ? 'bg-right' : show_ms == 1 ? 'bg-left' : 'bg-middle'
          "
          @click="collapse = !collapse"
        >
          <div
            :title="title"
            class="hd-title_tc"
            @click="
              show_ms = 1;
              $bus.$emit('clickUavTitle');
            "
          >
            <!-- <el-tooltip
              :content="flightInfo.deviceName"
              placement="top"
            >
              <span> {{ flightInfo.deviceName || "无架次" }} </span>
            </el-tooltip>-->
            <span>飞行操作</span>
          </div>
          <div class="hd-title_tc hd-title_r" @click="$emit('Back')">
            <span>{{$t('operation.Close')}}</span>
          </div>
          <!-- <div
            class="hd-title_ms"
            @click="show_ms = 3"
          >空中操作</div>
          <div
            class="hd-title_ms"
            @click="show_ms = 2"
          >云台控制</div>-->
        </div>

        <div class="drone-operation">
          <!-- 鹰巢操作 -->
          <div
            v-if="$eagleNestUav && showEagleNestMenu === 1"
            class="drone-operation-left"
          >
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 1 ? true : false }"
              @click="onAccess"
            >
              <img
                v-if="isAccess"
                src="~@/assets/uavApplications/drone-operation/定位.png"
                alt
              />
              <img
                v-else
                src="~@/assets/uavApplications/drone-operation/轨迹.png"
                alt
              />
              <div v-if="isAccess">入舱</div>
              <div v-else>出舱</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 2 ? true : false }"
              @click="onCharge"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/暂停飞行.png"
                alt
              />
              <div v-if="isCharge">结束充电</div>
              <div v-else>开始充电</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 3 ? true : false }"
              @click="onPreheat"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/一键起飞.png"
                alt
              />
              <div>预热</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 4 ? true : false }"
              @click="onHibernate"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/一键返航.png"
                alt
              />
              <div>休眠</div>
            </div>
            <div
              v-if="$uav || ($eagleNestUav && showEagleNestMenu === 1)"
              class="drone-operation-item"
              :class="{ active: selectOperation === 5 ? true : false }"
              @click="onOperationLog(5)"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/operationLog.png"
                alt
              />
              <div>运行日志</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 6 ? true : false }"
              @click="onExitProcess"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/安全降落.png"
                alt
              />
              <div>退出流程</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 7 ? true : false }"
              @click="onLand"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/安全降落.png"
                alt
              />
              <div>运行监测</div>
            </div>
          </div>
          <!-- 无人机操作 -->
          <div v-else class="drone-operation-left">
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 1 ? true : false }"
              @click="onPosition"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/定位.png"
                alt
              />
              <div>定位</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 2 ? true : false }"
              @click="onSwitchAirline"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/轨迹.png"
                alt
              />
              <div>轨迹</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 3 ? true : false }"
              @click="onPauseFly"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/暂停飞行.png"
                alt
              />
              <div v-if="isPause">暂停飞行</div>
              <div v-else>继续飞行</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 4 ? true : false }"
              @click="onTakeOff"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/一键起飞.png"
                alt
              />
              <div>一键起飞</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 5 ? true : false }"
              @click="onReturnFlight"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/一键返航.png"
                alt
              />
              <div>一键返航</div>
            </div>
            <div
              class="drone-operation-item"
              :class="{ active: selectOperation === 6 ? true : false }"
              @click="onLand"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/安全降落.png"
                alt
              />
              <div>安全降落</div>
            </div>
            <div
              v-if="$uav || $eagleNestUav"
              class="drone-operation-item"
              :class="{ active: selectOperation === 7 ? true : false }"
              @click="onPointingFlight"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/指点飞行.png"
                alt
              />
              <div>指点飞行</div>
            </div>
            <!-- <div
              v-if="$uav"
              class="drone-operation-item"
              :class="{ active: selectOperation === 11 ? true : false }"
              @click="onFlyModel(11)"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/flyModel.png"
                alt
              />
              <div>飞行模式</div>
            </div> -->
            <div
              v-if="$uav"
              class="drone-operation-item"
              :class="{ active: selectOperation === 10 ? true : false }"
              @click="onOperationLog(10)"
            >
              <img
                src="~@/assets/uavApplications/drone-operation/operationLog.png"
                alt
              />
              <div>运行日志</div>
            </div>
            <div
              @click="KeyboardControl(13)"
              :class="{ active: selectOperation === 13 ? true : false }"
              v-if="$uav"
              class="drone-operation-item"
            >
              <img src="~@/assets/uavApplications/drone-operation/jp.png" />
              <div>键盘</div>
            </div>
            <template v-if="$uav">
              <div
                v-if="modelType == '手动模式'"
                class="drone-operation-item"
                :class="{ active: selectOperation === 8 ? true : false }"
                @click="modeAuto"
              >
                <img
                  src="~@/assets/uavApplications/drone-operation/手动.png"
                  alt
                />
                <div>手动</div>
              </div>
              <div
                v-else
                class="drone-operation-item"
                :class="{ active: selectOperation === 9 ? true : false }"
                @click="unlock()"
              >
                <!-- @click="modeManual" -->
                <img
                  src="~@/assets/uavApplications/drone-operation/手动.png"
                  alt
                />
                <div>自动</div>
              </div>
            </template>
            <template v-if="$uav">
              <div
                class="drone-operation-item"
                :class="{ active: selectOperation === 8 ? true : false }"
              >
                <div
                  class="w50 h50 cp"
                  title="强制切换内场权限，前提是外场遥控器丢失"
                  @click="isRcConnectingDialog"
                >
                  <img
                    v-if="uavRealTimeData && uavRealTimeData.rcState == 1"
                    class="w25 mt10 h25 dib cp"
                    src="~@/assets/within.svg"
                    alt="#"
                  />
                  <img
                    v-else
                    class="w25 mt10 h25 dib cp"
                    src="~@/assets/Outside.svg"
                    alt="#"
                  />
                </div>
                <div class="mb3">内场控制</div>
              </div>
            </template>
          </div>
          <!-- <div class="drone-operation-right">
            <div class="title">挂载控制</div>
            <div class="right-on-operation">
              <div
                @click="onOperation=false"
                class="right-on-operation-left"
                :class="{active:onOperation?false:true}"
              >锁定</div>
              <div
                @click="onOperation=true"
                class="right-on-operation-right"
                :class="{active:onOperation?true:false}"
              >控制</div>
            </div>
            <div @click="operation" id="right-click-operation"></div>
            <div class="right-bottom-operation">
              <div @click="clickMount(5)" class="bottom-operation-item">放大</div>
              <div @click="clickMount(6)" class="bottom-operation-item">缩小</div>
              <div @click="clickMount(7)" class="bottom-operation-item">拍照</div>
              <div @click="clickMount(8)" class="bottom-operation-item">录像</div>
            </div>
          </div>-->
        </div>
        <!-- <Control
          :show_ms="show_ms"
          :flight-info="flightInfo"
          :now-data="data"
          @fn="(e) => $emit('fn', e)"
        />-->
      </div>
    </div>
    <el-dialog
      :visible.sync="safetyNotice"
      width="30%"
      :modal-append-to-body="true"
    >
      <div class="endrenwu">
        <div class="tishiyu">温馨提示</div>
        <div class="queding">
          请确定是否进行
          <span style="color: red">{{ modelType }}</span
          >切换,目前无人机处于
          <span style="color: red">{{
            rcChannelStateList[rcChannelState]
          }}</span
          >状态
        </div>
        <div class="btn_kuang">
          <div class="btn btn_lan" @click="safetyNotice = false">{{$t('operation.Cancel')}}</div>
          <div style="width: 20px"></div>
          <div class="btn btn_lv" @click="model_qr">{{$t('operation.Confirm')}}</div>
        </div>
      </div>
    </el-dialog>
    <!-- 强制切换外场权限 -->
    <el-dialog
      title
      :visible.sync="isRcConnectingType"
      width="30%"
      :append-to-body="true"
      style="margin-top: 20vh"
    >
      <div class="endrenwu">
        <div class="tishiyu">温馨提示</div>
        <div v-if="isChange == 1" class="queding">
          当前油门值为
          <span style="color: red">{{ ygValue.toFixed(2) || 0 }}</span
          >,请调到中位(470-530)
        </div>
        <div v-if="isChange == 2" class="queding">
          摇杆已处于中位，即将切换内场权限
        </div>
      </div>
    </el-dialog>
    <!-- 键盘控制飞机 -->
    <!-- <keyControl
      v-if="keyControlFlag"
      @close="
        keyControlFlag = false;
        selectOperation = null;
      "
    ></keyControl> -->
  </div>
</template>

<script>
import Stat from "./stat";
// import Battery from "./battery";
import Speed from "./speed";
import Height from "./height";
import Pitchyaw from "./pitchyaw";
import Prevent from "./prevent";
import keyControl from "./keyControl";

// import Airway from "./airway";
import Control from "./control";
// import OperationLog from "../operationLog";
import OperationMonitoring from "../operationMonitoring";
import { mapState } from "vuex";
import { Control_API } from "@/api";
let Point = null;
import mixinJoystick from "./mixins/joystick";
import { number } from "echarts";
let handler = null;
export default {
  components: {
    Stat,
    // Battery,
    Speed,
    Height,
    Pitchyaw,
    Prevent,
    // Airway,
    Control,
    // OperationLog,
    OperationMonitoring,
    keyControl,
  },
  mixins: [mixinJoystick],
  inject: ["change_active", "flight_info"],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    batteryData: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: "",
    },
    async_airway_drd: {
      type: Function,
      default: () => () => {},
    },
  },
  data() {
    return {
      selectOperation: 0,
      modelType: "自动模式",
      isRcConnectingType: false, // 强制切换状态为内场权限 提示框
      ygValue: 0,
      isChange: 0,
      isSend: false,
      collapse: true,
      flightInfo: {},
      show_ms: 1,
      onOperation: false,
      isPause: true, // 暂时飞行
      showOperationLog: false,
      isCharge: false, // 暂停充电
      isAccess: false, // 出入舱
      FlyModelFlag: true, // 飞行模式操作框
      rcChannelState: null, // rcChannelState为0时 处于中位 大于处于高位，小于0处于低位
      rcChannelStateList: {
        0: "摇杆中位",
        "-98": "摇杆外场权限",
        "-99": "摇杆未连接",
        1: "俯仰偏前",
        "-1": "俯仰偏后",
        "-2": "横滚偏左",
        2: "横滚偏右",
        3: "油门偏高",
        "-3": "油门偏低",
        4: "航向偏右",
        "-4": "航向偏左",
      },
      safetyNotice: false, // 安全降落提示框
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    keyControlFlag() {
      return this.$store.state.uavApplications.keyControlFlag;
    },
    stat() {
      const { data } = this;
      return {
        // altitude: data && data.gps ? data.gps.absoluteAlt : 0,
        battaryRemain: data && data.battaryRemain ? data.battaryRemain : 0,
        flyTime: data
          ? ((data.flightTime || 0.1) / 1000).toFixed(2).replace(".", ":")
          : 0,
        flyDistance:
          data && data.flightDistance ? data.flightDistance.toFixed(2) : 0,
        distanceToHome:
          data && data.distanceToHome ? data.distanceToHome.toFixed(2) : 0,
      };
    },
    battery() {
      return this.batteryData;
    },
    speed() {
      const { data } = this;
      // return {
      //   airSpeed: data ? data.airspeed : 0,
      //   groundSpeed: data ? data.groundSpeed : 0,
      // };
      return {
        airSpeed: data ? data.airSpeed : 0,
        groundSpeed: data ? data.groundSpeed : 0,
      };
    },
    height() {
      const { data } = this;
      // return {
      //   altitude: data && data.gps ? Math.abs(data.gps.absoluteAlt) : 0,
      //   height: data && data.gps ? Math.abs(data.gps.relativeAlt) : 0,
      // };
      return {
        height: data ? Math.abs(data.height) : 0,
        altitude: data ? data.altitude : 0,
      };
    },
    pitchyaw() {
      const { data } = this;
      return {
        yaw: data ? data.yaw : 0,
        roll: data ? data.roll : 0,
        pitch: data ? data.pitch : 0,
        climbRate: data ? data.climbRate : 0,
      };
    },
    flightTask() {
      return this.flight_info();
    },
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    currentEagle() {
      return this.$store.state.uavApplicationsEagleNest.currentEagle;
    },
    $eagleNestUav() {
      return this.$store.state.uavApplicationsEagleNest.uav;
    },

    // 鹰巢是否显示操作菜单
    showEagleNestMenu: {
      get() {
        return this.$store.state.uavApplicationsEagleNest.showMenu;
      },
      set(newVal) {
        this.$store.commit("uavApplicationsEagleNest/setState", {
          key: "showMenu",
          value: newVal,
        });
      },
    },

    // 飞行模式
    flyMode: {
      get() {
        return this.$store.state.uavApplications.flyMode;
      },
      set(val) {
        this.$store.commit("uavApplications/setState", {
          key: "flyMode",
          value: val,
        });
      },
    },
    uavApplications_ygData() {
      return this.$store.state.uavApplications.ygValue;
    },
    uavRealTimeData() {
      return this.$store.state.uavApplications.uavRealTimeData;
    },
  },

  watch: {
    "$store.state.uavApplications.showOperationLog": {
      handler(newval) {
      this.showOperationLog =newval
      },
      deep: true,
    },
    flightTask(val) {
      this.flightInfo = val;
    },
    data(newValue, oldValue) {
      this.data = newValue;
    },
    uavRealTimeData(val) {
      if (val.rcChannelState) {
        // 如果数据值由 0 或者 -99 或者 -98 变为其他状态则提示
        if (
          this.rcChannelState == 0 ||
          this.rcChannelState == "-99" ||
          this.rcChannelState == "-98"
        ) {
          if (
            val.rcChannelState != 0 &&
            val.rcChannelState != "-99" &&
            val.rcChannelState != "-98"
          ) {
            if (
              this.rcChannelStateList &&
              this.rcChannelStateList[val.rcChannelState]
            ) {
              this.$message.warning(
                `当前无人机处于${
                  this.rcChannelStateList[val.rcChannelState]
                } 状态,请谨慎飞行`
              );
            }
          }
        }
        // rcChannelState为0时 处于中位 大于处于高位，小于0处于低位
        this.rcChannelState = val.rcChannelState;
      }
    },
    uavApplications_ygData(val) {
      this.ygValue = val;
      if (val >= 470 && val <= 530) {
        this.isChange = 2;
        setTimeout(() => {
          this.isRcConnectingType = false;
          if (this.uavRealTimeData.rcState != 1 && this.isSend) {
            console.log("权限控制");
            this.isRcConnectingChange();
          }
        }, 1000);
      } else {
        if (this.isSend) {
          this.isRcConnectingType = true;
        }
        this.isChange = 1;
      }
    },
    deep: true,
    // onOperation(newValue, oldValue){
    //   if (newValue === true) {
    //     this.clickOperation()
    //   }else{
    //     this.removeClickOperation()
    //   }
    // }
  },
  mounted() {
    console.log("数据", this.data);
    this.flightInfo = this.flight_info();
    setTimeout(() => (this.collapse = false), 50);
    this.$bus.$on("autoManual", (e) => {
      if (e) {
        this.selectOperation = 0;
      }
    });

    // this.$nextTick(()=>{
    //   this.$bus.$emit("control_ws",9)
    // })
  },
  beforeDestroy() {
    if (Point) {
      window.viewer.entities.remove(Point);
    }
    this.$store.commit("uavApplications/setState", {
      key: "FlyModelFlag",
      value: false,
    });
  },
  methods: {
    closekeyControl() {
      this.selectOperation = 0;
    },
    // 键盘控制
    KeyboardControl() {
      if (this.keyControlFlag) {
        this.selectOperation = null;
        this.$emit("keyControlFlag", false);
      } else {
        this.selectOperation = 13;
      }
      this.$store.commit("uavApplications/setState", {
        key: "keyControlFlag",
        value: this.keyControlFlag ? false : true,
      });
    },
    // 强制切换状态为内场权限
    async isRcConnectingChange() {
      this.isSend = false;
      // 发送强权限指令
      this.$store.dispatch("uavApplications/changeYGPermissions", {
        callback: () => {},
      });
      setTimeout(() => {
        console.log(
          this.uavRealTimeData.rcState,
          "this.uavRealTimeData.rcState"
        );
        if (this.uavRealTimeData.rcState == 1) {
          this.$message.success("已切换内场权限，请使用摇杆控制！");
        } else {
          this.$message.error("摇杆权限切换失败");
          console.log(this.uavRealTimeData.rcState);
        }
      }, 2000);
    },
    async isRcConnectingDialog() {
      // 判断是否有权限
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      if (this.uavRealTimeData.rcState == 1) {
        this.isSend = false;
        this.$message.success("当前处于内场模式，请使用摇杆控制！");
      } else {
        this.unlock();
        this.isSend = true;
      }
    },
    // 手动切自动
    modeAuto() {
      // 判断是否有权限
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      this.modelType = "自动模式";
      this.selectOperation = 9;
      this.model_qr();
    },
    // 自动切手动
    unlock() {
      console.log(this.rcChannelState, "this.rcChannelState");
      // 判断是否有权限
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      // 切换为手动模式
      this.modelType = "手动模式";
      if (this.rcChannelState == null) return false;
      if (this.rcChannelState == 0 || this.rcChannelState == "-98") {
        this.$store.commit("uavApplications/setState", {
          key: "ygValue",
          value: 0,
        });
        this.model_qr();
        this.selectOperation = 8;
      } else {
        this.safetyNotice = true;
      }
    },
    // 切换模式时弹出框确认事件
    model_qr() {
      if (this.modelType == "手动模式") {
        // 打开软件
        console.log("打开软件");
        const a = document.createElement("a");
        a.href = "JoystickTools://";
        a.click();
        setTimeout(() => {
          this.initJoystick(this.$uav);
          // 如果是内场权限就窃案为定点模式 rcState 当前权限状态  1代表内场权限 0 代表外场
          if (this.uavRealTimeData.rcState == 1) {
            // 切换为定点模式
            this.$store.dispatch("uavApplications/modeManual", {
              callback: () => {},
            });
          }
        }, 4000);
      } else if (this.modelType == "自动模式") {
        this.closeJoystick();
        // 切换为航线模式
        this.$store.dispatch("uavApplications/modeAuto", {
          callback: () => {},
        });
      }
      this.safetyNotice = false;
    },
    // 无人机接管更新状态
    async updateTakeOver() {
      await Control_API.updateTakeOver(this.$uav.id);
    },
    // 打开飞行模式
    onFlyModel(status) {
      if (this.selectOperation == status) {
        this.selectOperation = null;
      } else {
        this.selectOperation = status;
      }
      this.FlyModelFlag = !this.FlyModelFlag;
      this.$store.commit("uavApplications/setState", {
        key: "FlyModelFlag",
        value: this.FlyModelFlag,
      });
    },
    // *鹰巢操作
    // 出入舱
    onAccess() {
      this.selectOperation = 1;
      if (!this.isAccess) {
        // 出
        this.$store.dispatch("uavApplicationsEagleNest/outAccess", {
          callback: (isOk) => {
            isOk && this.$message.success("出舱指令已下发");
          },
        });
      } else {
        // 入
        this.$store.dispatch("uavApplicationsEagleNest/inAccess", {
          callback: (isOk) => {
            isOk && this.$message.success("入舱指令已下发");
          },
        });
      }
      this.isAccess = !this.isAccess;
    },
    // 开始充电
    onCharge() {
      // if (this.userInfo.id !== this.$eagleNestUav.currentOperator) { //不是本账号接管的无人机
      //   return this.messageBox()
      // }
      this.selectOperation = 2;
      if (!this.isCharge) {
        // 开始
        this.$store.dispatch("uavApplicationsEagleNest/startCharge", {
          callback: (isOk) => {
            isOk && this.$message.success("开始充电指令已下发");
          },
        });
      } else {
        // 结束
        this.$store.dispatch("uavApplicationsEagleNest/endCharge", {
          callback: (isOk) => {
            isOk && this.$message.success("结束充电指令已下发");
          },
        });
      }
      this.isCharge = !this.isCharge;
    },
    // 预热
    onPreheat() {
      this.selectOperation = 3;
      this.$store.dispatch("uavApplicationsEagleNest/preheat", {
        callback: (isOk) => {
          isOk && this.$message.success("预热指令已下发");
        },
      });
    },
    // 休眠
    onHibernate() {
      this.selectOperation = 4;
      this.$store.dispatch("uavApplicationsEagleNest/hibernate", {
        callback: (isOk) => {
          isOk && this.$message.success("休眠指令已下发");
        },
      });
    },
    // 退出流程
    onExitProcess() {
      this.selectOperation = 6;
      this.$store.dispatch("uavApplicationsEagleNest/exitProcess", {
        callback: (isOk) => {
          isOk && this.$message.success("退出流程指令已下发");
        },
      });
    },

    // 提示
    messageBox() {
      this.$msgbox({
        title: this.$t('operation.Hint'),
        message: "请先接管无人机，然后再操作无人机作业",
        showCancelButton: true,
        confirmButtonText: this.$t('operation.Confirm'),
        cancelButtonText: this.$t('operation.Cancel'),
        beforeClose: (action, instance, done) => {
          done();
        },
      }).then((action) => {});
    },
    // 定位
    onPosition() {
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      this.selectOperation = 1;
      this.$store.dispatch("uavApplications/flyToUavModel");
      this.updateTakeOver();
    },
    // 开关显示航线
    onSwitchAirline() {
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      this.selectOperation = 2;
      this.updateTakeOver();
      this.$store.state.uavApplications.airlineEntity.show =
        !this.$store.state.uavApplications.airlineEntity.show;
    },
    // 暂停飞行
    onPauseFly() {
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      this.selectOperation = 3;
      if (this.isPause) {
        // 暂停
        this.$store.dispatch("uavApplications/pauseFly", {
          callback: (isOk) => {
            isOk && this.$message.success("暂停飞行指令已下发");
          },
        });
      } else {
        this.$store.dispatch("uavApplications/continueFly", {
          callback: (isOk) => {
            isOk && this.$message.success("继续飞行指令已下发");
          },
        });
      }
      this.isPause = !this.isPause;
      this.updateTakeOver();
    },
    // 一键起飞
    onTakeOff() {
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      this.selectOperation = 4;
      if (!this.$store.state.uavApplications.airlineData) {
        this.$message.warning("请选择航线");
        return;
      }
      this.$confirm("请确认是否进行一键起飞操作?", this.$t('home.SafetyConfirmation'), {
        cancelButtonText: this.$t('operation.Cancel'),
        confirmButtonText: this.$t('operation.Confirm'),
        customClass: "uav_controlPane",
        showClose: false,
      }).then(async () => {
        this.$store.dispatch("uavApplications/takeOff", {
          callback: (isOk) => {
            isOk
              ? this.$message.success("发出起飞指令成功")
              : this.$message.warning("发出起飞指令失败");
          },
        });
      });
      this.updateTakeOver();
    },
    // 一键返航
    onReturnFlight() {
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      this.selectOperation = 5;
      this.$confirm("请确认是否进行一键返航操作?", this.$t('home.SafetyConfirmation'), {
        cancelButtonText: this.$t('operation.Cancel'),
        confirmButtonText: this.$t('operation.Confirm'),
        customClass: "uav_controlPane",
        showClose: false,
      }).then((res) => {
        this.$store.dispatch("uavApplications/returnFlight", {
          callback: (isOk) => {
            isOk && this.$message.success("发出返航指令成功");
          },
        });
      });
      this.updateTakeOver();
    },
    // 一键降落
    onLand() {
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      this.selectOperation = 6;
      this.$confirm("请确认是否进行安全降落操作?", this.$t('home.SafetyConfirmation'), {
        cancelButtonText: this.$t('operation.Cancel'),
        confirmButtonText: this.$t('operation.Confirm'),
        customClass: "uav_controlPane",
        showClose: false,
      }).then((res) => {
        this.$store.dispatch("uavApplications/land", {
          callback: (isOk) => {
            isOk && this.$message.success("发出降落指令成功");
          },
        });
      });
      this.updateTakeOver();
    },
    // 指点飞行
    onPointingFlight() {
      if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的无人机
        return this.messageBox();
      }
      this.selectOperation = 7;
      if (this.$uav.network == 1) {
        const viewer = window.viewer;
        // 指点飞行 wgs84
        let position = null;
        this.$message("请点击地图获取目标位置");
        handler = new window.Cesium.ScreenSpaceEventHandler(viewer.canvas);
        handler.setInputAction((movement) => {
          const earthPosition = viewer.camera.pickEllipsoid(
            movement.position,
            viewer.scene.globe.ellipsoid
          );
          const cartographic = Cesium.Cartographic.fromCartesian(
            earthPosition,
            viewer.scene.globe.ellipsoid,
            new Cesium.Cartographic()
          );
          const lng = Cesium.Math.toDegrees(cartographic.longitude);
          const lat = Cesium.Math.toDegrees(cartographic.latitude);
          if (Point) {
            viewer.entities.remove(Point);
          }
          console.log(movement.position, " movement.position");
          // let position = window.viewer.scene.camera.pickEllipsoid(
          //   movement.position
          // );
          (position = Cesium.Cartesian3.fromDegrees(lng, lat, 20)),
            (Point = this.addPoint(window.viewer, position, "目标点"));

          this.$confirm(
            "位置获取成功，请确认是否进行指点模式飞行?",
            "引导飞行确认",
            {
              cancelButtonText: this.$t('operation.Cancel'),
              confirmButtonText: this.$t('operation.Confirm'),
              customClass: "uav_controlPane",
              showClose: false,
            }
          )
            .then(() => {
              console.log(
                {
                  data: {
                    latitude: lat,
                    longitude: lng,
                    speed: 6,
                  },
                  type: 518,
                },
                "指点移动指令下发"
              );
              this.$store.dispatch("uavApplications/routeControl", {
                latitude: lat,
                longitude: lng,
                speed: 6,
                callback: (isOk) => {
                  isOk && this.$message.success("发出指点飞行指令成功");
                },
              });
              handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            })
            .catch(() => {
              this.zdfcFlag = true;
              if (Point) {
                window.viewer.entities.remove(Point);
              }
              handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      } else {
        controlWsClick(14, 7);
      }
      this.updateTakeOver();
    },
    onOperationLog(status) {
      if (this.selectOperation == status) {
        this.selectOperation = null;
      } else {
        this.selectOperation = status;
      }
      this.$store.commit("uavApplications/setState", {
                  key: "showOperationLog",
                  value: !this.$store.state.uavApplications.showOperationLog,
                });
      // this.showOperationLog = !this.showOperationLog;
      this.updateTakeOver();
    },
    addPoint(viewer, position, name) {
      return viewer.entities.add({
        position,
        point: {
          pixelSize: 12,
          color: Cesium.Color.fromCssColorString("#ee0000"),
          outlineColor: Cesium.Color.fromCssColorString("#fff"),
          outlineWidth: 1,
          show: true,
        },
        label: {
          text: name,
          scale: 0.5,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          outlineWidth: 0,
          pixelOffset: new Cesium.Cartesian2(26, -20),
        },
      });
    },
    clickMount(status) {
      this.wsControl(status);
    },
    controlWsClick(select, num) {
      if (select === 21) {
        this.modelType = "手动模式";
      } else if (select === 22) {
        this.selectOperation = 0;
        this.modelType = "自动模式";
      }
      if (this.modelType == "手动模式") {
        this.wsControl(select);
        this.selectOperation = num;
      }
    },
    wsControl(e) {
      this.$nextTick(() => {
        this.$bus.$emit("control_ws", e);
      });
    },
    operation(e) {
      if (this.onOperation) {
        const box = document.getElementById("right-click-operation");
        box.classList.forEach((e) => box.classList.remove(e));
        const centerX = box.offsetWidth / 2;
        const centerY = box.offsetHeight / 2;
        const dx = e.offsetX - centerX;
        const dy = e.offsetY - centerY;
        const theta = Math.atan2(dy, dx);
        const r = Math.sqrt(dx * dx + dy * dy);
        const angle = (theta * 180) / Math.PI;
        if (r < 16) {
          box.classList.add("middle");
          console.log("中");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 16);
          });
          return "中";
        } else if (angle >= -45 && angle < 45) {
          box.classList.add("right");
          console.log("右");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 3);
          });
          return "右";
        } else if (angle >= 45 && angle < 135) {
          box.classList.add("down");
          console.log("下");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 4);
          });
          return "下";
        } else if (angle >= -135 && angle < -45) {
          box.classList.add("up");
          console.log("上");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 1);
          });
          return "上";
        } else {
          box.classList.add("left");
          console.log("左");
          this.$nextTick(() => {
            this.$bus.$emit("control_ws", 2);
          });
          return "左";
        }
      }
    },
    // clickOperation() {
    //   document.getElementById("right-click-operation").addEventListener("click",this.operation)
    //   },
    //   removeClickOperation(){
    //     document.getElementById("right-click-operation").removeEventListener("click",this.operation)
    //   }
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "UnidreamLED";
  src: url("../../../styles/font/UnidreamLED/UnidreamLED.woff");
}

.cpt-observe-mspace-dashboard {
  &.hangar {
    right: 70px;
    bottom: 360px;
  }
  // width: 398px;
  // height: 259px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  display: flex;
  position: absolute;
  right: 660px;
  bottom: 153px;
  border: 1px solid #3bc1e5;
  z-index: 9999;
  // transform: translateX(-50%) translateY(0);
  // transition: 0.3s;
  // &.collapse {
  //   transform: translateX(-50%) translateY(calc(100% - 50px));
  // }
  .hd-title {
    font-family: Microsoft YaHei Bold;
    font-size: 16px;
    // color: #3df1ff;
    letter-spacing: 1px;
    // text-align: center;
    // text-shadow: 0 2px 8px #0067ff;
    font-weight: 700;
    height: 34px;
    line-height: 29px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 98%;
    // padding: 2px 36px 2px 20px;

    .hd-title_tc {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      // color: #a4ff64;
      letter-spacing: 0;
      font-weight: 700;
      width: 180px;
      padding: 2px 0 2px 5px;
      box-sizing: border-box;
      color: #a4ff64;
    }
    .hd-title_r {
      width: 60px;
      padding: 2px 10px;
      cursor: pointer;
    }
    .hd-title_ms {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #f8f8f8;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      width: 32%;
      line-height: 34px;
    }
  }
  .bd-content-left {
    height: 265px;
    width: 600px;
    margin-left: 5px;
    box-sizing: border-box;
    .left-container {
      display: flex;
      justify-content: space-around;
      margin-top: 2px;
      margin-left: 23px;
    }

    .bd-content-left-top-bd {
      height: 8px;
      width: 100%;
      background: url("~@/assets/image/observe/dashboard/left-broder-top.png")
        no-repeat;
    }
    .right-content-box {
      background: url("~@/assets/image/observe/dashboard/left-broder.png")
        no-repeat;
      height: 240px;
    }
  }
  .bd-content-right {
    width: 308px;
    z-index: 1000 !important;
    .centent-box {
      /* background: url('~@/assets/image/observe/dashboard/right-broder.png')
        no-repeat; */
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba(19, 21, 24, 0.7);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
        inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
        inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
      border-radius: 4px;
      .drone-operation {
        width: 100%;
        flex: 1;
        background: #0a0b0d;
        border-radius: 0 4px 4px 4px;
        display: flex;
        padding: 8px;
        box-sizing: border-box;

        // height: 223px;
        .drone-operation-left {
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          gap: 12px;
          .drone-operation-item {
            cursor: pointer;
            width: 64px;
            height: 64px;
            background: #3a4252;
            border-radius: 4px;
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #f8f8f8;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &:hover {
              background: url("~@/assets/uavApplications/drone-operation/Hover.png");
            }
            &.active {
              background: url("~@/assets/uavApplications/drone-operation/Hover.png");
            }
            img {
              width: 32px;
              height: 32px;
            }
            &.more {
              background: #161d2b;
            }
          }
        }
        .drone-operation-right {
          width: 154px;
          height: 209px;
          background: #161d2b;
          border: 1px solid #2b313d;
          border-radius: 4px;
          margin: 8px 6px 6px 0;
          color: #ccc;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .title {
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #b3bbc5;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
          }
          .right-on-operation {
            display: flex;
            font-family: MicrosoftYaHei;
            font-size: 10px;
            color: #c6ccd4;
            letter-spacing: 0;
            font-weight: 400;
            cursor: pointer;
            &-left {
              text-align: center;
              line-height: 20px;
              width: 64px;
              height: 20px;
              background-color: #3c4251;
              border-radius: 30px 0 0 30px;
            }
            &-right {
              text-align: center;
              line-height: 20px;
              width: 64px;
              height: 20px;
              background-color: #3c4251;
              border-radius: 0 30px 30px 0;
            }
            .active {
              color: #0a0b0d;
              background-color: #50abff;
            }
          }
          .right-bottom-operation {
            display: flex;
            flex-wrap: wrap;
            .bottom-operation-item {
              cursor: pointer;
              text-align: center;
              margin: 4px 0 0 4px;
              width: 71px;
              height: 26px;
              line-height: 26px;
              background: #3a4252;
              border-radius: 4px;
              font-family: MicrosoftYaHei;
              font-size: 12px;
              color: #b3bbc5;
              letter-spacing: 0;
              font-weight: 400;
              &:hover {
                background: #50abff;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .right-task {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .task-info {
        font-family: MicrosoftYaHei;
        color: #b3bbc5;
        letter-spacing: 0;
        font-weight: 400;
        .task-title {
          font-size: 12px;
        }
        .task-name {
          font-size: 14px;
        }
      }
      .task-btns {
        .task-btn1 {
          width: 52px;
          height: 32px;
          background: #3a4252;
        }
        .task-btn2 {
          width: 76px;
          height: 32px;
          background: #3a4252;
        }
        .on {
          border: 1px solid #3bc1e5;
          border-radius: 4px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #f8f8f8;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
        .off {
          background: #3a4252;
          border: 1px solid #979797;
          border-radius: 4px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #f8f8f8;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
}

#right-click-operation {
  cursor: pointer;
  width: 93px;
  height: 93px;
  border-radius: 50%;
  background: url("~@/assets/uavApplications/drone-operation/Controller.png")
    no-repeat;
  &.up {
    background: url("~@/assets/uavApplications/drone-operation/Controller-Up.png")
      no-repeat;
  }
  &.down {
    background: url("~@/assets/uavApplications/drone-operation/Controller-Down.png")
      no-repeat;
  }
  &.middle {
    background: url("~@/assets/uavApplications/drone-operation/Controller-Reset.png")
      no-repeat;
  }
  &.left {
    background: url("~@/assets/uavApplications/drone-operation/Controller-Left.png")
      no-repeat;
  }
  &.right {
    background: url("~@/assets/uavApplications/drone-operation/Controller-Right.png")
      no-repeat;
  }
}

.bg-left {
  background: url("~@/assets/image/observe/dashboard/title-right.png") no-repeat;
  background-size: 100% 100%;
}
.bg-middle {
  background: url("~@/assets/image/observe/dashboard/Rectangle.png") no-repeat;
}
.bg-right {
  background: url("~@/assets/image/observe/dashboard/title-left.png") no-repeat;
}
// 弹框样式
.endrenwu {
  width: 100%;
  height: 177px;
  border: 1px solid #70daf9;
  background: rgba(9, 32, 87, 0.7);
}
.queding {
  color: #92d9ff;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 30px 0 0 0;
}
.tishiyu {
  color: #92d9ff;
  font-size: 18px;
  margin: 30px 0 0 0;
  text-align: center;
  width: 100%;
}
.btn_kuang {
  display: flex;
  justify-content: center;
  width: 100%;
}
.btn {
  width: 79px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  font-size: 12px;
  margin: 20px 0 0 0;
  cursor: pointer;
}
.btn_lan {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #606266;
}
.btn_lv {
  border-image: linear-gradient(180deg, #8adaff, rgba(82, 179, 255, 0)) 1 1;
  box-shadow: inset 0 0 5px #00ffff;
  background: rgba(23, 70, 216, 0.2);
  color: #fff;
}
::v-deep.el-dialog__header {
  margin-left: 0;
  padding: 0;
}
::v-deep.el-dialog__body {
  padding: 0;
  z-index: 2023 !important;
}
::v-deep .el-dialog {
  z-index: 2022 !important;
  background: transparent !important;
}
</style>

<style>
.el-message-box__btns{
  display: flex;
}
</style>
