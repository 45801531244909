<template>
  <div class="cpt-uav__avoidance-container-wrap">
    <div class="cpt-uav__avoidance-container" :style="containerStyle">
      <div class="avoidance__dashboard">
        <div class="avoidance_dir" :style="`transform: rotate(${prevent}deg);`" />
      </div>
      <div class="tip">避障</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    containerStyle: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      prevent: 0
    };
  },
  watch: {
    data: {
      handler(value) {},
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin inner_box($imgpath, $top: 0) {
  position: absolute;
  top: $top;
  left: 0;
  right: 0;
  bottom: 0;
  width: 115px;
  height: 115px;
  background: url(./images/#{$imgpath}.svg) no-repeat;
  background-size: 100% 100%;
}
.cpt-uav__avoidance-container-wrap {
  width: 180px;
  padding-top: 10px;
  position: relative;
  &::before {
    width: 1px;
    height: 90px;
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    background-image: linear-gradient(
      206deg,
      rgba(36, 146, 252, 0) 5%,
      #2492fc 56%,
      rgba(36, 146, 252, 0) 100%
    );
    transform: translateY(-50%);
  }
}
.cpt-uav__avoidance-container {
  width: 115px;
  height: 140px;
  box-sizing: border-box;
  margin: 0 auto;
  .avoidance__dashboard {
    width: 115px;
    height: 115px;
    background: url('./images/bg.svg') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .avoidance_dir {
      @include inner_box(dir);
    }
  }
  .tip {
    width: 50px;
    border: 1px solid #b3bbc5;
    border-radius: 10.08px;
    font-size: 10.08px;
    color: #b3bbc5;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    margin: 7px 0px 0px 32px;
  }
}
</style>
