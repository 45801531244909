<template>
  <div class="cpt-dashborad-logcpt">
    <div class="track_item">
      <div v-for="(item, index) in logs" :key="index" class="track_items">
        <span class="isGou" />
        <span class="info" style="margin-right: 10px">{{ item.time }}</span>
        <span class="info">{{ item.log }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['logs'])
  },
  mounted() {
    console.log('Logcpt-->', this.logs);
  }
};
</script>

<style lang="scss" scoped>
.cpt-dashborad-logcpt {
  // width: 373px;
  height: 175px;
  background: #171a1c;
  border-radius: 2px;
  padding: 0 8px;
  box-sizing: border-box;
  .track_item {
    height: 95%;
    overflow-y: scroll;
  }
  .track_items {
    // background: #282b33;
    // border: 1px solid #50abff;
    // box-shadow: inset 0 -1px 0 0 #000000;
    height: 29px;
    line-height: 29px;
    .isGou {
      width: 22px;
      display: inline-block;
    }
    .type {
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #689eea;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      border-radius: 30%;
      border: #689eea 1px solid;
      margin: 0 5px;
    }
    .info {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #eaf6ff;
      letter-spacing: 0;
      font-weight: 400;
    }
    .operate {
      float: right;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #00feff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
</style>
