<template>
  <div class="weather">{{ dateNow }}，监测风速值为10.5m/s,大于最大飞行速度10m/s条件</div>
</template>

<script>
import { Control_API } from '@/api';
export default {
  data() {
    return {
      weatherData: [],
      timer: null,
      dateNow: null
    };
  },
  async created() {
    await this.getWeather24();
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
  methods: {
    async getWeather24() {
      const res = await Control_API.getUavShortForEcast24({
        isCache: true, // 缓存
        lon: 120.09540608,
        lat: 33.31858954
      });
      if (res.code === 200) {
        this.weatherData = res.data;
        console.log(res.data, '测试天气数据');
        this.timer = setInterval(() => {
          this.dateNow = new Date().toLocaleString();
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.weather {
  position: fixed;
  left: 30%;
  top: 5%;
  width: 700px;
  height: 32px;
  line-height: 32px;
  color: #ffffff;
  background: rgba(19, 21, 24, 0.7);
  border: 1px solid rgba(52, 223, 255, 1);
  border-radius: 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  backdrop-filter: blur(4px);
  text-indent: 2%;
}
</style>
