<template>
  <div class="cpt-observe-mspace-dashboard" :class="{ collapse }">
    <div class="bd-content-left">
      <!-- <Battery :data="battery" /> -->
      <div class="bd-content-left-top-bd" />
      <div class="right-content-box">
        <Stat :data="stat" />
        <div class="left-container">
          <Speed :data="speed" />
          <Height :data="height" />
          <Pitchyaw :data="pitchyaw" />
        </div>
      </div>
    </div>
    <div class="bd-content-right">
      <div class="bd-content-right-top-bd" />
      <div class="centent-box">
        <div
          class="hd-title"
          :class="show_ms == 2 ? 'bg-right' : 'bg-left'"
          @click="collapse = !collapse"
        >
          <div
            :title="title"
            class="hd-title_tc"
            :style="{
              opacity: flightInfo.deviceStatus == 1 ? '1' : '0.5',
              color: flightInfo.deviceStatus == 1 ? '#a4ff64' : '#dcdcdc'
            }"
            @click="
              show_ms = 1;
              $bus.$emit('clickUavTitle');
            "
          >
            <el-tooltip :content="flightInfo.deviceName" placement="top">
              <span> {{ flightInfo.deviceName || '无架次' }} </span>
            </el-tooltip>
          </div>
          <div class="hd-title_ms" @click="show_ms = 2">飞行视图</div>
        </div>
        <Control
          :show_ms="show_ms"
          :flight-info="flightInfo"
          :now-data="data"
          @fnGz="handle_directive"
          @fn="handle_zhiling"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Stat from './stat';
// import Battery from "./battery";
import Speed from './speed';
import Height from './height';
import Pitchyaw from './pitchyaw';
import Prevent from './prevent';
// import Airway from "./airway";
import Control from './control';
import { mapState } from 'vuex';

export default {
  inject: ['change_active', 'flight_info'],
  components: {
    Stat,
    // Battery,
    Speed,
    Height,
    Pitchyaw,
    Prevent,
    // Airway,
    Control
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    batteryData: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    async_airway_drd: {
      type: Function,
      default: () => () => {}
    }
  },
  data() {
    return {
      collapse: true,
      flightInfo: {},
      show_ms: 2
    };
  },
  mounted() {
    setTimeout(() => (this.collapse = false), 50);
    this.flightInfo = this.flight_info();
  },
  computed: {
    ...mapState(['userInfo']),

    stat() {
      const { data } = this;
      return {
        // altitude: data && data.gps ? data.gps.absoluteAlt : 0,
        battaryRemain: data && data.battaryRemain ? data.battaryRemain : 0,
        flyTime: data ? ((data.flightTime || 0.1) / 1000).toFixed(2).replace('.', ':') : 0,
        flyDistance: data && data.flightDistance ? data.flightDistance.toFixed(2) : 0,
        distanceToHome: data && data.distanceToHome ? data.distanceToHome.toFixed(2) : 0
      };
    },
    battery() {
      return this.batteryData;
    },
    speed() {
      const { data } = this;
      // return {
      //   airSpeed: data ? data.airspeed : 0,
      //   groundSpeed: data ? data.groundSpeed : 0,
      // };
      return {
        airSpeed: data ? data.airSpeed : 0,
        groundSpeed: data ? data.groundSpeed : 0
      };
    },
    height() {
      const { data } = this;
      // return {
      //   altitude: data && data.gps ? Math.abs(data.gps.absoluteAlt) : 0,
      //   height: data && data.gps ? Math.abs(data.gps.relativeAlt) : 0,
      // };
      return {
        height: data ? Math.abs(data.height) : 0,
        altitude: data ? data.altitude : 0
      };
    },
    pitchyaw() {
      const { data } = this;
      return {
        yaw: data ? data.yaw : 0,
        roll: data ? data.roll : 0,
        pitch: data ? data.pitch : 0,
        climbRate: data ? data.climbRate : 0
      };
    },

    flightTask() {
      return this.flight_info();
    }
  },

  watch: {
    flightTask(val) {
      this.flightInfo = val;
    },
    data(newValue, oldValue) {
      this.data = newValue;
    }
  },
  methods: {
    handle_directive(buffer) {
      const name = this.flightInfo.deviceHardId;
      const { userInfo } = this;
      const username = userInfo.username;
      if (name) {
        const data = {
          // cmdBuffer: "165,8,3,2,29", // 回中
          // cmdBuffer: "165,6,3,3,219", // 上
          // cmdBuffer: "165,6,3,253,70", // 下
          cmdBuffer: buffer.join(','),
          cmdControlType: 7000,
          cmdFunction: null,
          cmdName: 'MMC_Gimbal_Z40', // 测试架上的为Z40S, Z40: MMC_Gimbal_Z40
          cmdState: null,
          cmdValue: null
        };
        this.$emit('Gzfn', {
          data,
          deviceHardId: name,
          state: 1,
          systemCode: 'mmc',
          height: 0,
          idlingTime: 0,
          type: 200,
          // cmdControlType: 102,
          username: username
          // data: {
          //   mountId: name,
          //   payload: buffer,
          // },
        });
      }
    },
    handle_zhiling(e) {
      this.$emit('fnZl', e);
      console.log('eeeeeeeeeeee', e);
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'UnidreamLED';
  src: url('../../../styles/font/UnidreamLED/UnidreamLED.woff');
}

.cpt-observe-mspace-dashboard {
  width: 1018px;
  height: 257px;
  display: flex;
  position: absolute;
  right: 1px;
  bottom: 1px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border: 1px solid #3bc1e5;
  // transform: translateX(-50%) translateY(0);
  // transition: 0.3s;
  // &.collapse {
  //   transform: translateX(-50%) translateY(calc(100% - 50px));
  // }
  .hd-title {
    font-family: Microsoft YaHei Bold;
    font-size: 16px;
    // color: #3df1ff;
    letter-spacing: 1px;
    // text-align: center;
    // text-shadow: 0 2px 8px #0067ff;
    font-weight: 700;
    height: 34px;
    line-height: 29px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 98%;
    // padding: 2px 36px 2px 20px;
    cursor: pointer;
    .hd-title_tc {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      // color: #a4ff64;
      letter-spacing: 0;
      font-weight: 700;
      width: 70%;
      padding: 2px 36px 2px 20px;
      box-sizing: border-box;
    }
    .hd-title_ms {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #f8f8f8;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      width: 30%;
      line-height: 34px;
    }
  }
  .bd-content-left {
    height: 265px;
    width: 600px;
    margin-left: 5px;
    box-sizing: border-box;
    .left-container {
      display: flex;
      justify-content: space-around;
      margin-top: 2px;
      margin-left: 23px;
    }

    .bd-content-left-top-bd {
      height: 8px;
      width: 100%;
      background: url('~@/assets/image/observe/dashboard/left-broder-top.png') no-repeat;
    }
    .right-content-box {
      background: url('~@/assets/image/observe/dashboard/left-broder.png') no-repeat;
      height: 240px;
    }
  }
  .bd-content-right {
    width: 405px;
    height: 257px;
    // margin-left: 3px;

    .bd-content-right-top-bd {
      height: 8px;
      width: 100%;
      background: url('~@/assets/image/observe/dashboard/right-broder-top.png') no-repeat;
    }
    .centent-box {
      background: url('~@/assets/image/observe/dashboard/right-broder.png') no-repeat;
      height: 240px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .right-task {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .task-info {
        font-family: MicrosoftYaHei;
        color: #b3bbc5;
        letter-spacing: 0;
        font-weight: 400;
        .task-title {
          font-size: 12px;
        }
        .task-name {
          font-size: 14px;
        }
      }
      .task-btns {
        .task-btn1 {
          width: 52px;
          height: 32px;
          background: #3a4252;
        }
        .task-btn2 {
          width: 76px;
          height: 32px;
          background: #3a4252;
        }
        .on {
          border: 1px solid #3bc1e5;
          border-radius: 4px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #f8f8f8;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
        .off {
          background: #3a4252;
          border: 1px solid #979797;
          border-radius: 4px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #f8f8f8;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
}
.bg-left {
  background: url('~@/assets/image/observe/dashboard/title-right.png') no-repeat;
}
.bg-right {
  background: url('~@/assets/image/observe/dashboard/title-left.png') no-repeat;
}
</style>
