<template>
  <div class="battery">
    <span
      v-if="num > 0"
      style="border-radius: 1px 0 0 1px; margin-left: 0px"
      :style="num > 10 ? 'backgroundColor: #04cf01' : 'backgroundColor: red'"
    />
    <span v-if="num > 20" />
    <span v-if="num > 40" />
    <span v-if="num > 60" />
    <span v-if="num > 80" style="border-radius: 0 1px 1px 0" />
  </div>
</template>

<script>
export default {
  name: 'BatteryIcon',
  // data() {
  //   return {
  //     num: 0
  //   }
  // }
  props: {
    num: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
*,
*:after {
  box-sizing: border-box;
}

.battery {
  position: relative;
  display: inline-block;
  height: 10px;
  line-height: 10px;
  width: 23px;
  padding: 1px;
  border: 1px solid #979797;
  border-radius: 3px;
  padding: 1px;
  // overflow: hidden;
  span {
    background-color: #04cf01;
    width: 3px;
    height: 6px;
    float: left;
    margin-left: 1px;
    z-index: 9999999999;
  }
  &:after {
    content: '';
    position: absolute;
    height: 3px;
    width: 1px;
    top: 50%;
    right: -2px;
    transform: translateY(-50%);
    /* border-radius: 0 7px 7px 0; */
    background-color: #979797;
  }
}
</style>
