<template>
  <div class="cpt-uav__speed-container">
    <div class="speed__dashboard">
      <div class="air-box">
        <svg width="100%" height="100%">
          <path
            d="M 32,72 A 26,26 0 1 1 114 72"
            stroke="#1890FF"
            fill="none"
            strokeWidth="2"
            :transform="`rotate(-${180 - airSpeed}, 72, 72)`"
          />
        </svg>
        <div class="value">
          <span class="text">{{ airSpeed }}</span>
          <span class="unit">m/s</span>
        </div>
      </div>
      <div class="ground-box">
        <svg width="100%" height="100%">
          <path
            d="M 32,0 A 26,26 0 1 0 114 0"
            stroke="#94D855"
            fill="none"
            strokeWidth="2"
            :transform="`rotate(${180 - groundSpeed}, 72, 0)`"
          />
        </svg>
        <div class="value">
          <span class="text">{{ groundSpeed }}</span>
          <span class="unit">m/s</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      airSpeed: 0,
      groundSpeed: 0
    };
  },
  watch: {
    data: {
      handler(value) {
        if (value) {
          const uavInfo = value;
          if (uavInfo) {
            this.airSpeed = parseInt(uavInfo['airSpeed']) || 0;
            this.groundSpeed = parseInt(uavInfo['groundSpeed']) || 0;
          }
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-uav__speed-container {
  width: 176px;
  position: relative;
  &::before {
    width: 1px;
    height: 90px;
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    background-image: linear-gradient(
      206deg,
      rgba(36, 146, 252, 0) 5%,
      #2492fc 56%,
      rgba(36, 146, 252, 0) 100%
    );
    transform: translateY(-50%);
  }
  .speed__dashboard {
    width: 145px;
    height: 145px;
    background: url('./images/bg.svg') no-repeat;
    background-size: 100% 100%;
    position: relative;
    margin: 0 auto;
    .air-box {
      width: 100%;
      height: 50%;
      position: relative;
      .value {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        color: #08ffff;
        text-align: right;
        font-weight: 400;
        .text {
          width: 52px;
          display: block;
          overflow: hidden;
          text-align: center;
        }
        .unit {
          position: absolute;
          bottom: 0px;
          right: -17px;
          font-size: 12px;
          color: #b3bbc5;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
      }
    }
    .ground-box {
      width: 100%;
      height: 50%;
      position: relative;
      .value {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        color: #94d855;
        text-align: right;
        font-weight: 400;
        .text {
          width: 52px;
          display: block;
          overflow: hidden;
          text-align: center;
        }
        .unit {
          position: absolute;
          bottom: 2px;
          right: -16px;
          font-size: 12px;
          color: #b3bbc5;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
