<!-- livePlayer播放器 -->
<template>
  <div class="cpt-livePlayer">
    <div v-interact class="player_header">
      <div class="title">
        {{ deviceName || $t('home.no') }}
      </div>
      <div v-hover class="iconfont icon-guanbi" @click="close" />
    </div>
    <div class="player_box">
      <div class="player">
        <player :data="aiUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import player from "@/components/video";
import Qs from "qs";
import { AI } from "@/api";
import { mapMutations } from "vuex";

export default {
  components: {
    player,
  },
  props: {
    videosUrl: {
      type: Object,
      default: () => ({
        // vUrl: ''    //视频地址
      }),
    },
    aiVideoInfo: {
      type: Object,
      default: () => ({
        // name: '',              //ai名称
        // detectortype: ''       //ai类目
      }),
    },
    rtmpUrl: {
      type: Object,
      default: () => ({}),
    },
    flightInfo: {
      type: Object,
      default: () => ({
        // id: '',
        // name: '',
        // deviceName: ''
      }),
    },
  },
  data() {
    return {
      value: false,
      aiUrl: {
        vUrl: "",
        videoType: "",
      },
      // flightInfo: null,
      deviceName: "",
      rtmp_url: "",
    };
  },
  //, 'flight_info'
  inject: ["c_player", "c_player_ai"],
  watch: {
    aiVideoInfo(newValue, oldValue) {
      this.getAiVideo();
      console.log(this.flightInfo);
      if (Object.keys(this.aiVideoInfo).length != 0) {
        if (Object.keys(this.flightInfo).length != 0) {
          const deviceName = this.flightInfo.deviceName;
          this.deviceName = "";
          this.deviceName = deviceName + `(AI:` + this.aiVideoInfo.name + `)`;
        }
      }
    },
  },
  mounted() {
    // this.flightInfo = this.flight_info();
    // this.flightInfo = JSON.parse(JSON.stringify(this.flight_info()));
    // this.deviceName = this.flight_info().deviceName;
    console.log(this.flightInfo);
    this.deviceName = this.flightInfo.name;
    if (Object.keys(this.aiVideoInfo).length != 0 && this.deviceName) {
      this.getAiVideo();
      if (Object.keys(this.flightInfo).length != 0) {
        this.deviceName =
          this.deviceName + `(AI:` + this.aiVideoInfo.name + `)`;
      }
    }
  },
  methods: {
    ...mapMutations(["SET_AIURLOBJ"]),

    async close() {
      // 触发事件总线中的事件，并传参数
      await AI.destroyAi(
        Qs.stringify({
          detectortype: this.aiVideoInfo.detectortype,
          videourl: this.aiUrl.vUrl,
        })
      );
      this.$emit("clickCloseAi");
    },
    async getAiVideo() {
      const data = await AI.getAiVideo(
        Qs.stringify({
          videourl: this.videosUrl.vUrl,
          detectortype: this.aiVideoInfo.detectortype,
          task_id: `TMJ_V2_${this.flightInfo.id}`,
        })
      );
      this.aiUrl = {
        vUrl: data.data.video_flv,
        videoType: "flv",
        type: 2,
      };
      console.log(this.aiUrl, "this.aiUrl");
      this.SET_AIURLOBJ({
        vUrl: this.aiUrl.vUrl,
        videoType: "flv",
        type: 2,
      });
      // this.rtmp_url = data.data.video_rtmp;
    },
  },
};
</script>
<style lang="scss" scoped>
.cpt-livePlayer {
  z-index: 999;
  position: absolute;
  top: 18%;
  right: 17.3%;
  width: 563px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #394963, #394963),
    linear-gradient(90deg, #34dfff, #1871d6);
  .player_header {
    height: 31px;
    width: 99%;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
    background: #292c35;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.05),
      inset 0 1px 0 0 rgba(255, 255, 255, 0.15), inset 0 -1px 0 0 #000000;
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #ebf5ff;
    letter-spacing: 0;
    font-weight: 400;
    .close-icon {
      cursor: pointer;
    }
  }
  .player_box {
    width: 563px;

    border-radius: 4px;

    .player {
      width: 99%;

      // height: 380px;
      margin: 0 auto;
    }
    .player_buttom {
      width: 99%;
      margin: 0 auto;
      background: #2d2f2c;
      opacity: 0.8;
      height: 59px;
      display: flex;
      align-items: center;
      .tilte_text_ai {
        width: 40px;
        text-align: center;
      }
      .tilte_text_dz {
        width: 60px;
        text-align: right;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #ffffff;
        line-height: 14px;
        font-weight: 400;
      }
      input {
        width: 470px;
        height: 32px;
        background: #16181b;
        border: 1px solid #000000;
        border-radius: 2px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #d7dbe1;
        letter-spacing: 0;
        font-weight: 400;
        padding: 0 10px;
        box-sizing: border-box;
      }
      .iconfont {
        font-size: 28px;
        margin-left: 18px;
        color: #dedede;
        cursor: pointer;
      }
    }
  }
}
</style>
