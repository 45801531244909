<template>
  <div class="cpt-uav__yaw-container-wrap">
    <div class="cpt-uav__yaw-container" :style="containerStyle">
      <div class="yaw__group">
        <div class="yaw__dashboard">
          <div class="yaw__dir" :style="`transform: rotate(${curr_data.yaw}deg)`" />
          <div class="yaw__sky" />
          <div class="yaw__pitch" />
          <div class="yaw__pitch2" />
          <div class="yaw__pitch3" />
          <div class="yaw__value">
            <span class="text">{{ curr_data.yaw }}</span>
            <span class="unit">°</span>
          </div>
        </div>
        <div class="yaw__info">
          <div class="yaw__info_item">
            <div class="yaw__info_item_title">横滚</div>
            <div class="yaw__info_item_value">
              <span class="text">{{ curr_data.roll }}</span>
              <span class="unit">°</span>
            </div>
          </div>
          <div class="yaw__info_item">
            <div class="yaw__info_item_title">俯仰</div>
            <div class="yaw__info_item_value">
              <span class="text">{{ curr_data.pitch }}</span>
              <span class="unit">°</span>
            </div>
          </div>
          <div class="yaw__info_item">
            <div class="yaw__info_item_title">爬升率</div>
            <div class="yaw__info_item_value">
              <span class="text">{{ curr_data.climbRate }}</span>
              <span class="unit">m/s</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tip">航向</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    containerStyle: {
      type: String,
      default: () => ''
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      curr_data: {}
    };
  },
  watch: {
    data: {
      handler(value) {
        if (value) {
          const uavInfo = value;
          if (uavInfo) {
            this.curr_data = {
              yaw: uavInfo['yaw'] ? parseInt(uavInfo['yaw']) : 0,
              roll: uavInfo['roll'] ? parseInt(uavInfo['roll']) : 0,
              pitch: uavInfo['pitch'] ? parseInt(uavInfo['pitch']) : 0,
              climbRate: uavInfo['climbRate'] ? parseInt(uavInfo['climbRate']) : 0
            };
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin inner_box($imgpath, $top: 0) {
  position: absolute;
  top: $top;
  left: 0;
  right: 0;
  bottom: 0;
  width: 115px;
  height: 115px;
  background: url(./images/#{$imgpath}.svg) no-repeat;
  background-size: 100% 100%;
  transition: 0.3s;
}
.cpt-uav__yaw-container-wrap {
  width: 250px;
  padding-top: 10px;
  position: relative;
  &::before {
    width: 1px;
    height: 90px;
    content: ' ';
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    background-image: linear-gradient(
      206deg,
      rgba(36, 146, 252, 0) 5%,
      #2492fc 56%,
      rgba(36, 146, 252, 0) 100%
    );
    transform: translateY(-50%);
  }
}
.cpt-uav__yaw-container {
  width: 180px;
  height: 140px;
  box-sizing: border-box;
  margin: 0 auto;
  .yaw__group {
    display: flex;
    align-items: center;
    .yaw__dashboard {
      width: 115px;
      height: 115px;
      background: url('./images/bg.svg') no-repeat;
      background-size: 100% 100%;
      position: relative;

      .yaw__dir {
        @include inner_box(dir);
        opacity: 0.7;
      }

      .yaw__sky {
        @include inner_box(sky);
      }

      .yaw__pitch {
        @include inner_box(pitch);
      }

      .yaw__pitch2 {
        top: 5px;
        @include inner_box(pitch2, 5px);
      }

      .yaw__pitch3 {
        @include inner_box(pitch3, 10px);
      }

      .yaw__value {
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #08ffff;
        position: absolute;
        bottom: 36px;
      }
    }

    .yaw__info {
      .yaw__info_item {
        text-align: left;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }

        .yaw__info_item_title {
          font-size: 12px;
          color: #ebf5ff;
          letter-spacing: 0;
          font-weight: 400;
        }

        .yaw__info_item_value {
          font-size: 16px;
          color: #08ffff;
          font-weight: 400;
          display: flex;
          .text {
            // width: 30px;
            display: block;
          }
          .unit {
            font-size: 14px;
            color: #c9d2db;
            font-weight: 400;
            margin-left: 2px;
          }
        }
      }
    }
  }

  .tip {
    width: 50px;
    border: 1px solid #b3bbc5;
    border-radius: 10.08px;
    font-size: 10.08px;
    color: #b3bbc5;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    margin: 7px 0px 0px 32px;
  }
}
</style>
