<!-- livePlayer播放器 -->
<template>
  <div class="cpt-livePlayer">
    <div v-interact class="player_header">
      <div v-if="flightInfo && flightInfo.name" class="title">
        {{ flightInfo.name }}
      </div>
      <div v-else class="title">{{$t('home.no')}}</div>
      <div v-if="flightInfo && flightInfo.name" class="iconfont-group">
        <div
          v-for="item in videosType"
          :key="item"
          v-hover
          class="iconfont"
          @click="changeFlow(item)"
        >
          {{ item }}
        </div>
        <div v-hover class="iconfont icon-guanbi" @click="close" />
      </div>
      <div v-else v-hover class="iconfont icon-guanbi" @click="close" />
    </div>
    <div class="player_box">
      <div class="player">
        <player :data="videosUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import player from '@/components/video/';
export default {
  components: {
    player
  },
  props: {
    videosUrl: {
      type: Object,
      default: () => ({})
    },
    videosType: {
      type: Array,
      default: () => []
    },
    flightInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      value: false,
      aiUrl: {
        vUrl: '',
        videoType: ''
      },
      // flightInfo: null,
      flowType: '' // 1:webRtc,0:hls
    };
  },
  //, 'flight_info'
  inject: ['c_player', 'c_player_ai'],
  mounted() {
    // let obj2 = JSON.parse(JSON.stringify(obj1));
    // this.flightInfo = JSON.parse(JSON.stringify(this.flight_info()));
    // this.$bus.$on('changeFlightInfo', (e) => {
    //   this.flightInfo = e
    // });
    // this.$emit("changeFlowType",this.videosType[0])
    // this.flowType = this.videosType[0]
    this.$emit('changeFlowType', this.videosType[0]);
    this.flowType = this.videosType[0];
  },
  methods: {
    close() {
      this.c_player.isShow = false;
    },
    changeFlow(e) {
      this.flowType = e;
      this.$emit('changeFlowType', this.flowType);
      this.$bus.$emit('changeFlow', this.flowType);
    }
  }
};
</script>
<style lang="scss" scoped>
.cpt-livePlayer {
  z-index: 999;
  position: absolute;
  transform: translateX(50%);
  top: 12vh;
  left: 25.3vw;
  width: 563px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #394963, #394963),
    linear-gradient(90deg, #34dfff, #1871d6);
  .player_header {
    height: 31px;
    width: 99%;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
    background: #292c35;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.05), inset 0 1px 0 0 rgba(255, 255, 255, 0.15),
      inset 0 -1px 0 0 #000000;
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #ebf5ff;
    letter-spacing: 0;
    font-weight: 400;
    .close-icon {
      cursor: pointer;
    }
    .iconfont-group {
      /* width: 30%; */
      width: 50px;
      display: flex;
      justify-content: space-between;
      .icon-RTC_HLS2,
      .icon-RTC_HLS1 {
        margin-right: 15px;
      }
    }
    .iconfont-group2 {
      width: 0px;
    }
  }
  .player_box {
    width: 563px;
    height: 318px;
    border-radius: 4px;

    .player {
      width: 99%;
      height: 99%;
      // height: 380px;
      margin: 0 auto;
    }
    .player_buttom {
      width: 99%;
      margin: 0 auto;
      background: #2d2f2c;
      opacity: 0.8;
      height: 59px;
      display: flex;
      align-items: center;
      .tilte_text_ai {
        width: 40px;
        text-align: center;
      }
      .tilte_text_dz {
        width: 60px;
        text-align: right;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #ffffff;
        line-height: 14px;
        font-weight: 400;
      }
      input {
        width: 470px;
        height: 32px;
        background: #16181b;
        border: 1px solid #000000;
        border-radius: 2px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #d7dbe1;
        letter-spacing: 0;
        font-weight: 400;
        padding: 0 10px;
        box-sizing: border-box;
      }
      .iconfont {
        font-size: 28px;
        margin-left: 18px;
        color: #dedede;
        cursor: pointer;
      }
    }
  }
}
</style>
