<template>
  <div class="applications_uavContent">
    <div v-if="showTaskBox" class="taskBox" :class="{ taskBox2: isShow }">
      <div v-interact class="head">
        <div class="left">
          <span class="title">{{$t('flightApplication.libraryOfTasks')}}</span>
        </div>
        <div class="right">
          <span v-hover class="iconfont icon-guanbi" @click="cancel" />
        </div>
      </div>
      <div class="taskBox_body">
        <div class="flight-line-list">
          <div class="tb-box">
            <div class="tb-hd-box">
              <div class="tb-hd">{{$t('flightApplication.TaskName')}}</div>
              <div class="tb-hd">{{$t('flightApplication.ExecutionStatus')}}</div>
              <div class="tb-hd">{{$t('flightApplication.ExecutionTime')}}</div>
              <div class="tb-hd">{{$t('operation.Operation')}}</div>
            </div>
            <div class="tb-bd-box">
              <div v-for="item in list" :key="item.id" class="tb-tr">
                <div class="tb-task-item" @click="detailsItem(item)">
                  <div class="td">
                    {{ item.taskName || $t('home.no') }}
                  </div>
                  <div
                    class="td"
                    :style="{
                      color: item.taskStatus == 2 ? '#FA8100 ' : '#C6CCD4',
                    }"
                  >
                    {{ taskTypeName(item) || $t('home.no') }}
                  </div>
                  <div class="td">
                    {{ item.expectedtime || $t('home.no') }}
                  </div>
                  <div class="td">
                    <el-tooltip
                      v-hover
                      v-pms="['飞行任务', '详情']"
                      effect="dark"
                      :content="$t('operation.Detail')"
                      placement="top"
                    >
                      <img
                        src="~@/assets/image/icons/details.png"
                        style="color: #a16869; width: 26px; height: 26px"
                        @click.stop="detailsItem(item)"
                      />
                    </el-tooltip>
                    <el-tooltip
                      v-pms="['飞行任务', '一键任务']"
                      v-hover
                      effect="dark"
                      content="一键任务"
                      placement="top"
                    >
                      <!-- taskStatus 0待执行 1执行中 2完成 executeButton判断是否有执行权限 -->
                      <img
                        v-if="item.taskStatus === 0 && !item.executeButton"
                        src="~@/assets/image/icons/one_click_task.png"
                        style="color: #a16869; width: 26px; height: 26px"
                        @click.stop="oneClickTask(item)"
                      />
                    </el-tooltip>
                  </div>
                </div>

                <div
                  class="details-body"
                  :class="item.showTaskDetails ? 'open' : ''"
                >
                  <el-tabs v-if="taskDetails" v-model="taskTypeId">
                    <el-tab-pane label="任务详情" name="0" />
                    <el-tab-pane label="任务流程" name="1" />
                  </el-tabs>
                  <div v-if="taskTypeId === '0'" class="body">
                    <div class="item">
                      <div class="label">任务名称</div>
                      <div class="value">
                        {{ taskDetails && (taskDetails.taskName || $t('home.no')) }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">任务类型</div>
                      <div class="value">
                        {{ taskDetails && (taskDetails.processName || $t('home.no')) }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">航线名称</div>
                      <div class="value">
                        {{ taskDetails && (taskDetails.routeName || $t('home.no')) }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">执行状态</div>
                      <div class="value">
                        {{
                          taskDetails && (taskTypeName(taskDetails) || $t('home.no'))
                        }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">责任机构</div>
                      <div class="value">
                        {{ taskDetails && (taskDetails.orgName || $t('home.no')) }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">无人机</div>
                      <div class="value">
                        {{
                          taskDetails && (taskDetails.deviceHardId || $t('home.no'))
                        }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">任务描述</div>
                      <div class="value">
                        {{ taskDetails && (taskDetails.remark || $t('home.no')) }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">创建时间</div>
                      <div class="remark">
                        {{ taskDetails && (taskDetails.addtime || $t('home.no')) }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      taskTypeId === '1' &&
                      taskDetails.taskOperators &&
                      taskDetails.taskOperators.length
                    "
                    class="flow-chart"
                  >
                    <div class="example">
                      <div class="item">
                        <div class="example-line" />
                        <div class="example-label">正常或通过</div>
                      </div>
                      <div class="item">
                        <div class="example-line back" />
                        <div class="example-label">异常或驳回</div>
                      </div>
                    </div>
                    <div
                      v-for="(item, index) in taskDetails.taskOperators"
                      :key="item.id"
                      class="chart-body"
                    >
                      <div
                        v-if="
                          item.functionType === 0 || item.functionType === 2
                        "
                        class="pie"
                        :class="{
                          active:
                            item.isOperate === 0 || item.isOperate === 2
                              ? true
                              : false,
                        }"
                        @click="openTaskDetailsList(item)"
                      >
                        <div class="pie-body">
                          <div
                            class="label-functionType"
                            :title="typeFilter(item.functionType)"
                          >
                            {{ typeFilter(item.functionType) }}
                          </div>
                          <div class="label-orgName" :title="item.operatorName">
                            {{ item.operatorName }}
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="item.functionType === 1"
                        class="diamond-pro"
                        @click="taskDetailsList = item"
                      >
                        <div class="diamond-body">
                          <div
                            class="label-functionType"
                            :title="typeFilter(item.functionType)"
                          >
                            {{ typeFilter(item.functionType) }}
                          </div>
                          <div class="label-orgName" :title="item.operatorName">
                            {{ item.operatorName }}
                          </div>
                        </div>
                        <div
                          class="diamond"
                          :class="{
                            active:
                              item.isOperate === 0 || item.isOperate === 2
                                ? true
                                : false,
                          }"
                        />
                      </div>
                      <!-- 驳回线条 -->
                      <!-- :style="{ height: `${90 + 43 + (90 * 1.414) / 2 - 90 / 2}px` }" -->
                      <div
                        v-if="item.functionType === 1 && item.isOperate === 3"
                        class="arrow-back"
                        :style="{ height: heightComputed(item, index) }"
                      >
                        <div class="top-box">
                          <div class="head" />
                          <div
                            class="line"
                            style="height: 3px"
                            :style="{
                              width: `calc(100% + (90px) * 1.414 / 2 - (90px) / 2 - 8px)`,
                            }"
                          />
                        </div>
                        <div class="middle-box line" />
                        <div class="bottom-box line" />
                      </div>

                      <div
                        v-if="index !== taskDetails.taskOperators.length - 1"
                        class="arrow"
                      >
                        <div class="arrow-line" />
                        <div class="arrow-head" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="taskTypeId === '1' && !taskDetails.taskOperators"
                  >
                    <el-empty description="暂无流程" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="taskDetailsList"
      class="taskDetails"
      :class="{ taskBox2: isShow }"
    >
      <div v-interact class="head">
        <div class="left">
          <span class="title">任务流程详情</span>
        </div>
        <div class="right">
          <span
            v-hover
            class="iconfont icon-guanbi"
            @click="closeTaskDetails"
          />
        </div>
      </div>
      <div class="body">
        <div class="item">
          <div class="label">流程节点名称</div>
          <div class="value">{{ taskDetailsList.labelName || $t('home.no') }}</div>
        </div>
        <div class="item">
          <div class="label">节点功能</div>
          <div class="value">
            {{ functionTypeList[taskDetailsList.functionType].label || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">指派操作员</div>
          <div class="value">{{ taskDetailsList.operatorName || $t('home.no') }}</div>
        </div>
        <div class="item">
          <div class="label">指派类型</div>
          <div class="value">
            {{ operateClassList[taskDetailsList.operateClass].label || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">操作状态</div>
          <div class="value">
            {{ isOperateList[taskDetailsList.isOperate].label || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">实际操作员</div>
          <div class="value">
            {{ taskDetailsList.operateRealUsername || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">实际操作员机构</div>
          <div class="value">
            {{ taskDetailsList.operateRealOgrName || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">操作时间</div>
          <div class="remark">{{ taskDetailsList.operateTime || $t('home.no') }}</div>
        </div>
      </div>
    </div>
    <UavContent
      :task-details="taskDetails"
      :prop-open-operate="propOpenOperate"
      @getTaskList="getTaskListEnd"
      @oneClickTask="(e) => oneClickTask(e)"
    />
  </div>
</template>
<script>
// 飞行应用任务库新组件
import { Control_API, Tg, flightTaskAPI, AirLine } from "@/api";
import UavContent from "@/views/uavApplications/components/flight-popup2/flight-uav-control/uav-content/index.vue";
import { mapState } from "vuex";
export default {
  components: { UavContent },
  data() {
    return {
      search: {
        name: "",
      },
      taskDetails: null,
      propOpenOperate: false,
      list: [{}],
      showDetailsId: null,
      showTaskBox: false,
      subplatformInfo: JSON.parse(localStorage.getItem("subplatformInfo")),
      typeGroup: [
        {
          id: 0,
          name: "待执行",
        },
        {
          id: 1,
          name: "执行中",
        },
        {
          id: 2,
          name: "已完成",
        },
      ],
      taskTypeId: "0",
      taskDetailsList: null,
      functionTypeList: [
        {
          value: 0,
          label: "发起",
        },
        {
          value: 1,
          label: "审批",
        },
        {
          value: 2,
          label: "执行",
        },
      ],
      operateClassList: [
        {
          label: "指定用户",
          value: 0,
        },
        {
          label: "指定机构",
          value: 1,
        },
      ],
      isOperateList: [
        {
          value: 0,
          label: "通过",
        },
        {
          value: 1,
          label: "未操作",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "驳回",
        },
      ],
      time: null,
    };
  },

  watch: {
    updataTaskList: {
      handler(value) {
        if (value) {
          this.getTaskList(value);
        }
      },
      deep: true,
      immediate: true,
      // async updataTaskList(val) {
      //     console.log(val, "任务列表数据");
      //   if (val) {
      //     await this.getTaskList(val);
      //     this.showTaskBox = true;
      //   }
    },
  },
  computed: {
    ...mapState(["userInfo", "openLeftNavSilder", "projectData"]),
    ...mapState("subplatform", ["subplatFormStatus"]),
    isShow: {
      get: function () {
        return this.openLeftNavSilder;
      },
      set: function (v) {
        this.SET_OPEN_LEFT_NAV_SILDER(v);
      },
    },
    openTest() {
      return this.$store.state.uavApplications.openTest;
    },
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    updataTaskList() {
      return this.$store.state.uavApplications.TaskList;
    },
  },
  mounted() {
    // getTaskList
    if (!this.time) {
      this.time = setInterval(() => {
        // this.getTaskList();
      }, 2000);
    } else {
      // this.clearTime();
    }
  },
  beforeDestroy() {
    // this.clearTime();
    this.$store.commit("uavApplications/setState", {
      key: "routeId",
      value: false,
    });
  },
  methods: {
    clearTime() {
      if (this.time) {
        clearInterval(this.time);
        this.time = null;
      }
    },
    heightComputed(item, index) {
      const arr = [];
      this.form.taskOperators.map((col, ind) => {
        if (ind > index) return;
        if (col.functionType === 0 || col.functionType === 2) {
          arr.push(90);
        } else if (index > 1 && ind > 1) {
          arr.push(90 * 1.414);
        } else {
          arr.push((90 * 1.414) / 2);
        }
      });
      const height = arr.reduce((old, now) => {
        return old + now;
      }, 0);
      return height - 90 / 2 + (30 + 8 + 5) * index + "px";
    },
    typeFilter(typeNam) {
      if (typeNam === 0) {
        return "任务发起";
      } else if (typeNam === 1) {
        return "派出所审批";
      } else {
        return "任务执行";
      }
    },
    taskType(val) {
      if (!val) return;
      if (val === 0) {
        return this.$t('taskMangement.常态任务');
      } else if (val === 1) {
        return this.$t('taskMangement.定时任务');
      } else if (val === 2) {
        return this.$t('taskMangement.周期任务');
      }
    },
    taskTypeName(item) {
      if (!item) return;
      // 先判断审批状态 如果通过或者不用审批 直接取拿执行状态就行 反之拿审批状态
      if (item.approveStatus === 1 || item.approveStatus === 3) {
        this.type = item.approveStatus;
        for (const obj of this.typeOfRatify) {
          if (obj.id == item.approveStatus) {
            return obj.name;
          }
        }
      } else {
        this.type = item.taskStatus;
        for (const obj of this.typeGroup) {
          if (obj.id == item.taskStatus) {
            return obj.name;
          }
        }
      }
    },
    cancel() {
      this.showTaskBox = false;
      this.clearTime();
    },
    openTaskDetailsList(item) {
      if (item.isOperate === 0) return;
      this.taskDetailsList = item;
    },
    closeTaskDetails() {
      this.taskDetailsList = null;
    },
    messageBox({ title, message, confirmButtonText, cancelButtonText, fun }) {
      if (!title || !message) return console.log("请输入提示标题以及提示内容");
      const value = {
        title,
        message,
        center: true,
      };
      if (confirmButtonText) {
        value.confirmButtonText = confirmButtonText;
      }
      if (cancelButtonText) {
        value.cancelButtonText = this.$t('operation.Cancel');
        value.showCancelButton = true;
      }
      this.$msgbox({
        ...value,
        beforeClose: (action, instance, done) => {
          done();
        },
      }).then((action) => {
        fun && fun();
      });
    },
    async detailsItem(item) {
      if (
        this.list.some((i) => i.showTaskDetails === true) &&
        item.id !== this.showDetailsId
      ) {
        this.list.map((i) => (i.showTaskDetails = false));
      }
      item.showTaskDetails = !item.showTaskDetails;
      if (item.showTaskDetails) {
        // 航线关联无人机数据面板预览
        this.$store.commit("uavApplications/setState", {
          key: "routeId",
          value: item.routeId,
        });
      } else {
        // 航线取消关联预览
        this.$store.commit("uavApplications/setState", {
          key: "routeId",
          value: false,
        });
      }
      this.showDetailsId = item.id;
      if (item.showTaskDetails) {
        const res = await Control_API.getTaskDetails(item);
        if (res.code === 200) {
          this.taskDetails = res.data;
        }
      } else {
        // 数据更改驱动视图刷新
        this.taskDetails = { ...this.taskDetails };
      }
    },
    async getUavList() {
      const res = await Control_API.getUavTree({
        name: this.search.name,
      });
      if (res.code === 200) {
        this.data = res.data;
        this.findList(this.data, "uavs", "childs");
      }
    },
    findList(list, uavListName, childrenName) {
      try {
        list.forEach((element) => {
          if (
            element[uavListName] &&
            element[uavListName].length &&
            element.type !== uavListName
          ) {
            element[uavListName].forEach((e) => (e.type = uavListName));
            element[childrenName] = [
              ...element[uavListName],
              ...element[childrenName],
            ];
          }
          if (this.$uav && element[uavListName]) {
            // 实时更新无人机数据，currentOperator 接管人参数
            const newVal = element[uavListName].find(
              (e) => e.id === this.$uav.id
            );
            if (
              newVal &&
              newVal.currentOperator !== this.$uav.currentOperator
            ) {
              this.$store.commit("uavApplications/setState", {
                key: "uav",
                value: {
                  ...this.$uav,
                  currentOperator: newVal.currentOperator,
                },
              });
            }
          }
          if (element[childrenName] && element[childrenName].length) {
            this.findList(element[childrenName], "uavs", "childs");
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async oneClickTask(item) {
      if (!this.$uav.currentOperator) {
        const messageValue = {
          title: this.$t('home.SafetyConfirmation'),
          message: `请确认是否接管${this.$uav.name}且进行一键任务操作自动起飞？`,
          confirmButtonText: this.$t('operation.Confirm'),
          cancelButtonText: this.$t('operation.Cancel'),
          fun: async () => {
            try {
              const res = await Tg.setUavControlOn({
                force: false,
                id: this.$uav.id,
                uid: this.userInfo.id,
              });
              if (res.code === 200) {
                this.$message.success(`${this.$t('home.takeOverSuccess')}${this.$uav.name}`);
                await this.getUavList();
              }
              const routeRes = await AirLine.routeDetail({
                id: item.routeId,
              });
              if (routeRes.code === 200) {
                const itemValue = JSON.parse(JSON.stringify(item));
                itemValue.content = routeRes.data.content;
                this.$store.commit("uavApplications/setState", {
                  key: "airlineData",
                  value: itemValue,
                });
              }
              this.$store.dispatch("uavApplications/takeOff", {
                callback: async (isOk) => {
                  if (isOk) {
                    this.$message.success("发出一键任务指令成功");
                    this.propOpenOperate = true;
                    const res = await flightTaskAPI.editFlight({
                      ...item,
                      changeSchedul: false,
                      deviceHardId: this.$uav.hardId,
                      taskStatus: 1,
                    });
                    if (res.code == 200) {
                      // 关联航线
                      this.detailsItem(item);
                      this.$message({
                        showClose: true,
                        type: "success",
                        message: this.$t('flightApplication.操作成功'),
                        duration: 1000,
                      });
                      const newItem = JSON.parse(JSON.stringify(item));
                      newItem.taskId = item.id;
                      newItem.hardId = this.$uav.hardId;
                      // await this.getTaskList(newItem);
                      // if (this.showTaskDetails) this.showTaskDetails = false;
                      this.$store.commit("uavApplications/setState", {
                        key: "TaskList",
                        value: newItem,
                      });
                    } else {
                      this.$message({
                        showClose: true,
                        type: "error",
                        message: res.msg,
                        duration: 1000,
                      });
                    }
                    // 控制无人机数据界面解锁
                    this.fly_control_unlock("fly_control_unlock");
                  } else {
                    return this.$message.warning("发出一键任务指令失败");
                  }
                },
              });
            } catch (e) {
              this.$message.warning("解析航线失败");
              console.log("解析航线失败", e);
            }
          },
        };
        this.messageBox(messageValue);
      } else if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的
        const messageValue = {
          title: this.$t('operation.Hint'),
          message: `当前选择的无人机正在被其他用户接管，请稍后操作或联系接管人？`,
          confirmButtonText: "好的,知道了",
        };
        // 不是本账号接管的无人机
        return this.messageBox(messageValue);
      } else {
        this.$confirm("请确认是否一键任务并一键起飞操作?",this.$t('home.SafetyConfirmation'), {
          cancelButtonText: this.$t('operation.Cancel'),
          confirmButtonText: this.$t('operation.Confirm'),
          customClass: "uav_controlPane",
          showClose: false,
        }).then(async () => {
          const routeRes = await AirLine.routeDetail({
            id: item.routeId,
          });
          if (routeRes.code === 200) {
            const itemValue = JSON.parse(JSON.stringify(item));
            itemValue.content = routeRes.data.content;
            this.$store.commit("uavApplications/setState", {
              key: "airlineData",
              value: itemValue,
            });
          }
          try {
            this.$store.dispatch("uavApplications/takeOff", {
              callback: async (isOk) => {
                if (isOk) {
                  this.$message.success("发出一键任务指令成功");
                  const res = await flightTaskAPI.editFlight({
                    ...item,
                    changeSchedul: false,
                    deviceHardId: this.$uav.hardId,
                    taskStatus: 1,
                  });
                  if (res.code == 200) {
                    this.$message({
                      showClose: true,
                      type: "success",
                      message: this.$t('flightApplication.操作成功'),
                      duration: 1000,
                    });
                    const newItem = JSON.parse(JSON.stringify(item));
                    newItem.taskId = item.id;
                    newItem.hardId = this.$uav.hardId;
                    // 打开任务列表
                    // await this.getTaskList(newItem);
                    // if (this.showTaskDetails) this.showTaskDetails = false;
                    // 打开任务列表
                    this.$store.commit("uavApplications/setState", {
                      key: "TaskList",
                      value: newItem,
                    });
                  } else {
                    this.$message({
                      showClose: true,
                      type: "error",
                      message: res.msg,
                      duration: 1000,
                    });
                  }
                  this.fly_control_unlock("fly_control_unlock");
                } else {
                  return this.$message.warning("发出一键任务指令失败");
                }
              },
            });
          } catch (e) {
            this.$message.warning("解析航线失败");
            console.log("解析航线失败", e);
          }
        });
      }
    },
    async getTaskListEnd() {
      this.getTaskList();
      this.taskDetails = null;
    },
    async getTaskList(item) {
      const res = await Control_API.getTaskList({
        target: 0,
        projectId: this.subplatformInfo.projectId,
        type: 0,
        deviceHardId: item?.taskId ? item.hardId : undefined,
        // taskStatus: item.taskId ? 1 : 0,
        // approveStatus: 0,
        taskStatusList: item?.taskId ? [1] : [0],
        approveStatusList: [0, 2],
      });
      if (res.code === 200) {
        // 有执行中的自动弹出详情框
        if (res.data.find((i) => i.taskStatus === 1)) {
          this.list = res.data;
          this.list.map((i) => {
            i.showTaskDetails = false;
          });
          this.detailsItem(res.data[0]);
        } else {
          this.list = res.data;
          this.list.map((i) => {
            i.showTaskDetails = false;
          });
          this.showTaskDetails = false;
        }
        this.showTaskBox = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.applications_uavContent {
  width: 100%;
  height: 100%;
  .taskBox {
    position: fixed;
    left: 70px;
    transition: left 0.4s ease-in-out;
    top: 54px;
    width: 352px;
    height: 448px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
      inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5),
      inset 0px 1px 4px 0px #2e63dc;
    border-radius: 8px;
    border: 1px solid;
    border-image: linear-gradient(
        180deg,
        rgba(52, 223, 255, 1),
        rgba(24, 113, 214, 0)
      )
      1 1;
    backdrop-filter: blur(4px);
    .head {
      box-sizing: border-box;
      width: 352px;
      height: 32px;
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05);
      border-radius: 4px 4px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .left {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ebf5ff;
      }
      .right {
        color: #ebf5ff;
      }
    }
    .taskBox_body {
      height: calc(100% - 38px);
      .flight-line-list {
        height: 100%;
        .tb-box {
          height: 100%;
          .tb-bd-box {
            ::v-deep {
              .el-tabs {
                .el-tabs__nav-scroll {
                  display: flex;
                  justify-content: center;
                }
                .el-tabs__active-bar {
                  background-color: #a4ff64;
                }
                .el-tabs__nav-wrap::after {
                  background-color: transparent;
                }
                .el-tabs__item {
                  color: #fff;
                  &:hover {
                    color: #a4ff64;
                  }
                  &.is-active {
                    color: #a4ff64;
                  }
                }
              }
            }
            .tb-tr {
              display: flex;
              flex-direction: column;
              &:hover {
                border: 1px solid transparent;
              }
              .tb-task-item {
                display: flex;
                border: 1px solid transparent;
                &:hover {
                  border: 1px solid #ccc;
                }
              }
              .details-body {
                transition: all 1s;
                background: #2c313b;
                border-radius: 4px 4px 0px 0px;
                overflow: auto;
                height: 0px;
                &.open {
                  height: 430px;
                  padding: 10px 8px;
                }
                .body {
                  height: calc(100% - 92px);
                  .item {
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .label {
                      width: 48px;
                      font-size: 12px;
                      font-family: MicrosoftYaHei;
                      color: #b3bbc5;
                      margin-right: 16px;
                    }
                    .value {
                      box-sizing: border-box;
                      width: 232px;
                      background: #16181b;
                      border-radius: 8px;
                      border: 1px solid #233a58;
                      font-size: 14px;
                      color: #d8dde8;
                      padding: 6px 12px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      word-wrap: break-word;
                    }
                    .remark {
                      box-sizing: border-box;
                      width: 232px;
                      height: 67px;
                      background: #16181b;
                      border-radius: 8px;
                      font-size: 14px;
                      border: 1px solid #233a58;
                      color: #d8dde8;
                      padding: 6px 12px;
                    }
                  }
                }
                .flow-chart {
                  max-height: 329px;
                  max-width: 435px;
                  overflow: auto;
                  position: relative;
                  padding-bottom: 10px;
                  .example {
                    position: absolute;
                    left: 20px;
                    top: 0;
                    width: 83px;
                    .item {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .example-line {
                        width: 20px;
                        height: 3px;
                        background-color: #52c2e9;
                        &.back {
                          background-color: #c63d3d;
                        }
                      }
                      .example-label {
                        font-size: 12px;
                        color: #fff;
                      }
                    }
                  }
                  .chart-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .pie {
                      width: 88px;
                      height: 88px;
                      border-radius: 50%;
                      background-color: #29260e;
                      border: 1px solid #8d7944;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      .pie-body {
                        text-align: center;
                      }
                    }
                    .diamond-pro {
                      width: calc(90px * 1.414);
                      height: calc(90px * 1.414);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      position: relative;
                      .diamond-body {
                        position: absolute;
                        text-align: center;
                        z-index: 1;
                      }
                      .diamond {
                        width: 90px;
                        height: 90px;
                        transform: rotate(45deg);
                        background-color: #29260e;
                        border: 1px solid #8d7944;
                      }
                    }
                    .label-functionType {
                      font-size: 14px;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #ffffff;
                      line-height: 20px;
                    }
                    .label-orgName {
                      font-size: 12px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #ffffff;
                      line-height: 17px;
                    }
                    .arrow {
                      width: 3px;
                      .arrow-line {
                        height: 30px;
                        background-color: #52c2e9;
                      }
                      .arrow-head {
                        border: 5px solid transparent;
                        border-top: 8px solid #52c2e9;
                        transform: translateX(-3px);
                      }
                    }
                    .arrow-back {
                      position: absolute;
                      left: calc(50% + 90px * 1.414 / 2);
                      top: calc(90px / 2);
                      width: 25px;
                      .line {
                        background-color: #c63d3d;
                      }
                      .top-box {
                        .head {
                          position: absolute;
                          left: calc((-90px) * 1.414 / 2 - (-90px) / 2 - 5px);
                          top: 0;
                          border: 5px solid transparent;
                          border-right: 8px solid #c63d3d;
                          transform: translateY(-3px);
                        }
                        .line {
                          position: absolute;
                          right: 0;
                        }
                      }
                      .middle-box {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        width: 3px;
                      }
                      .bottom-box {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 3px;
                      }
                    }
                    .active {
                      background-color: #0e290e !important;
                      border: 1px solid #448d5f !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .taskBox2 {
    left: 451px;
  }
}
.taskDetails {
  position: fixed;
  height: unset;
  top: 55px;
  left: 430px;
  z-index: 1;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 8px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(52, 223, 255, 1),
      rgba(24, 113, 214, 0)
    )
    1 1;
  backdrop-filter: blur(4px);
  .head {
    box-sizing: border-box;
    width: 352px;
    height: 32px;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05);
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .left {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ebf5ff;
    }
    .right {
      color: #ebf5ff;
    }
  }
  .body {
    margin: 10px 8px;
    background: #2c313b;
    border-radius: 4px 4px 0px 0px;
    overflow: auto;
    height: auto;
    .item {
      margin-top: 8px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #b3bbc5;
        margin-right: 16px;
      }
      .value {
        box-sizing: border-box;
        width: 220px;
        height: 32px;
        background: #16181b;
        border-radius: 8px;
        border: 1px solid #233a58;
        font-size: 14px;
        color: #d8dde8;
        padding: 6px 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        word-wrap: break-word;
      }
      .remark {
        box-sizing: border-box;
        width: 220px;
        height: 67px;
        background: #16181b;
        border-radius: 8px;
        font-size: 14px;
        border: 1px solid #233a58;
        color: #d8dde8;
        padding: 6px 12px;
      }
    }
  }
}
</style>
