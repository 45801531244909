<template>
  <div class="cpt-player-webrtc">
    <template v-if="showPlayer">
      <video v-if="isWebrtc" ref="video" width="500px" height="400px" />
      <ali-player
        v-else
        ref="aliPlayer"
        class="aliplayer"
        width="500px"
        height="400px"
        control-bar-visibility="hover"
        :source="data.vUrl"
        :options="options"
      />
    </template>
  </div>
</template>

<script>
const sdk = null;
import { flightTaskAPI } from "@/api";
import AliPlayer from "vue-aliplayer-v2";

export default {
  components: {
    AliPlayer,
  },
  props: {
    // {vUrl: '', videoType: ''}
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      flight_info: null,
      showPlayer: true,
      webrtcPlay: null,
    };
  },
  computed: {
    options() {
      return {
        isLive: true,
        format: this.data.videoType,
      };
    },
    // 判断是否为webrtc流
    isWebrtc() {
      return this.data.vUrl.includes("webrtc://");
    },
  },
  watch: {
    data: {
      handler(value) {
        this.showPlayer = false;
        this.$nextTick(() => {
          this.showPlayer = true;

          this.$nextTick(() => {
            if (this.isWebrtc) {
              if (this.webrtcPlay) {
                this.webrtcPlay.destroy();
              }
              this.webrtcPlay = new JSWebrtc.Player(this.data.vUrl, {
                video: this.$refs.video,
                autoplay: true,
                onPlay(plyer) {},
                onPause(player) {},
              });
            }
          });
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    const self = this;
    self.$bus.$on("takePhotos", (flight_info) => {
      self.flight_info = flight_info();
      self.screenShot(self.flight_info);
    });
  },
  methods: {
    init() {},
    async screenShot(data) {
      // console.log("screenShot:", data);
      var player = null;
      if (this.isWebrtc) {
        player = this.$refs.video; // 获取video的Dom节点
      } else {
        player = this.$refs.aliPlayer.$el.children[0];
        console.log("this.$refs.aliPlayer", player);
      }
      player.setAttribute("crossOrigin", "anonymous"); // 添加srossOrigin属性，解决跨域问题
      var canvas = document.createElement("canvas");
      // var img = document.createElement("img");
      canvas.width = player.clientWidth;
      canvas.height = player.clientHeight;
      canvas
        .getContext("2d")
        .drawImage(player, 0, 0, canvas.width, canvas.height); // 截
      // var dataURL = canvas.toDataURL("image/png"); //将图片转成base64格式
      // img.src = dataURL;
      // img.width = player.clientWidth; //控制截出来的图片宽的大小
      // img.height = player.clientHeight; //控制截出来的图片高的大小
      // img.style.border = "1px solid #333333"; //控制截出来的图片边框的样式
      // document.getElementById("cutImage").appendChild(img); //显示在页面中
      // console.log("img:", dataURL);
      try {
        canvas.toBlob((blob) => {
          // 创建a元素来实现下载
          // const a = document.createElement("a");
          // a.style.display = "none";
          // document.body.appendChild(a);
          // a.href = window.URL.createObjectURL(blob);
          // // 下载的文件名
          // let fileName = "nt图片";
          // a.download =
          //   fileName || `screenshot-${canvas.width}x${canvas.height}.png`;
          // console.log("IMG-BLOD:", blob);
          const myFile = new File([blob], "image.png", {
            type: blob.type,
          });
          this.savaTaskImages(myFile, data.deviceId, data.id);
          // a.click();
          // // 触发下载后，移除元素
          // a.remove();
        });
      } catch (e) {
        console.log(e);
      }
      // this.downFile(dataURL, "图片.jpg"); //下载截图
    },
    async savaTaskImages(images, deviceHardId, task_id) {
      const forms = new FormData();

      forms.append("task_id", task_id);
      forms.append("deviceHardId", deviceHardId);
      forms.append("images", images);

      const data = await flightTaskAPI.savaTaskImages(forms);
    },
    play: function () {
      if (this.isWebrtc) {
        this.webrtcPlay.play();
      } else {
        this.$refs.VueAliplayerV2.play();
      }
    },
    pause: function () {
      this.$refs.VueAliplayerV2.pause();
    },
    replay: function () {
      this.$refs.VueAliplayerV2.replay();
    },
    getStatus() {
      const status = this.$refs.VueAliplayerV2.getStatus();
      console.log(`getStatus:`, status);
      alert(`getStatus:${status}`);
    },
  },
};
</script>

<style lang="scss" scoped>
video::-webkit-media-controls-enclosure {
  display: none;
}
.cpt-player-webrtc {
  height: 100%;
  width: 100%;
  background-color: #000;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>
