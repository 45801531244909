import * as mountCtrlList from '@/components/observe/dashboard/control/utils.js';
import { buffer } from '@turf/turf';

let lastMountCtrl = null;
export default {
  data() {
    return {
      wsJoystick: null,
      control_speed: 3, //控制速度
      mountCtrlList,
      lastDirection: 'stop',  //最后一次操控方向
      lastMount: null,
      uavModel: false,//无人机遥感连接状态，
      iStatusValue: null,
      channel08: null,
      Mdata: {}
    }
  },
  computed: {
    selectMount() {
      return this.$store.state.uavApplications.selectMount;
    },
    mountCtrl() {
      return this.mountCtrlList[this.selectMount?.gimbalName];
    }
  },
  inject: ['uav_mqtt_fn', 'uav'],
  watch: {
  },
  beforeDestroy() {
    console.log('closeJoystick.........................');
    this.closeJoystick();
  },
  methods: {
    /**
     * 初始化操纵杆连接
     */
    initJoystick(device) {
      this.wsJoystick && this.wsJoystick.close();
      if (device) {
        let topic = `PX4/OBTAIN/${device.hardId}`;
        console.log(topic, process.env.VUE_APP_FCMQ_URL, 'topic');
        this.wsJoystick = new WebSocket("ws://127.0.0.1:8802");
        this.wsJoystick.onopen = () => {
          console.log('mixins/joystick.js 连接上 ws://127.0.0.1:8802')
          this.wsJoystick.send(
            JSON.stringify({
              type: 2003,
              data: {
                mqttAddr: process.env.VUE_APP_FCMQ_URL,
                mqttIport: 1883,
                mqttTopic: topic,
                vehicleType: 1
              },
            })
          );
          this.uavModel = true
          this.unlockType = false;

        };
        setTimeout(() => {
          this.wsJoystick.send(JSON.stringify({ type: 2001, data: {} }));
        }, 2000);
        let _tihs = this;
        this.wsJoystick.onmessage = (e) => {
          let reader = new FileReader();
          reader.onload = (event) => {
            //   {
            //     "code": 2000,
            //     "data": {
            //         "iStatusValue": 15
            //     },
            //     "type": 518
            // }
            let obj = JSON.parse(event.currentTarget.result)
            if (obj.type == 518) {
              this.iStatusValue = obj.data?.iStatusValue
            }
            let data = JSON.parse(event.currentTarget.result).data;

            const y = data.channel03 //油门值 0-1000
            // 判断是否在摇杆中位
            if (y || y == 0) {
              // 保存摇杆值
              this.$store.commit("uavApplications/setState", {
                key: "ygValue",
                value: y,
              });
            }
            // 判断是否在摇杆中位
            if (y >= 470 && y <= 530) {
              this.$store.commit("uavApplications/setState", {
                key: "ygisCenterFlag",
                value: true,
              });
            } else {
              this.$store.commit("uavApplications/setState", {
                key: "ygisCenterFlag",
                value: false,
              });
            }
            if (data && this.wsShow) {
              const yaw = data.channel01//偏航-1000-1000
              const x = data.channel02//俯仰-1000-1000
              const y = data.channel03 //油门值 0-1000
              const z = data.channel04 //横滚 -1000-1000
              this.Mdata = {
                yaw: yaw,
                x: x,
                y: y,
                z: z
              }
            }
            if (!this.wsShow) {

              if (this.mountCtrl) {
                //拍照
                if (data.channel06) {
                  let buffer = this.mountCtrl.take_photo()
                  this.send_mqtt(buffer)
                }
                //录像
                if (data.channel05) {
                  let buffer = null
                  if (data.channel05 == 1) {
                    buffer = this.mountCtrl.record(true)
                  } else if (data.channel05 == 2) {
                    buffer = this.mountCtrl.record(false)
                  }
                  this.send_mqtt(buffer)
                }
                //云台模式
                // if (data.channel07) {
                //   let buffer = null
                //   if (data.channel07 == 1) {
                //     buffer = this.mountCtrl.gimbal_mode_ctrl(0)
                //   } else if (data.channel07 == 2) {
                //     buffer = this.mountCtrl.gimbal_mode_ctrl(2)
                //   }
                //   this.send_mqtt(buffer)
                // } else {
                //   let buffer = this.mountCtrl.gimbal_mode_ctrl(1)
                //   this.send_mqtt(buffer)
                // }
                //变焦
                if (data.channel09) {
                  let buffer = null
                  if (data.channel09 == 1) {
                    buffer = this.mountCtrl.zoom(2)
                  } else if (data.channel09 == 2) {
                    buffer = this.mountCtrl.zoom(0)
                  }
                }
              }

              //channel10 云台俯仰 0：没按 1：上 2：下
              if (data.channel10) {
                const direction = {
                  1: 'up',
                  2: 'down'
                }

                const ctr = direction[data.channel10];
                // console.log('摇杆按下了channel10', ctr);
                _tihs.cameraMountCtr(ctr)
              }
              //channel11 云台偏航 0：没按 1：左 2：右
              if (data.channel11) {
                const direction = {
                  1: 'left',
                  2: 'right'
                }

                const ctr = direction[data.channel11];
                // console.log('摇杆按下了channel11', ctr);
                _tihs.cameraMountCtr(ctr)
              }

              if (data.channel10 == 0 && data.channel11 == 0) {
                _tihs.cameraMountCtr('stop')
              }
            }
          };
          reader.readAsText(e.data);
        };
      }
    },
    /**
     * 关闭操纵杆
     */
    closeJoystick() {
      if (!this.wsJoystick) {
        return;
      }
      this.cameraMountCtr('stop', this.lastMountCtrl);
      setTimeout(() => {
        this.wsJoystick.send(JSON.stringify({ type: 2010, data: {} }));
        this.wsJoystick && this.wsJoystick.close()
        this.wsJoystick = null;
      }, 2000);
    },
    /**
     * 相机挂载控制
     * @param {String} type up: 上，left：打，right：右，down：下
     */
    cameraMountCtr(type) {
      /* if(type !== 'stop'){
        console.log('cameraMountCtr', !this.wsJoystick, !this.mountCtrl || !type, this.lastDirection === type)
        console.log('this.mountCtrl', this.mountCtrl, type)
      } */
      if (!this.wsJoystick) {
        return;
      }

      if (!this.mountCtrl || !type) {
        return;
      }

      if (this.lastDirection === type) {
        return;
      }


      console.log('摇杆按下了', this.mountCtrl, type);

      let buffer = null;
      try {
        switch (type) {
          case "stop":
            if (['up', 'down'].includes(this.lastDirection)) {
              buffer = [165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 5, 220, 27, 60]
            } else if (['left', 'right'].includes(this.lastDirection)) {
              buffer = [165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 5, 220, 27, 60]
            }
            console.log(buffer, 'stop');
            break;
          case "up":
            buffer = [165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 4, 176, 118, 108]
            console.log(buffer, 'up');
            break;
          case "left":
            buffer = [165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 4, 176, 0, 0, 5, 220, 118, 216];
            console.log(buffer, 'left');
            break;
          case "right":
            buffer = [165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 7, 8, 0, 0, 5, 220, 205, 15]
            console.log(buffer, 'right');
            break;
          case "down":
            buffer = [165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 7, 8, 205, 85]
            console.log(buffer, 'down');
            break;
        }
      } catch (error) {
        console.log(error, 'error')
      }
      console.log(buffer, this.mountCtrl, 'bufferbuffer')
      this.lastDirection = type;
      /* this.fn({
        type: 514,
        data: {
            mountId: this.selectMount.mountId,
            payload: buffer,
        },
      }, 'wrj') */
      this.send_mqtt(buffer)
    },
    // send_ws() {
    //   if (this.Mdata && this.Mdata.y) {
    //     this.$emit("fun", {
    //       type: 200,
    //       systemCode: "mmc",
    //       state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
    //       username: this.user_info.username,
    //       data: {
    //         cmdFunction: 2120,
    //         cmdChannel: this.Mdata
    //       },
    //       deviceHardId: this.device.deviceHardId
    //     })
    //   }
    // },
    send_mqtt(buffer) {
      this.$store.dispatch('uavApplications/mounteControl', {
        mountId: this.selectMount.mountId,
        payload: buffer,
        callback: (isOk) => {
          isOk && console.log('发出控制挂载指令');
        }
      });
      console.log('send_mqtt', this.selectMount)
      // this.uav_mqtt_fn({
      //   type: 514,
      //   data: {
      //     mountId: this.selectMount.mountId,
      //     payload: buffer,
      //   },
      // }, 'wrj', this.uav.control.device)
    }
  }
}