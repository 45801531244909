<script>
import { AI, Control_API, Tg } from "@/api";
import dayjs from "dayjs";
import OSD from "@/views/videoWall/com/osd/index.vue";
import {MMC_Gimbal_ZT60R} from '@/components/mount/MMC_Gimbal_ZT60R/js/index.js';
import { to_moveMount } from "@/utils/to_moveMount.js";
export default {
  name: "Index",
  props: {
    thrustDataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const validateTimeOut = (rule, value, callback) => {
      if (
        /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value) ==
        false
      ) {
        return callback(new Error("请输入正整数或两位小数!"));
      }
      if (!value && this.supervisedForm.selectType === 1) {
        return callback(new Error("请选择抽帧频率！"));
      } else if (!value && this.supervisedForm.selectType === 1) {
        return callback(new Error("请输入抽帧频率！"));
      }
      callback();
    };
    return {
      isInfoShow: false,
      aiBtnList: [],
      payload:null,//zt60r 挂载buffer
      aiCollapseShow: false, // 是否展开
      aiTabs: [
        {
          id: 1,
          label:  this.$t('flightApplication.OneClickecognition'),// 一键识别
        },
        {
          id: 2,
          label: this.$t('flightApplication.MonitoringAndDefense'),// "监控布防",
        },
      ],
      currentAiTabsIndex: 0,
      currentPointIndex: -1,
      supervisedForm: {
        algorithmType: null,
        selectType: 1,
        timeOut: null,
      },
      rules: {
        algorithmType: [
          { required: true, message: "请选择算法类型", trigger: ["change"] },
        ],
        selectType: [
          { required: true, message: "请选择抽帧间隔", trigger: ["change"] },
        ],
        timeOut: [
          { required: true, validator: validateTimeOut, trigger: ["change"] },
        ],
      },
      timeOutList: [
        { label: 1 },
        { label: 2 },
        { label: 3 },
        { label: 4 },
        { label: 5 },
        { label: 10 },
        { label: 20 },
        { label: 30 },
      ],
      supervisedTimeOut: null,
      isDeployed: false,
      flightSortie: "", //飞行架次
      screenRecordingStatus: true,
      // 状态锁,处理停止布防后还一直提示截屏的问题
      stopMethod: false,
    };
  },
  watch: {
    "thrustDataList":{
      handler(val) {
    console.log(val,'thrustDataList');
      },
      deep: true,
    },
    "MountZt60R.payload": {
      handler(val) {
      this.payload = val
      },
      deep: true,
    },
  },
  computed: {
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    uavRealTimeData() {
      return this.$store.state.uavApplications.uavRealTimeData;
    },
    mountList() {
      return this.$store.state.uavApplications.mountList;
    },
    MountZt60R() {
      return this.mountList.filter(
        (item) => item.gimbalName == "MMC_Gimbal_ZT60R"
      );
    },
    selectMount() {
      return this.$store.state.uavApplications.selectMount;
    },
  },
  mounted() {
   setTimeout(() => {
    const dom = document.getElementById ("uav-player-content");
    if(dom){
      let cpt_video =dom.querySelector(".cpt_video");
      cpt_video.addEventListener("click",this.videoCcontrol);
    }
   }, 20);
  },
  beforeDestroy(){
    const dom = document.getElementById ("uav-player-content");
    if(dom){
      let cpt_video =dom.querySelector(".cpt_video");
      cpt_video.removeEventListener("click",this.videoCcontrol);
    }
  },
  methods: {
    sortFunction(a, b)  {
      if (a.label === 'webrtc') return -1;
  if (b.label === 'webrtc') return 1;
  return 0;
},
    videoCcontrol(el){
      let video= document.getElementById('rtc_media_player') ||  document.getElementsByClassName('vjs-tech')[0]
      if(!video){
        video=  document.getElementsByClassName("qingliu").length &&
        document.getElementsByClassName("qingliu")[0].childNodes[0].childNodes[0]
      }
      console.log(video,'video');
      if(video){
                  setTimeout(() => {
                    video.play();
                  }, 20);
                  this.videoMove(el)
                }  
    },
    async videoMove(el){
      let move_data = this.move_data
      if(move_data &&move_data.mountStatus){
    let mountData = move_data;
        let evt = window.event || el;
        let div =document.querySelector(".cpt_video");
       const user_info= JSON.parse(window.localStorage.getItem("user_info"))
        let data = {
          x: evt.offsetX * (1920 / div.offsetWidth) - 960,
          y: -(evt.offsetY * (1080 / div.offsetHeight)) + 540,
          w: 1920,
          h: 1080
        };
        // return
        let buffer = null;
        if (mountData.name == "MMC_Gimbal_Z30Pro") {
          // z30pro
          buffer = await to_moveMount.move_track_focus_ctrl(data.x, data.y);
        } else if (mountData.name == "MMC_Gimbal_Z40S") {
          //z40s
          buffer = await to_moveMount.move_or_focus_ctrl(data);
        } else if (mountData.name == "DJI_H20T") {
          let cx = evt.offsetX / div.offsetWidth;
          let cy = evt.offsetY / div.offsetHeight;
          let djbuff = "cameraAim:" + cx.toFixed(1) + "," + cy.toFixed(1);
          let data = {
            type: 200,
            systemCode: "mmc",
            state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
            username: user_info.userName,
            data: {
              cmdControlType: 7000, //520
              cmdBuffer: djbuff,
              cmdName: mountData.name
            },
            deviceHardId: this.$uav.hardId
          };
          //z40s
          // buffer = await to_moveMount.move_or_focus_ctrl(data);
        } else if (
          mountData.name == "MMC_Gimbal_Z60S" ||
          mountData.name == "MMC_Gimbal_ZT60R"||
          mountData.name == "MMC_Gimbal_Z60R"
        ) {
          buffer = await to_moveMount.move_track_focus_ctrl(data.x, data.y, 3);
        } else {
          buffer = await to_moveMount.gcs_click_coordinates(data);
        }
        if (buffer) {
          this.$store.dispatch('uavApplications/mounteControl', {
        mountId:this.selectMount.mountId,
        payload: buffer,
        callback: (isOk) => {
          isOk && console.log('发出控制挂载指令');
        }
      });
      }
      }
    },
    screenRecording() {
      this.$store.dispatch("uavApplications/videoTranscribeStatus", {
        callback: (isOk) => {
          isOk && this.$message.success("查询录制状态");
        },
      });

      this.$store.dispatch("uavApplications/videoTranscribe", {
        videoID: 1, // 视频通道ID（需保持唯一）
        dbID: "929", // 历史记录id
        name: "z30Pro", // 挂载名称（如果知道挂载名称，将传名称即可，通道号可以不用传）
        taskID: "", // 任务ID，可传可不传
        recordControl: true, // 录制开关、true为开启
        status: this.screenRecordingStatus,
        callback: (isOk) => {
          isOk &&
            this.$message.success(
              `${this.screenRecordingStatus ? "开始" : "结束"}录屏`
            );
          this.screenRecordingStatus = !this.screenRecordingStatus;
        },
      });
    },
    async getflightSortie() {
      const res = await Tg.uavLastData({
        hardId: this.$uav.hardId,
        appId: 87878,
      });
      if (res.data != "DATA_NOT_EXIST") {
        this.flightSortie = res.data.deviceData.data.uavInfo.flightSortie;
      }
    },
    // 拍照
    async photographClick() {
      this.getflightSortie();
      setTimeout(() => {
        this.screenshot("photo");
      }, 1000);
    },
    // 截屏,一键识别
    screenshot(type, item) {
      this.getflightSortie();
      const element1 = document.querySelectorAll(".rtc_media_player");
      const video = element1.length && element1[element1.length - 1];
      const element2 = document.querySelectorAll(".vjs-tech");
      const video1 = element2.length && element2[element2.length - 1];
      const element3 = document.querySelectorAll(".qingliu");
      const video2 =
        element3.length &&
        element3[element3.length - 1].childNodes[0].childNodes[0];
      if (video || video1 || video2) {
        try {
          setTimeout(() => {
            this.uploadPhoto(video || video1 || video2, type, item);
          }, 1000);
        } catch (error) {
          console.log(error);
          this.$message.error(this.$t('flightApplication.截屏失败'));
        }
      }
    },
    getPosition() {
      let longitude = "";
      let latitude = "";
      if (
        this.$uav.locationCoordinate3D &&
        this.$uav.locationCoordinate3D.longitude
      ) {
        longitude = this.$uav.locationCoordinate3D.longitude;
        latitude = this.$uav.locationCoordinate3D.latitude;
      } else {
        const modelId = "model_" + this.$uav.hardId;
        const modelEntity = window.viewer.entities.getById(modelId);
        if (modelEntity) {
          // 获取模型的位置坐标
          let modelPosition = modelEntity.position.getValue(
            viewer.clock.currentTime
          );
          // 将位置坐标转换为经纬度坐标
          let modelCartographic =
            Cesium.Cartographic.fromCartesian(modelPosition);
          // 获取经纬度
          let lon = Cesium.Math.toDegrees(modelCartographic.longitude);
          let lat = Cesium.Math.toDegrees(modelCartographic.latitude);
          longitude = lon;
          latitude = lat;
        }

        return { longitude, latitude };
      }
    },
    // 拍照上传or截屏上传or AI上传
    async uploadPhoto(video, type, item) {
      // 获取当前帧
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth || video.width;
      canvas.height = video.videoHeight || video.width;
      const canvasContext = canvas.getContext("2d");
      canvasContext.drawImage(video, 0, 0);
      // let { longitude, latitude } = this.getPosition();
      let longitude= null;
      let latitude= null;
      // 判断当前飞机是否挂有zt60r挂载 如果有就使用zt60r返回的目标经纬度
      if(this.payload){
        const dataArray = new Uint8Array(this.payload);
          const zt60rPosition = MMC_Gimbal_ZT60R.gcs_transmit_ctrl(dataArray);
      if (zt60rPosition &&zt60rPosition.longitude) {
        longitude= zt60rPosition.longitude
        latitude= zt60rPosition.latitude
      }
      }else{
       // 否则使用无人机的经纬度
      let uavPosition = this.getPosition();
      longitude= uavPosition.longitude
      latitude= uavPosition.latitude
      }
      if (type === "aiPhoto") {
        canvas.toBlob(
          async (blob) => {
            if (blob) {
              const data = new FormData();
              data.append("image", blob);
              data.append("detectortype", item.detectortype);
              data.append("imgSave", "1");
              const res = await AI.getAiPhoto(data);
              if (res.data && res.data.data.length) {
                if (!this.stopMethod) {
                  this.$message({
                    type: "success",
                    message: this.$t('flightApplication.识别成功'),
                    duration: 1000,
                  });
                }
                const imgData = new FormData();
                imgData.append("url", res.data.imgAiUrl);
                imgData.append(
                  "addtime",
                  dayjs().format("YYYY-MM-DD HH:mm:ss")
                );
                imgData.append("longitude", longitude);
                imgData.append("latitude", latitude);
                imgData.append("hardId", this.$uav.hardId);
                imgData.append("name", this.$uav.name);
                imgData.append("imageType", 2);
                const obj = {};
                const dataArr = res.data.data;
                dataArr.map((e) => {
                  if (!obj[e.label_cn]) {
                    obj[e.label_cn] = [];
                  }
                  if (Object.keys(obj).includes(e.label_cn)) {
                    obj[e.label_cn].push(e);
                  } else {
                    obj[e.label_cn].push(e);
                  }
                });
                imgData.append("remark", JSON.stringify(res));
                // imgData.append("aiType", 2);
                imgData.append("sortie", this.flightSortie || "");
                const data = new FormData();
                data.append("addtime", dayjs().format("YYYY-MM-DD HH:mm:ss"));
                data.append("file", blob);
                data.append("hardId", this.$uav.hardId);
                data.append("name", this.$uav.name);
                data.append("longitude", longitude);
                data.append("latitude", latitude);
                data.append("imageType", 1);
                data.append("sortie", this.flightSortie || "");
                const res2 = await Control_API.addPhoto(data);
                if (res2.code === 200) {
                  if (!this.stopMethod) {
                    this.$message.success(this.$t('flightApplication.截屏成功'));
                    this.$bus.$emit("updateAIResult");
                  }
                }
                const res1 = await Control_API.addPhoto(imgData);
              } else {
                if (!this.stopMethod) {
                  this.$message({
                    type: "error",
                    message: res.msg || this.$t('flightApplication.出错了'),
                    duration: 1000,
                  });
                }
              }
            }
          },
          "image/png",
          1
        );
      } else if (type === "photo") {
        canvas.toBlob(
          async (blob) => {
            if (blob) {
              const data = new FormData();
              data.append("addtime", dayjs().format("YYYY-MM-DD HH:mm:ss"));
              data.append("file", blob);
              data.append("hardId", this.$uav.hardId);
              data.append("name", this.$uav.name);
              data.append("imageType", 1);
              data.append("sortie", this.flightSortie || "");
              data.append("longitude", longitude);
              data.append("latitude", latitude);
              const res = await Control_API.addPhoto(data);
              if (res.code === 200) {
                this.$message.success(this.$t('flightApplication.截屏成功'));
              } else {
                this.$message({
                  type: "error",
                  message: res.msg || this.$t('flightApplication.出错了'),
                  duration: 1000,
                });
              }
            }
          },
          "image/png",
          1
        );
      }
    },
    // 获取AI可操作列表
    async getAiBtnList() {
      if (!this.aiCollapseShow) {
        const { data } = await AI.getAiPhotoList();
        this.aiBtnList = data || [];
      }
      this.aiCollapseShow = !this.aiCollapseShow;
    },
    // ai tab切换
    aiTasChangeEvent(index) {
      this.currentAiTabsIndex = index;
    },
    // ai选择
    aiSelectEvent(index) {
      this.currentPointIndex = index;
      this.stopMethod = false;
      this.screenshot("aiPhoto", this.aiBtnList[index]);
    },
    //   监控布防
    onSubmitSupervisedForm() {
      if (!this.isDeployed) {
        if (this.supervisedTimeOut) {
          clearInterval(this.supervisedTimeOut);
          this.supervisedTimeOut = null;
        }
        this.stopMethod = false;
        this.$refs["supervisedForm"].validate((validate) => {
          if (validate) {
            const data = {
              detectortype: this.supervisedForm.algorithmType.join(","),
            };
            this.supervisedTimeOut = setInterval(() => {
              this.screenshot("aiPhoto", data);
            }, 1000 * this.supervisedForm.timeOut || 1000);
          }
        });
      }
      this.isDeployed = !this.isDeployed;
    },
    // 取消
    cancelDeployed() {
      if (this.isDeployed && this.supervisedTimeOut) {
        clearInterval(this.supervisedTimeOut);
        this.supervisedTimeOut = null;
        this.isDeployed = false;
        this.stopMethod = true;
      } else if (this.supervisedTimeOut) {
        this.isDeployed = true;
      } else {
        this.aiCollapseShow = false;
      }
    },
  },
  components: { OSD },
};
</script>

<template>
  <div class="uav-player">
    <div id="uav-player-content" class="uav-player-content">
      <MMCPlayer
        ref="MMCPlayer"
        title=""
        :stream-options="thrustDataList.sort(sortFunction)"
        :obstacle-data="null"
        :size="2"
      />
    </div>
    <div class="uav-player-control">
      <div :title="$t('flightApplication.拍照')" class="item-box photograph" @click="photographClick" />
      <div :title="$t('flightApplication.录屏')" class="item-box" @click="screenRecording" :class="screenRecordingStatus ? 'pictureRecording' : 'pictureRecordingStop'"/>
      <div :title="$t('flightApplication.截屏')" class="item-box screenshot" @click="screenshot" />
      <div :title="$t('flightApplication.AI预警')" class="item-box aiPhoto">
        <div class="aiPhoto-img" :title="$t('flightApplication.AI预警')" @click="getAiBtnList" />
        <el-collapse-transition>
          <div
            v-if="aiCollapseShow"
            class="ai-collapse-wrap"
            :style="{ width: currentAiTabsIndex === 0 ? '208px' : '320px' }"
          >
            <div class="ai-tabs">
              <div
                v-for="(v, i) in aiTabs"
                :key="v.id"
                class="tab-item"
                :class="{ 'tab-item-active': i === currentAiTabsIndex }"
                @click="aiTasChangeEvent(i)"
              >
                {{ v.label }}
              </div>
            </div>
            <div v-if="currentAiTabsIndex === 0" class="ai-btn-list">
              <div
                v-for="(v, index) in aiBtnList"
                :key="v.id"
                class="btn-item"
                @click="aiSelectEvent(index)"
              >
                <div class="item-content" style="position: relative !important">
                  <span
                    :class="{
                      selected_text: index === currentPointIndex,
                    }"
                    style="position: absolute !important"
                    >{{ v.name }}</span
                  >
                  <img
                    :src="v.icon_url"
                    alt
                    :class="{
                      selected: index === currentPointIndex,
                    }"
                    class="images_item"
                    crossorigin="anonymous"
                  />
                </div>
              </div>
            </div>
            <div v-else class="ai-form-wrap">
              <el-form
                ref="supervisedForm"
                :rules="rules"
                :model="supervisedForm"
                label-width="80px"
                :disabled="isDeployed"
              >
                <el-form-item label="选择算法" prop="algorithmType">
                  <el-select
                    v-model="supervisedForm.algorithmType"
                    placeholder="请选择算法"
                    multiple
                    collapse-tags
                    filterable
                  >
                    <el-option
                      v-for="item in aiBtnList"
                      :key="item.detectortype"
                      placeholder="请选择算法"
                      :label="item.name"
                      :value="item.detectortype"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="抽帧间隔" prop="selectType">
                  <el-radio-group
                    v-model="supervisedForm.selectType"
                    @change="changeSelectType"
                  >
                    <el-radio :label="1">标准</el-radio>
                    <el-radio :label="2">自定义</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  v-show="supervisedForm.selectType === 1"
                  label="抽帧频率"
                  prop="timeOut"
                >
                  <div style="display: flex">
                    <el-select
                      v-model="supervisedForm.timeOut"
                      placeholder="请选择"
                      filterable
                    >
                      <el-option
                        v-for="item in timeOutList"
                        :key="item.label"
                        placeholder="请选择"
                        :label="item.label"
                        :value="item.label"
                      />
                    </el-select>
                    <div class="arm-body-item-unit">秒/次</div>
                  </div>
                </el-form-item>
                <el-form-item
                  v-show="supervisedForm.selectType === 2"
                  label="抽帧频率"
                  prop="timeOut"
                >
                  <div style="display: flex">
                    <el-input
                      v-model="supervisedForm.timeOut"
                      placeholder="请输入抽帧频率"
                      clearable
                      min="0"
                    />
                    <div class="arm-body-item-unit">秒/次</div>
                  </div>
                </el-form-item>
                <div class="form_btn">
                  <div class="btn_create" @click="cancelDeployed">
                    {{ isDeployed ? "停止布防" : $t('operation.Cancel') }}
                  </div>
                  <div class="btn_cancel" @click="onSubmitSupervisedForm">
                    {{ isDeployed ? "编辑布防" : "确认布防" }}
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </el-collapse-transition>
      </div>
    </div>
    <div class="cf bottom7 left52 osdInfo pa" style="z-index: 11">
      <div>
        <el-tooltip class=" w20" :content="$t('home.uavInfomation')" placement="top">
          <div class="cp" @click="isInfoShow = !isInfoShow" >
             <img src="@/assets/image/uav.png"></img>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="player-tip">{{$t('flightApplication.mounting')}}</div>
    <OSD :osdStyle="{ height: '94%' }" :isInfoShow="isInfoShow"></OSD>
  </div>
</template>

<style scoped lang="scss">
.uav-player {
  position: relative;
  &-content::v-deep {
    div {
      position: unset !important;
    }
    .interact-wrap {
      transform: none !important;
      position: relative !important;
      margin: 0 !important;
    }
    .cpt_video {
      position: relative !important;
      .video {
        position: absolute !important;
        .name,
        .tr-info-box,
        .close {
          position: absolute !important;
        }
        .close {
          display: none;
        }
      }
      .play {
        position: absolute !important;
        .ctrl-items-selects {
          margin-right: 4px !important;
          .el-input {
            position: relative !important;
          }
        }
        .size_type {
          display: none !important;
        }
      }
      .video-wrapper {
        padding-bottom: 0 !important;
      }
    }
    .size_02 {
      width: 427px !important;
      height: 240px !important;
    }
  }
  &-control {
    position: absolute;
    right: 10px;
    bottom: 41px;
    .item-box {
      cursor: pointer;
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      margin-bottom: 10px;
    }
    .photograph {
      background-image: url("~@/assets/images/mount/photograph.png");
      background-position: 0 0;
      background-size: cover;
    }
    .pictureRecording {
      background: url("~@/assets/images/mount/pictureRecording.png") no-repeat;
      background-size: 100% 100%;
    }
    .pictureRecordingStop {
      background: url("~@/assets/images/mount/停止录屏.png") no-repeat;
      background-size: 100% 100%;
    }
    .screenshot {
      background: url("~@/assets/images/mount/screenshot.png") no-repeat;
      background-size: 100% 100%;
    }
    .aiPhoto {
      position: relative;
      background-color: transparent;
      .aiPhoto-img {
        width: 100%;
        height: 100%;
        background: url("~@/assets/images/mount/ai-photo-default.png") no-repeat;
        background-size: 100% 100%;
      }
      .ai-collapse-wrap {
        position: absolute;
        height: 269px;
        width: 320px;
        background-color: #161d2b;
        top: -232px;
        left: 70px;

        .ai-tabs {
          height: 31px;
          box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05),
            inset 0px -1px 0px 0px #000000;
          border-radius: 4px 0px 0px 0px;
          display: flex;
          .tab-item {
            flex: 1;
            height: 100%;
            text-align: center;
            line-height: 31px;
            font-size: 14px;
            color: #ebf5ff;
            &-active {
              background-color: #1890ff;
            }
          }
        }
        .ai-btn-list {
          display: flex;
          align-items: center;
          overflow-y: auto;
          height: 238px;
          flex-wrap: wrap;
          padding-top: 10px;
          box-sizing: border-box;
          .btn-item {
            width: 68px;
            height: 48px;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            .item-content {
              width: 48px;
              height: 48px;
              span {
                color: #b1bbc6;
                font-size: 12px;
                top: 0;
                left: 50%;
                line-height: 0;
                width: 48px;
                transform: translateX(-50%);
              }
              .images_item {
                width: 100%;
                height: 100%;
                background: url("~@/assets/ai/AI.png");
                cursor: pointer;
              }
              .images_item:hover {
                background: url("~@/assets/ai/AI_hover.png");
                cursor: pointer;
              }
              .selected {
                width: 48px;
                height: 48px;
                background: url("~@/assets/ai/AI_Selected.png");
                &:hover {
                  background: url("~@/assets/ai/AI_Selected.png");
                  cursor: pointer;
                }
              }
              .selected_text {
                color: #50efff;
              }
            }
          }
        }
      
        .ai-form-wrap {
          padding: 10px;
          .arm-body-item-unit {
            width: 80px;
            font-size: 12px;
            color: #b3bbc5;
            margin-left: 5px;
          }
          .form_btn {
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 14px;
            color: #f8f8f8;
            letter-spacing: 0;
            text-align: center;
            margin-top: 35px;
            .btn_create,
            .btn_cancel {
              box-sizing: border-box;
              width: 92px;
              height: 32px;
              background: #3a4252;
              border: 1px solid #3bc1e5;
              border-radius: 4px;
              line-height: 32px;
              cursor: pointer;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
  .player-tip {
    width: 32px;
    height: 20px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    color: #d4f8ff;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    bottom: 14px;
    left: 8px;
    z-index: 99999;
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item__label {
    color: #fff;
  }
  .el-select {
    display: block;
    width: 200px;
  }
  .videoWallOsd {
    .timeStr{
      right: 53px;
    }
  }
}
</style>
