<script>
import TaskLibraryModal from "./components/task-library-modal/index.vue";
import { mapState } from "vuex";
import TaskDetailModal from "./components/task-detail-modal/index.vue";
import TaskThrustModal from "./components/task-thrust-modal/index.vue";
import UavContent from "@/views/uavApplications/components/flight-popup2/flight-uav-control/uav-content/index.vue";

export default {
  name: "Index",
  components: {
    UavContent,
    TaskThrustModal,
    TaskDetailModal,
    TaskLibraryModal,
  },
  data() {
    return {
      taskLibraryModalShow: false,
      currentTabIndex: 0,
      currentTask: undefined,
      currentExecuteTask: undefined,
      taskDetailModalShow: false,
      taskThrustModalShow: false,
    };
  },
  computed: {
    ...mapState("uavApplicationsEagleNest", ["currentEagle"]),
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
  },
  watch: {
    "$store.state.uavApplications.showVideo": {
      handler(val) {
        this.taskThrustModalShow = val;
      },
      deep: true,
      immediate: true,
    },
    currentEagle(data) {
      if (data) {
        if (data.uav) {
          this.taskLibraryModalShow = true;
        }
        if (data.uav?.streamConfiguration) {
          this.taskThrustModalShow = true;
        }
      } else {
        this.taskLibraryModalShow = false;
        this.taskThrustModalShow = false;
        this.taskDetailModalShow = false;
      }
    },
  },
  methods: {
    // 任务库弹窗
    tabChangeEvent(index) {
      this.currentTabIndex = index;
    },
    taskLibraryModalClose() {
      this.taskLibraryModalShow = false;
      this.currentExecuteTask = undefined;
      // this.$store.commit('uavApplications/setState', { key: 'uav', value: null });
      this.$store.commit("uavApplications/setState", {
        key: "routeId",
        value: null,
      });
    },
    // 任务详情弹窗
    showTaskDetailEvent(row) {
      this.currentTask = row;
      this.taskDetailModalShow = true;
    },
    closeTaskDetailModal() {
      this.taskDetailModalShow = false;
    },
    //   设置当前执行任务
    setCurrentTaskEvent(task) {
      this.currentExecuteTask = task;
    },
    //   推流视频弹窗
    taskThrustModalClose() {
      this.taskThrustModalShow = false;
      this.$store.commit("uavApplications/setState", {
        key: "showVideo",
        value: false,
      });
    },
    // 结束任务后更新任务库数据
    updateTaskList() {
      this.$store.commit("uavApplications/setState", {
        key: "routeId",
        value: null,
      });
      // 解除鹰巢下无人机任务id
      this.$store.commit("uavApplications/setState", {
        key: "currentEagle",
        value: {
          ...this.currentEagle,
          uav: { ...this.currentEagle.uav, taskId: null, taskType: null },
        },
      });
      this.$refs.taskLibraryModalRef.getTaskLibraryList(this.currentTabIndex, [
        0,
      ]);
      this.currentExecuteTask = undefined;
    },
    //   更新任务
    updateTaskLibraryList({ type, taskStatusList }) {
      this.$refs.taskLibraryModalRef.getTaskLibraryList(type, taskStatusList);
    },
  },
};
</script>

<template>
  <div class="eagle-operate">
    <!--任务库-->
    <task-library-modal
      v-if="taskLibraryModalShow"
      ref="taskLibraryModalRef"
      :show="taskLibraryModalShow"
      @tabChangeEvent="tabChangeEvent"
      @showTaskDetailEvent="showTaskDetailEvent"
      @close="taskLibraryModalClose"
      @setCurrentTaskEvent="setCurrentTaskEvent"
    />
    <!--任务详情-->
    <task-detail-modal
      v-if="taskDetailModalShow"
      :current-tab="currentTabIndex"
      :show="taskDetailModalShow"
      :current-task-id="currentTask.id"
      @close="closeTaskDetailModal"
    />
    <!--推流视频弹窗-->
    <task-thrust-modal
      v-if="taskThrustModalShow"
      :show="taskThrustModalShow"
      @close="taskThrustModalClose"
    />
    <UavContent
      v-if="$uav && currentEagle"
      :task-details="currentExecuteTask"
      @getTaskList="updateTaskList"
      @updateTaskLibraryList="updateTaskLibraryList"
    />
  </div>
</template>

<style scoped lang="scss"></style>
