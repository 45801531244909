<template>
  <div class="cpt-observe-control-popup-choose">
    <div class="choose_box">
      <div class="box_title">
        <div class="title">选择任务</div>
        <div class="xicon iconfont icon-guanbi" @click="$emit('fn')" />
      </div>
      <div class="box_centent">
        <div class="title">任务：</div>
        <div class="input_box" @click.stop="select_active = !select_active">
          <div class="task_name">任务名称</div>
          <div class="iconfont icon-dropdown" />
        </div>
        <div v-show="select_active" class="select_box">
          <div class="select_input_box">
            <span class="iconfont icon-chaxun" />
            <input class="input" type="text" placeholder="请输入要搜索的内容" />
            <div class="result_box">
              <div class="result_item">解放大道日常巡视任务</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box_buttom">
        <button v-hover>{{$t('operation.Cancel')}}</button>
        <button v-hover>发送任务至无人机</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      select_active: false
    };
  }
};
</script>

<style lang="scss" scoped>
.cpt-observe-control-popup-choose {
  width: 323px;
  height: 170px;
  position: absolute;
  top: -500px;
  right: 200px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  .choose_box {
    width: 323px;
    height: 170px;
    padding: 10px 15px;
    box-sizing: border-box;
    .box_title {
      height: 20%;
      display: flex;
      justify-content: space-between;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 1px;
        background: #494949;
      }
      .title {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #ebf5ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
      .iconfont {
        font-size: 20px;
        color: #ebf5ff;
        cursor: pointer;
      }
    }
    .box_centent {
      height: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input_box {
        width: 250px;
        height: 32px;
        background: #16181b;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        .task_name {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #c6ccd4;
          letter-spacing: 0;
          font-weight: 400;
        }
        .iconfont {
          font-size: 14px;
          color: #b3bbc5;
        }
      }
      .select_box {
        width: 250px;
        height: 207px;
        background: rgba(19, 21, 24, 0.7);
        box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
          inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
        border-radius: 8px;
        position: absolute;
        top: 86px;
        left: 58px;
        padding: 5px 5px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        .select_input_box {
          width: 238px;
          height: 32px;
          background: #16181b;
          border-radius: 8px;
          line-height: 32px;
          .iconfont {
            color: #1eb9fa;
            font-size: 14px;
            margin: 0 10px;
          }
          .input {
            height: 80%;
            width: 70%;
            background: #16181b;
            border: none;
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #c6ccd4;
            letter-spacing: 0;
            font-weight: 400;
            padding: 0 5px;
            box-sizing: border-box;
            &:focus {
              outline: none;
            }
          }
          .result_box {
            width: 234px;
            height: 145px;
            // background: #171a1c;
            margin-top: 5px;
            margin-left: 2px;
            border: 1px solid transparent;
            border-radius: 2px;
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            background-image: linear-gradient(to right, #222, #222),
              linear-gradient(90deg, #325d93, #55d1ec);
            .result_item {
              font-family: MicrosoftYaHei;
              font-size: 12px;
              color: #c6ccd4;
              letter-spacing: 0;
              font-weight: 400;
              height: 29px;
              padding: 1px 5px;
              box-sizing: border-box;
            }
          }
        }
      }
      .title {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #b3bbc5;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
    .box_buttom {
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      height: 40%;
      align-items: center;
      button {
        background: #3a4252;
        border: 1px solid #3bc1e5;
        border-radius: 4px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #f8f8f8;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
        &:nth-child(1) {
          width: 92px;
          height: 32px;
        }
        &:nth-child(2) {
          width: 140px;
          height: 32px;
        }
      }
    }
  }
}
</style>
