<script>
import MessageModal from "@/components/common/message-modal/index.vue";
import EaglePlayer from "../eagle-player/index.vue";
import UavPlayer from "../uav-player/index.vue";
import { mapState } from "vuex";
import { Base64 } from "js-base64";

export default {
  name: "Index",
  components: {
    MessageModal,
    EaglePlayer,
    UavPlayer,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      thrustDataList: [
        {
          label: "webrtc",
          brand: "webrtc",
          url: "webrtc://live.mmcuav.cn/hls/shouguang01",
        },
      ],
    };
  },
  computed: {
    ...mapState(["openLeftNavSilder"]),
    ...mapState("uavApplicationsEagleNest", ["currentEagle"]),
    ...mapState("uavApplications", ["uav"]),
    // 舱外
    getOutsideThrustData() {
      return (
        this.currentEagle?.uav?.streamConfiguration
          ?.find((v) => v.videoType === 3)
          ?.streamUrlMessage.map((v) => ({
            label: v.streamType.toLocaleLowerCase(),
            url: this.coverStreamUrl(v.streamUrl, this.currentEagle.hardId),
            brand: "livenvr",
          })) || []
      );
    },
    // 舱内
    getInsideThrustData() {
      return (
        this.currentEagle?.uav?.streamConfiguration
          ?.find((v) => v.videoType === 2)
          ?.streamUrlMessage.map((v) => ({
            label: v.streamType.toLocaleLowerCase(),
            url: this.coverStreamUrl(v.streamUrl, this.currentEagle.hardId),
            brand: "livenvr",
          })) || []
      );
    },
    // 挂载
    getUavThrustData() {
      let currentEagleData=   this.currentEagle?.uav?.streamConfiguration?.filter((v) => v.videoType === 1)
      if(currentEagleData){
     return currentEagleData.flatMap(item =>(item.streamUrlMessage.map((v) => ({
            label: v.streamType.toLocaleLowerCase(),
            url: this.coverStreamUrl(v.streamUrl, this.currentEagle.hardId),
            brand:this.thrustDataList[v.streamType],
          }))))
      }else{
        return []
        }
       
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    // 转换拉流地址
    coverStreamUrl(url, hardId) {
      if (url.includes("https://live.mmcuav.cn")) {
        const str = Base64.encode(
          JSON.stringify({
            token: localStorage.getItem("tmj_token"),
            actionId: new Date().getTime().toString(),
            deviceHardId: hardId,
            environment: process.env.VUE_APP_ENV === "dev" ? "sit" : "prod",
          })
        );
        return `${url}?${str}`;
      } else {
        return url;
      }
    },
  },
};
</script>

<template>
  <message-modal
    :show="show && !openLeftNavSilder"
    :width="440"
    :position-top="491"
    :position-left="70"
    @close="close"
  >
    <template slot="title">
      <div class="task-thrust-title">
        <div class="title-left">{{$t('flightApplication.HangarList')}}</div>
        <div class="title-center">{{ currentEagle.name || "" }}</div>
      </div>
    </template>
    <div class="task-thrust-content">
      <div class="eagle-player-wrap">
        <eagle-player
          v-if="getInsideThrustData.length"
          :thrust-data-list="getInsideThrustData"
          style="margin-right: 8px"
          :tip="$t('flightApplication.inboard')"
        />
        <eagle-player
          v-if="getOutsideThrustData.length"
          :thrust-data-list="getOutsideThrustData"
          :tip="$t('flightApplication.outboard')"
        />
      </div>
      <div class="uav-player-wrap">
        <uav-player
          v-if="getUavThrustData.length"
          :thrust-data-list="getUavThrustData"
        />
      </div>
    </div>
  </message-modal>
</template>

<style scoped lang="scss">
.task-thrust-title {
  display: flex;
  align-items: center;
  .title-left {
    font-size: 14px;
    color: #ebf5ff;
    line-height: 22px;
    padding: 0 9px;
    height: 22px;
    min-width: 76px;
    text-align: center;
    box-sizing: border-box;
    background-image: url("./bg.png");
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-left: 7px;
  }
  .title-center {
    font-size: 14px;
    font-weight: bold;
    color: #34dffe;
    line-height: 19px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.task-thrust-content {
  padding: 5px;
  .eagle-player-wrap {
    display: flex;
  }
}
.eagle-player-wrap {
  margin-bottom: 8px;
}
::v-deep {
  .uav-player-wrap {
    .cpt_video {
      width: 844px;
    }
  }
}
</style>
