<template>
  <div class="cpt-dashborad-iamgecpt">
    <div v-for="(item, index) in urtemp" :key="index" class="track_item">
      <!-- <span class="isGou"></span> -->
      <span class="info">{{ item.createTime }}</span>
      <span class="operate" @click="onPreview(index)">预览</span>
    </div>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="urls"
      :initial-index="index"
    />
  </div>
</template>

<script>
import { flightTaskAPI } from '@/api';

import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
  components: { ElImageViewer },

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      urtemp: null,
      urls: null,
      showViewer: false,
      index: 0
    };
  },
  mounted() {
    // if (this.data.taskStatus == 3) {
    // }
    console.log(' this.data.id:', this.data.id);
    this.getImages();
  },
  methods: {
    async getImages() {
      const data = await flightTaskAPI.getTaskImages({
        task_id: this.data.id
        // task_id: "666",
      });
      if (data.status == 200) {
        this.urtemp = data.data;
        this.urls = this.urtemp.map((item) => item.images);
      }
    },
    onPreview(index) {
      this.showViewer = true;
      this.index = index;
    },
    closeViewer() {
      this.showViewer = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-dashborad-iamgecpt {
  // width: 373px;
  height: 175px;
  background: #171a1c;
  border-radius: 2px;
  padding: 0 8px;
  box-sizing: border-box;
  overflow-y: scroll;

  .track_item {
    // background: #282b33;
    // border: 1px solid #50abff;
    // box-shadow: inset 0 -1px 0 0 #000000;
    height: 29px;
    line-height: 29px;
    // display: flex;
    // justify-content: space-between;
    .isGou {
      width: 22px;
      display: inline-block;
    }
    .type {
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #689eea;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      border-radius: 30%;
      border: #689eea 1px solid;
      margin: 0 5px;
    }
    .info {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #eaf6ff;
      letter-spacing: 0;
      font-weight: 400;
    }
    .operate {
      float: right;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #00feff;
      letter-spacing: 0;
      font-weight: 400;
      cursor: pointer;
    }
  }
}
</style>
