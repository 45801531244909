<template>
  <div class="views-home">
    <HomeHeader @changeList="onHeaderChangeList" />
    <HomeNav v-if="isLogin" />
    <!-- <FlightPopup v-if="active == 1"
    ref="flightPopup" />-->
    <!-- 航线新增 -->
    <AirwayPopup v-if="active == 2 || active == 3" @point-info="pointInfo" />
    <!-- 任务创建-->
    <FlightTaskCreate v-if="active == 4" />
    <!-- 任务选择弹窗 -->
    <SelectTask v-if="active == 5" />
    <!-- 任务编辑 -->
    <FlightTaskEdit v-if="active == 6" />
    <!-- 任务操作弹窗 -->
    <FlightTaskTooptip v-if="active == 7" />
    <!-- 航线操作弹窗 -->
    <FlightLineTooptip v-if="active == 8" />
    <!-- 航线编辑 -->
    <AirwayChange v-if="active == 9" />
    <!-- 右侧操作栏及展示-->
    <ConTrolBar v-if="$uav || currentEagle" @changeAi="AiChange" />
    <!-- 无人机操作台 -->
    <DashBoardZl
    ref='DashBoardZl'
      v-show="showMenu"
      :data="uavInfo"
      @Back="
        () => {
          showMenu = false;
        }
      "
      @fn="uav_control_ws"
    />
    <!-- 原视频 -->
    <MMCPlayer
      v-if="!currentEagle && showVideo && $uav"
      ref="MMCPlayer"
      class="MMCPlayerStyle"
      :title="$uav.name.length > 20 ? $uav.name : $uav.name + '#实时视频'"
      :stream-options="streamOptions"
      :obstacle-data="null"
      :size="1"
      network-type=""
      @close="showVideo = false"
    >
      <div class="video-control">
        <div title="拍照" class="item-box photograph" @click="photograph" />
        <div
          title="录屏"
          class="item-box"
          :class="screenRecordingStatus ? 'pictureRecording' : 'pictureRecordingStop'"
          @click="screenRecording"
        />
        <div title="截屏" class="item-box screenshot" @click="screenshot" />
        <div class="item-box aiPhoto">
          <div class="aiPhoto-img" title="AI预警" @click="aiPhoto" />
          <el-collapse-transition class="showAi">
            <div v-show="active_list" class="ailist-wrap">
              <div class="ailist-wrap-head">
                <div
                  class="ailist-wrap-head-btn ai"
                  :class="`${aiActive === 1 ? 'active' : null}`"
                  @click.stop="changeAiActive(1)"
                >
                  一键识别
                </div>
                <div
                  class="ailist-wrap-head-btn"
                  :class="`${aiActive === 2 ? 'active' : null}`"
                  @click.stop="changeAiActive(2)"
                >
                  监控布防
                </div>
              </div>
              <div v-show="aiActive === 1" class="ailist" style="height: 130px">
                <div
                  v-for="(item, index) in active_list"
                  :key="index"
                  class="img_items"
                >
                  <div
                    ref="back_img"
                    class="img_item"
                    @click.stop="active_ailist_item(item, index, $event)"
                  >
                    <div class="img_item_text">
                      <span
                        class="item_text_img"
                        :class="{
                          selected_text: index === currentPhotiIndex,
                        }"
                        >{{ item.name }}</span
                      >
                    </div>
                    <img
                      crossorigin="anonymous"
                      :src="item.icon_url"
                      alt
                      :class="{
                        selected: index === currentPhotiIndex,

                        images_item: index != currentPhotiIndex,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div v-show="aiActive === 2" class="supervised-arm">
                <div v-show="!supervisedTimeOut" class="supervised-arm-body">
                  <el-form
                    ref="supervisedForm"
                    :rules="rules"
                    :model="supervisedForm"
                    label-width="70px"
                  >
                    <el-form-item label="选择算法" prop="algorithmType">
                      <el-select
                        v-model="supervisedForm.algorithmType"
                        placeholder="请选择算法"
                        multiple
                        collapse-tags
                        filterable
                      >
                        <el-option
                          v-for="item in active_list"
                          :key="item.detectortype"
                          placeholder="请选择算法"
                          :label="item.name"
                          :value="item.detectortype"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item label="抽帧间隔" prop="selectType">
                      <el-radio-group
                        v-model="supervisedForm.selectType"
                        @change="changeSelectType"
                      >
                        <el-radio :label="1">标准</el-radio>
                        <el-radio :label="2">自定义</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item
                      v-show="supervisedForm.selectType === 1"
                      label="抽帧频率"
                      prop="timeOut"
                    >
                      <div style="display: flex">
                        <el-select
                          v-model="supervisedForm.timeOut"
                          placeholder="请选择"
                          filterable
                        >
                          <el-option
                            v-for="item in timeOutList"
                            :key="item.label"
                            placeholder="请选择"
                            :label="item.label"
                            :value="item.label"
                          />
                        </el-select>
                        <div class="arm-body-item-unit">秒/次</div>
                      </div>
                    </el-form-item>
                    <el-form-item
                      v-show="supervisedForm.selectType === 2"
                      label="抽帧频率"
                      prop="timeOut"
                    >
                      <div style="display: flex">
                        <el-input
                          v-model="supervisedForm.timeOut"
                          placeholder="请输入抽帧频率"
                          clearable
                          min="0"
                        />
                        <div class="arm-body-item-unit">秒/次</div>
                      </div>
                    </el-form-item>
                    <div class="form_btn">
                      <div class="btn_create" @click="onCloseSupervisedForm">
                        {{$t('operation.Cancel')}}
                      </div>
                      <div class="btn_cancel" @click="onSumbitSupervisedForm">
                        {{$t('operation.Confirm')}}
                      </div>
                    </div>
                  </el-form>
                </div>
                <div v-show="supervisedTimeOut" class="supervised-arm-body">
                  <div class="arm-body-item">
                    <div class="arm-body-item-left">布防状态</div>
                    <div class="arm-body-item-right">
                      <div class="arm-input">布防中</div>
                    </div>
                  </div>
                  <div class="arm-body-item">
                    <div class="arm-body-item-left">布防算法</div>
                    <div class="arm-body-item-right">
                      <div class="arm-input">
                        <span v-for="item in algorithmTypeList" :key="item.id"
                          >{{ item.name }} <br
                        /></span>
                      </div>
                    </div>
                  </div>
                  <div class="arm-body-item">
                    <div class="arm-body-item-left">抽帧频率</div>
                    <div class="arm-body-item-right">
                      <div class="arm-input" style="width: 100px">
                        {{ supervisedForm.timeOut }}
                      </div>
                    </div>
                    <div class="arm-body-item-unit" style="width: 30px">
                      秒/次
                    </div>
                  </div>
                  <div class="form_btn">
                    <div class="btn_create" @click="offSupervisedTimeOut">
                      停止布防
                    </div>
                    <div class="btn_cancel" @click="offSupervisedTimeOut">
                      编辑布防
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
      <OSD :osdStyle="{height:'94%'}" :isInfoShow='isInfoShow'></OSD>
      <div class="cf bottom7 left60 osdInfo pa" style="z-index: 11">
          <div>
            <el-tooltip
              class=" w20"
              content="无人机信息"
              placement="top"
            >
              <div
                class="cp"
                @click="isInfoShow = !isInfoShow"
              >
              <img src="@/assets/image/uav.png"></img>
              </div>
            </el-tooltip>
          </div>
        </div>
    </MMCPlayer>

    <!-- ai视频 -->
    <PlayerAi
      v-if="showAIVideo"
      :videos-url="video"
      :ai-video-info="aiInfo"
      :flight-info="uavInfoValues"
      @clickCloseAi="showAIVideo = false"
    />
    <Applications_uavContent v-if="$uav && !currentEagle" />
    <AiList v-if="is_active_ailist" />
    <!-- <SearchPlace /> -->
    <SideList />
    <UavDetail v-if="active == 10" />
    <CompanyDetail v-if="active == 11" />
    <!--  v-if="active === 12" -->
    <AchievementDatabase v-if="showViewLibrary" ref="viewLibrary" />
    <!--下载中心-->
    <DownloadCenter v-if="downoadPageShow" />
    <!--鹰巢操作-->
    <eagle-operate />
    <!-- 天气预报 -->
    <!--    <WeatherTooptip v-if="active == 13" />-->
    <!-- 键盘控制飞机 -->
    <keyControl    v-if="keyControlFlag" @close="closeFn"></keyControl>
      <!-- selectOperation = null; -->
  </div>
</template>

<script>
import FlightPopup from "@/components/home/flight-popup";
import AirwayPopup from "@/components/home/airway-edit/airway-popup";
import HomeNav from "@/components/home/navsilder";
import HomeHeader from "@/components/home/header/index";
import FlightTaskCreate from "@/components/home/flight-popup/flight-task-create";
import FlightTaskEdit from "@/components/home/flight-popup/flight-task-edit";
import FlightTaskTooptip from "@/components/home/flight-popup/flight-task-tooptip";
import AirwayChange from "@/components/home/airway-edit/airway-change";
import ConTrolBar from "@/components/control-bar";
import DashBoard from "@/components/observe/dashboard";
import DashBoardZl from "@/components/observe1/dashboard";
import keyControl from "@/components/observe1/dashboard/keyControl";
import SelectTask from "@/components/home/select-task";
import Player from "@/components/home/player";
import PlayerAi from "@/components/home/player/ai.vue";
import AiList from "@/components/home/ailist";
import SearchPlace from "@/components/home/search-place";
import FlightLineTooptip from "./components/flight-popup2/flight-line-tooptip";
import SideList from "@/components/home/sideList";
import UavDetail from "@/components/home/uav-detail";
import CompanyDetail from "@/components/home/company-detail";
import AchievementDatabase from "./components/achievement-database";
import DownloadCenter from "@/components/home/download-center";
import WeatherTooptip from "@/components/eagle-nest/weather-tooptip";
import linkFusion from "./mixins/linkFusion";
import dayjs from "dayjs";
import Applications_uavContent from "./components/applications_uavContent";
import EagleOperate from "@/components/eagle-nest/eagle-operate/index.vue";
import MonitoringParameters from "@/components/home/monitoring-parameters/index.vue";
import OSD from "@/views/videoWall/com/osd/index.vue";
import uav from "./data";
import methods from "./methods";
import { mapState, mapMutations } from "vuex";
import { Control_API, flightTaskAPI, LoginAPI, AI, Tg } from "@/api";
import { coverStreamUrl } from "@/utils";
import { to_moveMount } from "@/utils/to_moveMount.js";
import {MMC_Gimbal_ZT60R} from '@/components/mount/MMC_Gimbal_ZT60R/js/index.js';
export default {
  components: {
    HomeNav,
    HomeHeader,
    FlightPopup,
    AirwayPopup,
    FlightTaskCreate,
    FlightTaskEdit,
    FlightTaskTooptip,
    FlightLineTooptip,
    ConTrolBar,
    DashBoard,
    SelectTask,
    Player,
    PlayerAi,
    AiList,
    AirwayChange,
    SearchPlace,
    DashBoardZl,
    keyControl,
    SideList,
    UavDetail,
    CompanyDetail,
    DownloadCenter,
    AchievementDatabase,
    WeatherTooptip,
    Applications_uavContent,
    EagleOperate,
    MonitoringParameters,
    OSD
  },
  mixins: [linkFusion],
  provide() {
    return {
      // 方法
      active: () => this.active,
      active_ailist: this.active_ailist,
      change_active: this.change_active,
      c_demo: this.c_demo,
      c_dashBoard: this.c_dashBoard,
      c_player: this.c_player,
      c_player_ai: this.c_player_ai,
      get_flight_info: this.get_flight_info,
      flight_info: () => this.flight_info,
      get_route_info: this.get_route_info,
      route_info: () => this.route_info,
      cancel_Handler: this.cancel_Handler,
      destroy_cesium: this.destroy_cesium,
      save_airline: this.save_airline,
      edit_point_info: this.edit_point_info,
      point_info: () => this.points_info,
      clear_airline: this.clear_airline,
      send_to_task_tooptip: this.send_to_task_tooptip,
      tooptip_flight_info: () => this.tooptip_flight_info,
      send_to_line_tooptip: this.send_to_line_tooptip,
      tooptip_line_info: () => this.tooptip_line_info,
      send_airline_info: this.send_airline_info,
      airline_info: () => this.airline,
      control_show: this.controlShow,
      get_flight_state: this.get_flight_state,
      get_sidelist_item: this.get_sidelist_item,
      side_list_item: () => this.side_list_item,
    };
  },
  inject: ["g_cesium_layer", "reload"],
  data() {
    const validateTimeOut = (rule, value, callback) => {
      if (
        /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value) ==
        false
      ) {
        return callback(new Error("请输入正整数或两位小数!"));
      }
      if (!value && this.supervisedForm.selectType === 1) {
        return callback(new Error("请选择抽帧频率！"));
      } else if (!value && this.supervisedForm.selectType === 1) {
        return callback(new Error("请输入抽帧频率！"));
      }
      callback();
    };
    return {
      payload:null,
      isInfoShow:false,
      flightSortie: "",
      active_list: null,
      isLogin: localStorage.getItem("tmj_token"),
      is_active_ailist: false,
      ...uav,
      currentIndex: 0, // ai视频选项index
      currentPhotiIndex: 0, // ai视频选项index
      active: 0,
      uavInfo: {},
      video_rtmp: {},
      see: false,
      videos: [],
      videoType: "flv", // 优化使用的视频拉流类型
      list: {},
      deviceType: "", // 用于存储挂载类型
      controlShow: {
        isShow: 1,
      }, // 控制台指令切换 1为指令 2为自适应
      c_demo: {
        isShow: false,
      }, // 一键演示视频是否显示
      c_dashBoard: {
        isShow: false,
      }, // 控制仪表盘是否显示
      c_player: {
        isShow: false,
      }, // 控制视频播放器是否显示
      c_player_ai: {
        isShow: false,
      }, // 控制视频播放器是否显示
      point_info: {},
      flight_info: {}, // 飞行任务
      trackPoints: {}, // 航迹
      aiInfo: {},
      route_info: {}, // 航线列表信息
      airline: {}, // 绘制的航线
      points_info: [], // 编辑后的航点
      tooptip_flight_info: {},
      tooptip_line_info: {},

      data_srouce_from_add: {}, // 规划的实体类
      positions_from_add: [],

      newest_pointsinfo: {},

      isFromEdit: false, // 是否来自编辑
      side_list_item: {},
      downoadPageShow: false, // 下载中心页面
      uavInfoVideo: [],
      streamOptions: [
        // 视频流数据
        /* {
          label: "flv",
          brand: "flv",     //支持以下品牌播放器"srs|fly|liveNVR|QingLiu|webrtc", 为空则根据url判断使用liveNVR或QingLiu
          url:
            "https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv",
        },
        {
          label: "liveNVR",
          brand: 'liveNVR',
          url:
            "https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv",
          fpvUrl:     //只有liveNVR支持fpvUrl
            "webrtc://live.mmcuav.cn/hls/shouguang01",
        },
        {
          label: "srs",
          brand: 'srs',
          url:
            "webrtc://live.mmcuav.cn/hls/shouguang01",
        },
        {
          label: "QingLiu",
          url:
            "pzsp://pzlink999bju.powzamedia.com:7788/live/ld/trans/test/mlinkm/channel1?ndselect=2&linkmode=8&fstusrd=1&us=1&wsinfo=pzlink999bju.powzamedia.com-13000&only-video=1&rct=500",
        },
        {
          label: "WebRtc",
          brand: 'webrtc',
          url:
            "webrtc://live.mmcuav.cn/hls/shouguang01",
        }, */
        {
          label: "WebRtc",
          brand: "webrtc",
          url: "webrtc://live.mmcuav.cn/hls/shouguang01",
        },
      ],
      videosType: [],
      flowType: "",
      videosSelectList: false,
      screenRecordingStatus: true,
      aiActive: 1,
      supervisedTimeOut: null,
      supervisedForm: {
        algorithmType: null,
        selectType: 1,
        timeOut: null,
      },
      algorithmTypeList: [],
      timeOutList: [
        { label: 1 },
        { label: 2 },
        { label: 3 },
        { label: 4 },
        { label: 5 },
        { label: 10 },
        { label: 20 },
        { label: 30 },
      ],
      rules: {
        algorithmType: [
          { required: true, message: "请选择算法类型", trigger: ["change"] },
        ],
        selectType: [
          { required: true, message: "请选择抽帧间隔", trigger: ["change"] },
        ],
        timeOut: [
          { required: true, validator: validateTimeOut, trigger: ["change"] },
        ],
      },
      // 状态锁,处理停止布防后还一直提示截屏的问题
      stopMethod: false,
    };
  },
  computed: {
    ...mapState([
      "uavLoginInfo",
      "userInfo",
      "taskDeviceData",
      "leftMenuAction",
    ]),
    ...mapState("uavApplicationsEagleNest", ["currentEagle"]),
    cesium_layer() {
      return this.g_cesium_layer();
    },
    keyControlFlag() {
      return this.$store.state.uavApplications.keyControlFlag;
    },
    video() {
      if (this.$uav.network == 1) {
        return { vUrl: this.$refs?.MMCPlayer?.vUrl?.vUrl || "" };
      } else if (this.uavInfoVideo.length) {
        this.nowVideosUrl = this.uavInfoVideo.find(
          (item) => item.videoType === this.flowType
        );
        this.videosType = this.uavInfoVideo.map((item) => item.videoType);
        // 测试
        // {
        //  vUrl:"https://pull.mmcuav.cn/live/0077.flv",
        //   videoType:"rtmp"
        // }
        return this.nowVideosUrl;
      } else {
        return { vUrl: this.$refs?.MMCPlayer?.vUrl?.vUrl || "" };
      }
    },
    uavInfoValues() {
      if (this.$uav.network == 1) {
        return {
          id: this.$uav.id,
          name: this.$uav.hardId, // 设置ai播放器标题
          deviceName: this.$uav.hardId,
        };
      } else {
        return {
          id: this.$uav.id,
          name: this.$uav.hardId, // 设置ai播放器标题
          deviceName: this.$uav.hardId,
        };
      }
    },
    mountList() {
      return this.$store.state.uavApplications.mountList;
    },
    selectMount() {
      return this.$store.state.uavApplications.selectMount;
    },
    MountZt60R() {
      return this.mountList.filter(
        (item) => item.gimbalName == "MMC_Gimbal_ZT60R"
      );
    },
    // 是否显示视频
    showVideo: {
      get() {
        return this.$store.state.uavApplications.showVideo;
      },
      set(newVal) {
        this.$store.commit("uavApplications/setState", {
          key: "showVideo",
          value: newVal,
        });
      },
    },
    // 是否显示鹰巢视频
    showEagleNestVideo: {
      get() {
        return this.$store.state.uavApplicationsEagleNest.showVideo;
      },
      set(newVal) {
        this.$store.commit("uavApplicationsEagleNest/setState", {
          key: "showVideo",
          value: newVal,
        });
      },
    },
    // 是否显示AI视频
    showAIVideo: {
      get() {
        return this.$store.state.uavApplications.showAIVideo;
      },
      set(newVal) {
        this.$store.commit("uavApplications/setState", {
          key: "showAIVideo",
          value: newVal,
        });
      },
    },
    // 是否显示视图库
    showViewLibrary: {
      get() {
        return this.$store.state.uavApplications.showViewLibrary;
      },
      set(newVal) {
        this.$store.commit("uavApplications/setState", {
          key: "showViewLibrary",
          value: newVal,
        });
      },
    },
    // 是否显示操作菜单
    showMenu: {
      get() {
        return this.$store.state.uavApplications.showMenu;
      },
      set(newVal) {
        this.$store.commit("uavApplications/setState", {
          key: "showMenu",
          value: newVal,
        });
      },
    },
    uavRealTimeData() {
      return this.$store.state.uavApplications.uavRealTimeData;
    },
    move_data(){
      return this.$store.state.uavApplications.move_data
    }
  },
  watch: {
    "MountZt60R.payload": {
      handler(val) {
      this.payload= val
      },
      deep: true,
    },
    videos: {
      
      handler(value) {
        /* value.forEach((element) => {
          if (element.videoType == "flv" || element.videoType == "FLV") {
            // this.video =element ;
            // this.video = element
          }
          if (element.videoType == "rtmp" || element.videoType == "RTMP") {
            this.video_rtmp = element
          }
        }) */
      },
      deep: true,
      immediate: true,
    },
    flight_info: {
      async handler(value) {
        // console.log('flight_info...', value); // huoqubudaos
        if (Object.keys(value).length > 0 && value.deviceStatus) {
          if (!this.isFromEdit) {
            this.uav_init_ws();
            try {
              const res = await flightTaskAPI.deviceData({
                deviceHardId: value.deviceHardId,
              });
              if (Array.isArray(res.data) && res.data.length > 0) {
                const deviceData = res.data.pop();
                this.uav.control.uavInfo = deviceData.data.uavInfo;
                this.uav_fn(1, this.uav.control.uavInfo);
                this.SET_CESIUM_STATE(true);
                this.SET_TASK_DEVICE_DATA(deviceData);
              }
            } catch (e) {}
          }
        } else {
          try {
            this.uav_destroy_ws();
            this.uav_de_location();
            this.uavInfo = {};
          } catch (err) {
            // console.log(err);
          }
        }
      },
      deep: true,
      immediate: true,
    },
    // 监视无人机是否选中
    $uav(newVal, oldVale) {
      if (!newVal || !newVal.id) {
        this.streamOptions = [];
      } else {
        this.streamOptions = [];
        // 取得视频地址
        Control_API.uavDetail({
          id: newVal.id,
        }).then((res) => {
          if (res.data.streamConfiguration) {
            res.data.streamConfiguration.forEach((item1) => {
              // 1为挂载
              if (item1.videoType == 1) {
                item1.streamUrlMessage.forEach((item2) => {
                  let brand = item1.videoSource?.toUpperCase() || "";
                  if (item2.streamType === "WEBRTC") {
                    brand = "SRS";
                  }
                  this.streamOptions.unshift({
                    label: item2.streamType,
                    brand: brand,
                    url:item2.streamUrl,
                      // coverStreamUrl(item2.streamUrl, this.$uav.hardId) || "",
                    // 只有liveNVR支持fpvUrl
                    fpvUrl: "",
                  });
                });
              }
            });
          }
        });
      }
    },
    showVideo(newVal) {
      if (!newVal) {
        this.showAIVideo = false;
      } else {
        this.$nextTick((itme) => {
          const dom = document.querySelector(".interact-wrap");
          if (dom) {
            dom.style.marginLeft = "100px";
            dom.style.marginTop = "-220px";
            let cpt_video =dom.querySelector(".cpt_video");
            cpt_video.addEventListener("click",this.videoCcontrol);
          }
        });
      }
    },
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    // 获取微信登录信息
    // this.wxLogin()
    if (localStorage.getItem("tmj_token") == null) {
      const href = window.location.href;
      const data1 = href.split("?")[1]?.split("#")[0]?.split("&");
      const data = {};
      data1.forEach((item, index) => {
        if (index == 0) {
          data.code = item.split("=")[1];
        }
        if (index == 1) {
          data.state = item.split("=")[1];
        }
      });
      if (data.code && data.state) {
        LoginAPI.wxToken(data)
          .then((res) => {
            window.localStorage.setItem("user_info", JSON.stringify(res.data));
            window.localStorage.setItem("tmj_token", res.data.token);
            this.SET_USERINFO(res.data); // 用户信息
            this.$message({
              type: "success",
              message: "登录成功",
              duration: 1000,
            });
          })
          .catch((req) => {
            console.log(req, "token拿取失败");
          });
      }
    } else {
      console.log(localStorage.getItem("tmj_token"), "token获取");
    }
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  async mounted() {
    this.$store.commit("uavApplications/setState", {
      key: "cesiumViewer",
      value: window.viewer,
    });
    // setTimeout(()=>{
    //       this.isShowHeader = true
    //       console.log('头部显示')
    // },1000)

    // await this.uav_init_ws();
    // this.get_son_token();
    this.$bus.$on("headerFn", () => {
      this.showMenu = false;
      this.uav_de_location();
      this.uavInfo = {};
    });
    this.$bus.$on("clickUavTitle", () => {
      this.uav_flyToCenter();
    });
    this.$bus.$on("clickUavTab", (e) => {
      if (e) this.uav_isShow_entity();
    });
    this.$bus.$on("reloadHome", () => {
      this.reload();
    });
    this.$bus.$on("changeFlow", (isFlow) => {
      // console.log('changeFlow', this.taskDeviceData?.data?.videoInfo);
      // 切换到下一个视频格式
      let index = null;
      const types = this.taskDeviceData?.data?.videoInfo?.map((item) => {
        return item.videoType;
      });
      if (types) {
        // console.log('types', types);
        index = types.indexOf(this.videoType);
        if (index === this.taskDeviceData.data.videoInfo.length - 1) {
          index = 0;
        } else {
          index++;
        }
        this.videoType = types[index];
      }
    });
  },
  destroyed() {
    if (this.supervisedTimeOut) clearInterval(this.supervisedTimeOut);
    this.uav_destroy_ws();
    this.$store.commit("uavApplications/setState", { key: "uav", value: null });
  },

  methods: {
    ...mapMutations([
      "SET_USERINFO",
      "SET_PERMISSION",
      "SET_UAVLOGIN_INFO",
      "SET_CESIUM_STATE",
      "SET_TASK_DEVICE_DATA",
    ]),
    ...methods,
    getUniqueData(data) {
  if (!Array.isArray(data) || data.length === 0) {
    throw new Error('Input data must be a non-empty array');
  }
  const uniqueLabels = new Set(data.map(item => {
    if (typeof item.label !== 'string') {
      throw new Error('Each item must have a string label property');
    }
    return item.label;
  }));
  const uniqueData = [...uniqueLabels].map(label => {
    return data.find(item => item.label === label);
  });
  return uniqueData;
},
    videoCcontrol(el){
      let video= document.getElementById('rtc_media_player') ||  document.getElementsByClassName('vjs-tech')[0]
      if(!video){
        video=  document.getElementsByClassName("qingliu").length &&
        document.getElementsByClassName("qingliu")[0].childNodes[0].childNodes[0]
      }
      if(video){
                  setTimeout(() => {
                    video.play();
                  }, 20);
                  this.videoMove(el)
                }  
    },
    async videoMove(el){
      let move_data = this.move_data
      if(move_data &&move_data.mountStatus){
    let mountData = move_data;
        let evt = window.event || el;
        let div =document.querySelector(".cpt_video");
       const user_info= JSON.parse(window.localStorage.getItem("user_info"))
        let data = {
          x: evt.offsetX * (1920 / div.offsetWidth) - 960,
          y: -(evt.offsetY * (1080 / div.offsetHeight)) + 540,
          w: 1920,
          h: 1080
        };
        console.log(data,'data');
        // return
        let buffer = null;
        if (mountData.name == "MMC_Gimbal_Z30Pro") {
          // z30pro
          buffer = await to_moveMount.move_track_focus_ctrl(data.x, data.y);
        } else if (mountData.name == "MMC_Gimbal_Z40S") {
          //z40s
          buffer = await to_moveMount.move_or_focus_ctrl(data);
        } else if (mountData.name == "DJI_H20T") {
          let cx = evt.offsetX / div.offsetWidth;
          let cy = evt.offsetY / div.offsetHeight;
          let djbuff = "cameraAim:" + cx.toFixed(1) + "," + cy.toFixed(1);
          let data = {
            type: 200,
            systemCode: "mmc",
            state: 1, //0 地面站（无人机），1 客户端或者web ，2 HTTP接口
            username: user_info.userName,
            data: {
              cmdControlType: 7000, //520
              cmdBuffer: djbuff,
              cmdName: mountData.name
            },
            deviceHardId: this.$uav.hardId
          };
          //z40s
          // buffer = await to_moveMount.move_or_focus_ctrl(data);
        } else if (
          mountData.name == "MMC_Gimbal_Z60S" ||
          mountData.name == "MMC_Gimbal_ZT60R"||
          mountData.name == "MMC_Gimbal_Z60R"
        ) {
          buffer = await to_moveMount.move_track_focus_ctrl(data.x, data.y, 3);
        } else {
          buffer = await to_moveMount.gcs_click_coordinates(data);
        }
        if (buffer) {
          this.$store.dispatch('uavApplications/mounteControl', {
        mountId:this.selectMount.mountId,
        payload: buffer,
        callback: (isOk) => {
          isOk && console.log('发出控制挂载指令');
        }
      });
      }
      }
    },
    closeFn(){
      this.$store.commit("uavApplications/setState", {
        key: "keyControlFlag",
        value: false,
      });
      this.$refs.DashBoardZl.closekeyControl()
    },
    offSupervisedTimeOut() {
      if (this.supervisedTimeOut) {
        this.$message.warning("取消布防");
        clearInterval(this.supervisedTimeOut);
        this.supervisedTimeOut = null;
        this.algorithmTypeList = [];
        this.stopMethod = true;
      }
    },
    changeSelectType(value) {
      this.$refs["supervisedForm"].clearValidate("timeOut");
      if (this.supervisedForm.timeOut) {
        this.supervisedForm.timeOut = null;
      }
      this.supervisedForm.selectType = value;
    },
    onCloseSupervisedForm() {
      this.$refs["supervisedForm"].resetFields();
      this.aiPhoto();
    },
    onSumbitSupervisedForm() {
      this.$refs["supervisedForm"].validate((validate) => {
        if (validate) {
          this.active_list.map((v) => {
            if (this.supervisedForm.algorithmType.includes(v.detectortype)) {
              this.algorithmTypeList.push(v);
            }
          });
          const data = {
            detectortype: this.supervisedForm.algorithmType.join(","),
          };
          this.stopMethod = false;
          this.supervisedTimeOut = setInterval(() => {
            this.active_ailist_item(data);
          }, 1000 * this.supervisedForm.timeOut || 1000);
        }
      });
    },
    changeAiActive(active) {
      this.aiActive = active;
    },
    showVideosList() {
      this.videosSelectList = !this.videosSelectList;
    },
    getPosition() {
      let longitude = "";
      let latitude = "";
      if (
        this.$uav.locationCoordinate3D &&
        this.$uav.locationCoordinate3D.longitude
      ) {
        longitude = this.$uav.locationCoordinate3D.longitude;
        latitude = this.$uav.locationCoordinate3D.latitude;
      } else {
        const modelId = "model_" + this.$uav.hardId;
        const modelEntity = window.viewer.entities.getById(modelId);
        if (modelEntity) {
          // 获取模型的位置坐标
          let modelPosition = modelEntity.position.getValue(
            viewer.clock.currentTime
          );
          // 将位置坐标转换为经纬度坐标
          let modelCartographic =
            Cesium.Cartographic.fromCartesian(modelPosition);
          // 获取经纬度
          let lon = Cesium.Math.toDegrees(modelCartographic.longitude);
          let lat = Cesium.Math.toDegrees(modelCartographic.latitude);
          longitude = lon;
          latitude = lat;
        }

        return { longitude, latitude };
      }
    },
    captureVideo(video, type, item) {
      // 获取当前帧
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth || video.width;
      canvas.height = video.videoHeight || video.width;
      var canvasContext = canvas.getContext("2d");
      canvasContext.drawImage(video, 0, 0);
      let longitude= null;
      let latitude= null;
      // 判断当前飞机是否挂有zt60r挂载 如果有就使用zt60r返回的目标经纬度
      if(this.payload){
        const dataArray = new Uint8Array(this.payload);
          const zt60rPosition = MMC_Gimbal_ZT60R.gcs_transmit_ctrl(dataArray);
      if (zt60rPosition &&zt60rPosition.longitude) {
        longitude= zt60rPosition.longitude
        latitude= zt60rPosition.latitude
      }
      }else{
       // 否则使用无人机的经纬度
      let uavPosition = this.getPosition();
      longitude= uavPosition.longitude
      latitude= uavPosition.latitude
      }
  
      try {
        if (type === "aiPhoto") {
          canvas.toBlob(
            async (blob) => {
              if (blob) {
                const data = new FormData();
                data.append("image", blob);
                data.append("detectortype", item.detectortype);
                data.append("imgSave", "1");
                data.append("longitude", longitude);
                data.append("latitude", latitude);
                const res = await AI.getAiPhoto(data);
                if (res.data && res.data.data.length) {
                  if (!this.stopMethod) {
                    this.$message({
                      type: "success",
                      message: "识别成功!",
                      duration: 1000,
                    });
                  }
                  const imgData = new FormData();
                  imgData.append("url", res.data.imgAiUrl);
                  imgData.append(
                    "addtime",
                    dayjs().format("YYYY-MM-DD HH:mm:ss")
                  );
                  imgData.append("hardId", this.$uav.hardId);
                  imgData.append("name", this.$uav.name);
                  imgData.append("longitude", longitude);
                  imgData.append("latitude", latitude);
                  imgData.append("imageType", 2);
                  imgData.append("remark", JSON.stringify(res));
                  // imgData.append("aiType", 2);
                  imgData.append("sortie", this.flightSortie);
                  const data = new FormData();
                  data.append(
                    "file",
                    new File([blob], "test.png", { type: "image/png" })
                  );
                  data.append("bucketName", "tmj-icon");
                  const res2 = await Tg.uploadFile(data);
                  imgData.append("primitiveImage", res2.data);
                  const res1 = await Control_API.addPhoto(imgData);
                  if (res1.code === 200) {
                    this.$refs.viewLibrary &&
                      this.$refs.viewLibrary.changeActive(2);
                    this.$bus.emit("updateAIResult");
                  }
                } else {
                  this.$message({
                    type: "error",
                    message: res.msg || "出错了",
                    duration: 1000,
                  });
                }
              }
            },
            "image/png",
            1
          );
        } else if (type === "photo") {
          canvas.toBlob(
            async (blob) => {
              if (blob) {
                const data = new FormData();
                data.append("addtime", dayjs().format("YYYY-MM-DD HH:mm:ss"));
                data.append("file", blob);
                data.append("hardId", this.$uav.hardId);
                data.append("name", this.$uav.name);
                data.append("imageType", 1);
                data.append("longitude", longitude);
                data.append("latitude", latitude);
                data.append("sortie", this.flightSortie || "");
                const res = await Control_API.addPhoto(data);
                if (res.code === 200) {
                  if (!this.stopMethod) {
                    this.$message.success("截屏成功");
                    this.$refs.viewLibrary &&
                      this.$refs.viewLibrary.changeActive(1);
                  }
                } else {
                  if (!this.stopMethod) {
                    this.$message({
                      type: "error",
                      message: res.msg || "出错了",
                      duration: 1000,
                    });
                  }
                }
              }
            },
            "image/png",
            1
          );
        }
      } catch (error) {
        console.log(error);
      }
      // return canvas.toDataURL('image/png');
    },
    async photograph() {
      const res = await Tg.uavLastData({
        hardId: this.$uav.hardId,
        appId: 87878,
      });
      if (res.data != "DATA_NOT_EXIST") {
        this.flightSortie = res.data.deviceData.data.uavInfo.flightSortie;
      }

      setTimeout(() => {
        this.screenshot();
      }, 500);
    },
    screenRecording() {
      this.$store.dispatch("uavApplications/videoTranscribeStatus", {
        callback: (isOk) => {
          isOk && this.$message.success("查询录制状态");
        },
      });

      this.$store.dispatch("uavApplications/videoTranscribe", {
        videoID: 1, // 视频通道ID（需保持唯一）
        dbID: "929", // 历史记录id
        name: "z30Pro", // 挂载名称（如果知道挂载名称，将传名称即可，通道号可以不用传）
        taskID: "", // 任务ID，可传可不传
        recordControl: true, // 录制开关、true为开启
        status: this.screenRecordingStatus,
        callback: (isOk) => {
          isOk &&
            this.$message.success(
              `${this.screenRecordingStatus ? "开始" : "结束"}录屏`
            );
          this.screenRecordingStatus = !this.screenRecordingStatus;
        },
      });
    },
    async screenshot() {
      const res = await Tg.uavLastData({
        hardId: this.$uav.hardId,
        appId: 87878,
      });
      if (res.data != "DATA_NOT_EXIST") {
        this.flightSortie = res.data.deviceData.data.uavInfo.flightSortie;
      }
      const video = document.getElementById("rtc_media_player");
      const video1 = document.getElementsByClassName("vjs-tech")[0];
      const video2 =
        document.getElementsByClassName("qingliu").length &&
        document.getElementsByClassName("qingliu")[0].childNodes[0]
          .childNodes[0];
      if (video || video1 || video2) {
        try {
          setTimeout(() => {
            this.captureVideo(video || video1 || video2, "photo");
          }, 500);
        } catch (error) {
          console.log(error);
          this.$message.error("截屏失败");
        }
      }
    },
    async aiPhoto() {
      if (this.active_list) return (this.active_list = null);
      try {
        const res = await AI.getAiPhotoList();
        this.active_list = res.data;
        // 演示专用 无ai效果
        this.active_list.push({
          detectortype:111,
          icon_url:require("@/components/control-bar/assets/裸土识别_ON.svg"),
          name:"裸土识别",
          status:1
        })
        this.active_list.push({
          detectortype:112,
          icon_url:require("@/components/control-bar/assets/头盔识别_ON.svg"),
          name:"头盔识别",
          status:1
        })
      } catch (error) {
        this.$message.error(error.msg || error);
      }
    },
    async active_ailist_item(item, index, $event) {
      const res = await Tg.uavLastData({
        hardId: this.$uav.hardId,
        appId: 87878,
      });
      if (res.data != "DATA_NOT_EXIST") {
        this.flightSortie = res.data.deviceData.data.uavInfo.flightSortie;
      }
      this.currentPhotiIndex = index;
      const video = document.getElementById("rtc_media_player");
      const video1 = document.getElementsByClassName("vjs-tech")[0];
      const video2 =
        document.getElementsByClassName("qingliu").length &&
        document.getElementsByClassName("qingliu")[0].childNodes[0]
          .childNodes[0];
      if (video || video1 || video2) {
        try {
          setTimeout(() => {
            this.captureVideo(video || video1 || video2, "aiPhoto", item);
          }, 500);
        } catch (error) {
          console.log(error);
          this.$message.error("截屏失败");
        }
      }
    },
    // onClose() {
    //   alert("关闭");
    // },
    changeFlowType(e) {
      this.flowType = e;
    },
    async wxLogin() {
      // let href = window.location.href
      // let data1 = href.split('?')[1].split('#')[0].split('&')
      // console.log(data1,'456456')
      // let data = {}
      // data1.forEach((item,index)=>{
      //   if(index == 0){
      //     data.code = item.split('=')[1]
      //   }
      //   if(index == 1){
      //     data.state = item.split('=')[1]
      //   }
      // })
      // console.log(data,'454132212')
      // // if (data.code && data.state) {
      //   // code=051JMO0w3CViWZ2fcm0w3P4wol2JMO0o&state=onlineEdu#/home
      //   await LoginAPI.wxToken(data).then((res) => {
      //     console.log(res,'进来了')
      //     window.localStorage.setItem(
      //       'user_info',
      //       JSON.stringify(res.data)
      //     )
      //     window.localStorage.setItem('tmj_token', res.data.token)
      //     this.SET_USERINFO(res.data) // 用户信息
      //     this.$message({
      //       type: 'success',
      //       message: '登录成功',
      //       duration: 1000,
      //     })
      //     this.$router.path('/home')
      //   })
      // // }
    },

    // 传送最新pointsInfo
    get_newest_pointsinfo(e) {
      this.newest_pointsinfo = e;
    },
    set_controlShow(e) {
      this.controlShow.isShow = e;
    },
    // 传送实体类
    send_data_srouce(e) {
      this.data_srouce_from_add = e;
    },
    // 传送位置
    send_positions(e) {
      this.positions_from_add = e;
    },

    AiChange(aiArr, index) {
      this.aiInfo = aiArr;
      // console.log('this.aiInfo:', this.aiInfo);
      // if (this.c_player.isShow) {
      this.showAIVideo = true;
      this.currentIndex = index;
      // }
    },
    // 拖拽后的航点
    edit_point_info(e) {
      this.points_info = e;
    },
    // 删除地图规划的航线
    destroy_cesium() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.waypointPopup.destroy_cesium_handler();
      });
    },
    // 删除编辑航线
    clear_airline() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.flightPopup.clear_airline_entitys();
      });
    },
    // 取消点击事件
    cancel_Handler() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.waypointPopup.cancel_Handler();
      });
    },

    // 航线规划
    save_airline() {
      const that = this;
      this.$nextTick(() => {
        that.$refs.waypointPopup.save();
      });
    },

    // AI列表
    active_ailist() {
      this.is_active_ailist = !this.is_active_ailist;
    },
    isShowList() {},
    enter() {
      this.see = true;
    },
    leave() {
      this.see = false;
    },
    pointInfo(e) {
      this.point_info = e;
    },
    get_flight_info(e) {
      this.flight_info = e;
    },
    get_route_info(e) {
      this.route_info = e;
    },
    get_airline_info(e) {
      this.airline = e;
    },
    send_airline_info(e) {
      this.airline = e;
    },
    send_to_tooptip(e) {
      this.tooptip_flight_info = e;
    },
    get_flight_state(e) {
      this.isFromEdit = e;
    },
    get_sidelist_item(e) {
      this.side_list_item = e;
    },
    async get_son_token() {
      // ws获取token代码--lgq
      if (window.localStorage.getItem("tmj_token")) {
        const token = window.localStorage.getItem("tmj_token");

        const data = {
          loginInfo: {
            appId: "40004",
            token: token,
          },
        };
        const control_login_info = await Control_API.login(data);
        if (control_login_info.data) {
          this.SET_UAVLOGIN_INFO({
            tmj_FLYINGSESSIONID: control_login_info.data["flyingSessionId"],
            "tmj_mmc-identity": control_login_info.data["token"],
          });
          window.localStorage.setItem(
            "ws_flyingSessionId",
            control_login_info.data["flyingSessionId"]
          );
          window.localStorage.setItem(
            "ws_token",
            control_login_info.data["token"]
          );
        }
      }
    },

    // change_active(e, index) {
    //   let tmj_token = window.localStorage.getItem("tmj_token");
    //   let dianli_token = window.localStorage.getItem("dianli_token");
    //   let shiyou_token = window.localStorage.getItem("shiyou_token");

    //   let space_token = window.localStorage.getItem("space_token");
    //   this.active = e;
    //   this.airline = e
    // },
    send_to_line_tooptip(e) {
      this.tooptip_line_info = e;
    },
    send_to_task_tooptip(e) {
      this.tooptip_flight_info = e;
    },
    // async change_active(e, index) {
    //   let tmj_token = window.localStorage.getItem("tmj_token");
    //   this.active = e;
    async change_active(active, index) {
      this.active = active;

      // switch (index) {
      //   case 0:
      //     this.$store.commit('CHANGE_ACTYIVE', 0)
      //     break
      //   case 1:
      //     this.$store.commit('CHANGE_ACTYIVE', 1)
      //     break
      //   case 2:
      //     this.$store.commit('CHANGE_ACTYIVE', 2)
      //     break
      // }
      if (index) {
        this.$store.commit("CHANGE_ACTYIVE", index);
      } else if (index === 0) {
        this.$store.commit("CHANGE_ACTYIVE", 0);
      }
    },
    // async getTrackData() {
    //   let data = await AirLine.details(this.flight_info.routeName);
    //   if (data.code == 200) {
    //     // this.trackPoints = data.points;
    //     this.flight_info.trackPoints = data.points;
    //   }
    // },
    fiflterPoint(uav_data) {
      this.flight_info.trackPoints.map((item) => ({
        latitude: item.latitude,
        longitude: item.longitude,
        isPass: !!(
          item.latitude === uav_data.latitude &&
          item.longitude === uav_data.longitude
        ),
      }));
    },
    // header的列表更改事件
    onHeaderChangeList(value) {
      this.active = 0;
      this.downoadPageShow = value === "download";
    },
  },
};
</script>

<style lang="scss" scoped>
.views-home {
  height: 100%;
}
.ai_player {
  // position: relative;
  height: 100%;
}
.MMCPlayer {
  position: fixed;
  top: 0;
}
.kedu {
  z-index: 1001;
  position: absolute;
  top: calc(50% - 25%);
  left: calc(50% - 25%);
  width: 50%;
  height: 50%;
  // background: yellow;
  background: url("~@/assets/images/mount/line.png");
  background-size: 100% 100%;
  transition: 0.5s;

  // overflow-y: hidden;
  .num {
    position: absolute;
    left: -30px;
    z-index: 1002;
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .line {
    // transform: translateY(-250px);
    position: absolute;
    bottom: calc(50%);
    width: 100%;
    height: 100%;
  }
  .longLine {
    position: absolute;
    // top: calc(50% - 25px);
    // left: calc(50% - 25px);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .text {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% + 25px);
    width: 75px;
    height: 25px;
    background: url("~@/assets/images/mount/textbg.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 25px;
    color: red;
    font-weight: bold;
  }
  .center {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
  }
}
.left-bottom-btn {
  position: absolute;
  left: 56px;
  bottom: 7px;
  z-index: 2;
  .btn-item-box {
    position: relative;
    .poition-box {
      position: absolute;
      bottom: -120px;
      right: 50%;
      transform: translateX(50%);
      width: 208px;
      height: 108px;
      background-color: #000000;
    }
  }
  .icon-item {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.video-control {
  position: absolute;
  right: 10px;
  top: 17%;
  z-index: 1;
  .item-box {
    cursor: pointer;
    width: 38px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    // background-color: #fff;
    margin-bottom: 10px;
    &.photograph {
      background: url("~@/assets/images/mount/photograph.png") no-repeat;
      background-size: 100% 100%;
    }
    &.pictureRecording {
      background: url("~@/assets/images/mount/pictureRecording.png") no-repeat;
      background-size: 100% 100%;
    }
    &.pictureRecordingStop {
      background: url("~@/assets/images/mount/停止录屏.png") no-repeat;
      background-size: 100% 100%;
    }
    &.screenshot {
      background: url("~@/assets/images/mount/screenshot.png") no-repeat;
      background-size: 100% 100%;
    }
    &.aiPhoto {
      position: relative;
      background-color: transparent;
      .aiPhoto-img {
        width: 100%;
        height: 100%;
        background: url("~@/assets/images/mount/aiPhoto.png") no-repeat;
        background-size: 100% 100%;
      }
      .ailist-wrap {
        position: absolute;
        left: 65px;
        top: 0;
        z-index: 1;
        /* padding-bottom: 30px; */
        background: rgba(19, 21, 24, 0.7);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
          inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
          inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
        border: 1px solid #3bc1e5;
        border-radius: 4px;
        .ailist-wrap-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .ailist-wrap-head-btn {
            flex: 1;
            background-color: #323844;
            border-bottom: 1px solid #000;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #ebf5ff;
            .ai {
              border-right: 1px solid #000;
            }
            &.active {
              background: #1890ff;
            }
          }
        }
        .ailist {
          display: flex;
          overflow-y: scroll;
          align-items: center;

          // justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          width: 208px;
          padding-bottom: 5px;
          box-sizing: border-box;

          .img_item {
            // background: rebeccapurple;
            // height: 66px;
            margin-left: 12px;
            margin-bottom: 6px;
            // padding-top: 10px;
            display: flex;
            width: 48px;
            height: 60px;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;

            font-family: MicrosoftYaHei;

            letter-spacing: 0;
            text-align: center;
            position: relative;
            padding-top: 10px;

            .img_item_text {
              color: #b1bbc6;
              display: flex;
              text-align: center;
              font-size: 14px !important;
              width: 48px;
              height: 8px;
              position: absolute;
              top: -9px;

              .item_text_img {
                width: 48px;
                word-break: keep-all;
                white-space: nowrap;
                margin-top: 5px;
                // height: 8px;
              }
            }
            .images_item {
              width: 48px;
              height: 48px;
              // background: rebeccapurple;
              background: url("../../assets/ai/AI.png");
              cursor: pointer;
              // background: url('../../assets/ai/AI_Selected.png');
            }
            .images_item:hover {
              background: url("../../assets/ai/AI_hover.png");
              cursor: pointer;
            }
          }
          .selected {
            width: 48px;
            height: 48px;
            background: url("../../assets/ai/AI_Selected.png");
          }
          .selected_text {
            color: #50efff;
          }
        }
        .supervised-arm {
          width: 208px;
          .supervised-arm-body {
            padding: 6px;
            ::v-deep {
              .el-form-item__label {
                font-size: 12px;
                font-family: MicrosoftYaHei;
                color: #b3bbc5;
              }
              .el-radio {
                margin-right: 5px;
              }
              .el-radio__label {
                padding-left: 5px;
                color: #fff;
              }
              .el-radio__input.is-checked + .el-radio__label {
                color: #409eff;
              }
              .el-select__tags {
                top: 0;
                transform: translateY(0);
              }
            }
            .arm-body-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #fff;
              .arm-body-item-left {
                width: 60px;
                font-size: 12px;
              }
              .arm-body-item-right {
                flex: 1;
                .arm-input {
                  width: 122px;
                  /* height: 32px; */
                  background: #16181b;
                  border-radius: 8px;
                  border: 1px solid #233a58;
                  line-height: 32px;
                  font-size: 14px;
                }
              }
            }
            .arm-body-item-unit {
              width: 80px;
              font-size: 12px;
              font-family: MicrosoftYaHei;
              color: #b3bbc5;
              margin-left: 5px;
            }
          }
          .form_btn {
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 14px;
            color: #f8f8f8;
            letter-spacing: 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            margin-top: 35px;
            .btn_create,
            .btn_cancel {
              box-sizing: border-box;
              width: 92px;
              height: 32px;
              background: #3a4252;
              border: 1px solid #3bc1e5;
              border-radius: 4px;
              line-height: 32px;
              cursor: pointer;

              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
}

::v-deep {
  .videoOfEagleNest {
    /* width: 440px;
    height: 416px; */
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5),
      inset 0px 19px 56px 0px rgba(93, 173, 255, 0.15),
      inset 0px 5px 56px 0px rgba(53, 76, 147, 0.5),
      inset 0px 1px 5px 0px #2e63dc;
    border-radius: 5px;
    border: 1px solid;
    border-image: linear-gradient(
        180deg,
        rgba(52, 223, 255, 1),
        rgba(24, 113, 214, 0)
      )
      1 1;
    backdrop-filter: blur(4.689165186500889px);
    padding: 10px;
    position: fixed;
    left: 25%;
    top: 10%;
    .head {
      height: 32px;
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05),
        inset 0px -1px 0px 0px #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .left {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ebf5ff;
      }
      .right {
        color: #ebf5ff;
      }
    }
    .topBox {
      display: flex;
      .pointer.iconfont.icon-quanping {
        visibility: hidden;
      }
    }

    .size_type {
      display: none;
    }
    .interact-wrap {
      /* height: 21.5vh; */
      transform: none !important;
      position: relative !important;
    }
    div {
      position: unset !important;
    }
    .play {
      position: absolute !important;
    }

    .video-wrapper {
      position: relative !important;
    }

    .el-input__suffix {
      right: 105px;
    }

    .cpt_video {
      width: 99.6%;
      height: 100%;
      position: unset;
      border: none;

      .video {
        display: flex;
        justify-content: space-between;

        .name {
          width: 80%;
          text-align: left;
          text-indent: 1rem;
          white-space: nowrap; /* 保证文本在一行显示 */
          overflow: hidden; /* 隐藏溢出的内容 */
          text-overflow: ellipsis; /* 超出部分显示省略号 */
        }

        .video-wrapper {
          .alt {
            display: none;
          }
        }
      }

      .close {
        display: none;
      }
    }

    .el-select {
      margin-right: 30px;
      margin-top: 10px;
      margin-bottom: 5px;
      background: #3a4252;
      border-radius: 2px;
      border: 1px solid #3bc1e5;
    }
  }
  .MMCPlayerStyle {
    //全屏按钮
    video::-webkit-media-controls-fullscreen-button {
      display: none;
    }
    //播放按钮
    video::-webkit-media-controls-play-button {
      display: none;
    }
    //进度条
    video::-webkit-media-controls-timeline {
      display: none;
    }
    //观看的当前时间
    video::-webkit-media-controls-current-time-display {
      display: none;
    }
    //剩余时间
    video::-webkit-media-controls-time-remaining-display {
      display: none;
    }
    //音量按钮
    video::-webkit-media-controls-mute-button {
      display: none;
    }
    video::-webkit-media-controls-toggle-closed-captions-button {
      display: none;
    }
    //音量的控制条
    video::-webkit-media-controls-volume-slider {
      display: none;
    }
    //所有控件
    video::-webkit-media-controls-enclosure {
      display: none;
    }
  }
}
</style>
