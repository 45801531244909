<!--
 * @Author: kai 17660422924@163.com
 * @Date: 2024-03-02 09:13:35
 * @LastEditors: kai 17660422924@163.com
 * @LastEditTime: 2024-05-16 17:53:38
 * @FilePath: \mmc-vue-tianmu\src\components\eagle-nest\eagle-operate\components\eagle-player\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script>
export default {
  name: "Index",
  props: {
    thrustDataList: {
      type: Array,
      default: () => [],
    },
    tip: {
      type: String,
      default: "",
    },
  },
  methods:{
      sortFunction(a, b)  {
        if (a.label === 'webrtc') return -1;
  if (b.label === 'webrtc') return 1;
  return 0;
}, 
    }
};
</script>

<template>
  <div class="eagle-player">
    <div class="eagle-player-wrap">
      <div class="eagle-player-content">
        <MMCPlayer
          ref="MMCPlayer"
          title=""
          :stream-options="thrustDataList.sort(sortFunction)"
          :obstacle-data="null"
          :size="1"
        />
      </div>
      <div class="player-tip">
        {{ tip }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.eagle-player {
  &-wrap {
    position: relative;
  }
  &-content::v-deep {
    div {
      position: unset !important;
    }
    .interact-wrap {
      transform: none !important;
      position: relative !important;
      margin: 0 !important;
    }
    .cpt_video {
      position: relative !important;
      .video {
        position: absolute !important;
        .name,
        .tr-info-box,
        .close {
          position: absolute !important;
        }
        .close {
          display: none;
        }
      }
      .play {
        position: absolute !important;
        div {
          margin-left: 0px !important;
        }
        .ctrl-items-selects {
          margin-right: 4px !important;
          .el-input {
            position: relative !important;
          }
        }
        .size_type {
          display: none !important;
        }
      }
    }
    .size_01 {
      width: 210px !important;
      height: 121px !important;
    }
  }
  .player-tip {
    width: 32px;
    height: 20px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    color: #d4f8ff;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 99999;
  }
}
</style>
