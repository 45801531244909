<template>
  <div class="cpt-search-box">
    <div class="search_icon" @click="btn">
      <div class="item_icon">
        <span class="icon iconfont">&#xe6ab;</span>
      </div>
    </div>
    <div v-if="status" class="search_box">
      <el-autocomplete
        v-model="destination.name"
        class="inline-input"
        :fetch-suggestions="querySearch"
        placeholder="请输入地点"
        :trigger-on-focus="false"
        clearable
        @select="handleSelect"
      >
        <i slot="prefix" class="icon iconfont">&#xe6ab;</i>
        <template slot-scope="{ item }">
          <div style="margin-left: -8px; margin-top: -1px">
            <span style="font-size: 14px; color: #333">{{ item.name }}</span>
            <span style="font-size: 12px; color: #999; margin-left: 12px">{{ item.address }}</span>
          </div>
        </template>
      </el-autocomplete>
    </div>
  </div>
</template>

<script>
import { Map } from '@/api';
import { Utils } from '@/utils/cesium';

let location_icon;

export default {
  name: 'SearchBox',

  data() {
    return {
      status: false,
      place: '',
      destination: {
        name: '',
        lng: 0,
        lat: 0
      }
    };
  },

  mounted() {},

  methods: {
    btn() {
      this.status = !this.status;
    },
    querySearch(queryString, cb) {
      Map.Geo({ keywords: queryString }).then((res) => {
        const placeList = [];
        res.data.tips.forEach((obj) => {
          if (typeof obj.address === 'string') {
            if (typeof obj.location === 'string') {
              placeList.push(obj);
            }
          }
        });

        // console.log("placeLIst....", placeList);
        // console.log("res.data.tips....", res.data.tips);
        cb(placeList);
        // cb(res.data.pois)
      });
    },
    handleSelect(item) {
      const location = item.location.split(',');
      this.destination.name = item.name;
      this.destination.lng = Number(location[0]);
      this.destination.lat = Number(location[1]);

      const viewer = window.viewer;
      const position = Utils.transformWGS842Cartesian({
        lng: this.destination.lng,
        lat: this.destination.lat,
        alt: 1000
      });
      viewer.camera.flyTo({
        destination: position
      });
      // this.init_location_icon(position);
    },

    init_location_icon(position) {
      const viewer = window.viewer;
      if (location_icon) {
        this.clear_localtion_icon();
        location_icon.position = position;
      } else {
        location_icon = viewer.entities.add({
          position,
          billboard: {
            width: 36,
            height: 36,
            image: require('@/assets/image/icons/icon_location.png'),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.Bottom,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
          }
        });
      }
    },

    clear_localtion_icon() {
      const viewer = window.viewer;
      viewer.entities.pop();
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-search-box {
  width: 18%;
  // height: 10%;
  right: 4px;
  top: 100px;
  position: absolute;

  .iconfont {
    font-size: 24px;
    color: #b3bbc5;
    font-weight: 700;
  }
  .search_icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    height: 48px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: inset 0 1px 4px 0 #2e63dc, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
      inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    border-radius: 4px;
    border: 1px solid #3bc1e5;
    display: flex;
    align-items: center;
    justify-content: center;
    .item_icon {
      width: 32px;
      height: 32px;
      background: #16181b;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 0.8;
      }
    }
    cursor: pointer;
  }
  .search_box {
    position: absolute;
    top: 0;
    right: 70px;
    width: 250px;
    height: 48px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
      inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    border-radius: 4px;
    border: 1px solid #3bc1e5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &::v-deep {
    .el-input__prefix {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-autocomplete {
      width: 88%;
      .el-autocomplete-suggestion {
        li {
          padding: 0 10px;
        }
      }
    }
    .el-input__icon {
      line-height: 32px;
      height: 32px;
    }
    // .el-scrollbar__bar {
    //   .is-horizontal {
    //     background-color: #fff;
    //   }
    // }
  }
}
</style>
