<template>
  <div class="cpt-dashborad-track">
    <div
      v-for="(item, index) in points"
      :key="index"
      class="track_item"
      :class="flight_info.trackPoints.isPass ? 'active' : ''"
    >
      <span class="isGou" />
      <span class="type">RTM</span>
      <span class="info">航点{{ index }}</span>
    </div>
  </div>
</template>

<script>
// let points = [
//   {
//     latitude: 100,
//     longitude: 100,
//   },
//   {
//     latitude: 200,
//     longitude: 200,
//   },
//   {
//     latitude: 300,
//     longitude: 300,
//   },
// ];

export default {
  props: ['data'],
  data() {
    return {
      points: {}
    };
  },
  inject: ['flight_info'],
  mounted() {
    this.points = this.flight_info.points;
  },
  methods: {
    // async getTrackData(uav_data) {
    //   let data = await AirLine.details("xxx");
    //   // console.log("trackData:", data);
    //   if (data.code == 200) {
    //     let points = data.points;
    //     this.points = points.map((item) => ({
    //       latitude: item.latitude,
    //       longitude: item.longitude,
    //       isPass:
    //         item.latitude == uav_data.latitude &&
    //         item.longitude == uav_data.longitude
    //           ? true
    //           : false,
    //     }));
    //     console.log("---------points---------", this.points);
    //   }
    // },
  }
};
</script>

<style lang="scss" scoped>
.cpt-dashborad-track {
  width: 373px;
  height: 175px;
  background: #171a1c;
  border-radius: 2px;
  .track_item {
    // background: #282b33;
    // border: 1px solid #50abff;
    // box-shadow: inset 0 -1px 0 0 #000000;
    height: 29px;
    line-height: 29px;
    .isGou {
      width: 22px;
      display: inline-block;
    }
    .type {
      font-family: PingFangSC-Medium;
      font-size: 12px;
      color: #689eea;
      letter-spacing: 0;
      text-align: center;
      font-weight: 500;
      border-radius: 30%;
      border: #689eea 1px solid;
      margin: 0 5px;
    }
    .info {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #eaf6ff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
.acitve {
  background: red;
}
</style>
