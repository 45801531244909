<!-- 控制栏 -->
<template>
  <div class="cpt-observe-mspace-dashboard-control">
    <div v-if="show_ms == 1" class="fly_ms">
      <div class="right-task">
        <div class="task-info">
          <div class="task-title">无人机：</div>
          <!-- <div class="task-name">深圳公安1#任务</div> -->
          <div class="task-name">
            <!-- {{ flightInfo.taskName || "无任务" }} -->
            <el-select style="width: 100%" placeholder="请选择无人机">
              <!-- <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option> -->
            </el-select>
          </div>
          <div class="task-title">航线：</div>
          <!-- <div class="task-name">深圳公安1#任务</div> -->
          <div class="task-name">
            <!-- {{ flightInfo.routeName || "无航线" }} -->
            <el-select style="width: 100%" placeholder="请选择航线">
              <!-- <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option> -->
            </el-select>
            <div class="name-icon">下发</div>
          </div>
        </div>
        <!-- <div class="task-btns">
          <button
            v-hover
            class="task-btn1"
            :class="flightInfo.taskStatus != 2 ? 'on' : 'off'"
            @click="choose_show = true"
          >
            选择
          </button>
          <button
            v-hover
            class="task-btn2"
            :class="flightInfo.taskStatus == 2 ? 'on' : 'off'"
            @click="endTask"
          >
            确认完成
          </button>
        </div> -->
      </div>
      <div :class="safe_swicth ? 'box_ctn1' : 'box_ctn2'">
        <div class="title-box">
          <div class="title-right">
            <span v-if="!safe_swicth" class="iconfont icon-Lock" />
            <span v-if="safe_swicth" class="iconfont icon-UnLock" />
            <div class="tilte-left">安全开关</div>
          </div>

          <el-switch
            v-model="safe_swicth"
            active-color="#13ce66"
            inactive-color="#939da6"
            :disabled="flightInfo.taskStatus == 1 || flightInfo.taskStatus == 2 ? false : true"
          />
        </div>
        <div class="control-box" :class="!safe_swicth ? 'gray' : ''">
          <!-- 一期样式 -->
          <!-- <div class="control-box gray"> -->
          <!-- <div class="box-row">
            <div class="box-item"
                 v-show="isFly"
                 @click="isTipShow = true"
                 v-hover>
              一键起飞
            </div>
            <div class="box-item"
                 v-show="!isFly"
                 @click="
                fn(frist_btn ? '16' : '17', frist_btn ? '暂停' : '执行');
                frist_btn = !frist_btn;
              "
                 v-hover>
              执行/暂停
            </div>
            <div class="box-item"
                 @click="fn(13, '航线模式')"
                 v-hover>
              航线模式
            </div>
          </div>
          <div class="box-row">
            <div class="box-item"
                 @click="fn(11, '安全降落')"
                 v-hover>
              安全降落
            </div>
            <div class="box-item"
                 @click="fn(12, '一键返航')"
                 v-hover>
              一键返航
            </div>
          </div> -->
          <!-- 二期样式 -->
          <div v-show="isFly" v-hover class="box-btn" @click="isTipShow = true">
            <span class="icon iconfont">&#xe6c8;</span>
            <span class="context">一键起飞</span>
          </div>
          <div v-show="!isFly" v-hover class="box-btn" @click="pointingFlight">
            <span class="icon iconfont">&#xe6ca;</span>
            <span class="context">指点飞行</span>
          </div>
          <div
            v-show="!isFly"
            v-hover
            class="box-btn"
            @click="
              fn(frist_btn ? '16' : '17', frist_btn ? '暂停' : '执行');
              frist_btn = !frist_btn;
            "
          >
            <span v-if="!frist_btn" class="icon iconfont">&#xe6c8;</span>
            <span v-else class="icon iconfont">&#xe6c7;</span>
            <span class="context">{{ frist_btn ? '暂停' : '执行' }}</span>
          </div>
          <div v-show="!isFly" v-hover class="box-btn" @click="fn(12, '一键返航')">
            <span class="icon iconfont">&#xe6c9;</span>
            <span class="context">一键返航</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show_ms == 2" class="cloud_ms">
      <!-- <div class="control_nav">
        <div
          class="nav_item"
          :class="nav_active == 1 ? 'nav_item_active ' : ''"
          @click="nav_active = 1"
        >
          <span class="iconfont icon-youxiayuntai" />
          云台
        </div> -->
      <!-- <div
          class="nav_item"
          :class="nav_active == 2 ? 'nav_item_active' : ''"
          @click="nav_active = 2"
        >
          <span class="iconfont icon-youxiayuntai"></span>
          轨迹
        </div> -->
      <!-- <div
          class="nav_item"
          :class="
            nav_active == 3
              ? 'nav_item_active animate__animated animate__fadeIn'
              : ''
          "
          @click="nav_active = 3"
        >
          <span class="iconfont icon-youxiatupian" />
          图片
        </div> -->
      <!-- <div
          class="nav_item"
          :class="nav_active == 4 ? 'nav_item_active' : ''"
          @click="nav_active = 4"
          :data="flightInfo"
        >
          <span class="iconfont icon-youxiayuntai"></span>
          视频
        </div> -->
      <!-- <div
          class="nav_item"
          :class="
            nav_active == 5
              ? 'nav_item_active animate__animated animate__fadeIn'
              : ''
          "
          @click="nav_active = 5"
        >
          <span class="iconfont icon-youxiarizhi" />
          日志
        </div>
        <div
          v-pms="['无人机操作', '成果']"
          class="nav_item"
          @click="daochubaogao"
        >
          <span class="iconfont icon-youxiadaochu" />
          成果
        </div>
      </div> -->
      <CloudBar v-if="nav_active == 1" :data="flightInfo" @fn="(e) => $emit('fn', e)" />
      <Track v-if="nav_active == 2" :data="nowData" class="animate__animated animate__fadeIn" />
      <Imagecpt
        v-if="nav_active == 3"
        class="animate__animated animate__fadeIn"
        :data="flightInfo"
      />
      <Videocpt v-if="nav_active == 4" class="animate__animated animate__fadeIn" />
      <LogCpt v-if="nav_active == 5" class="animate__animated animate__fadeIn" />
    </div>
    <div v-if="show_ms === 3" class="aerialOperation">
      <div v-for="item in aerialOperationList" :key="item.id" class="aerialOperation-item">
        <div class="itme-box">
          <img :src="item.img" alt="" />
          <div>{{ item.label }}</div>
        </div>
      </div>
    </div>
    <!-- <ChoosePopup v-if="choose_show"
                 @fn="choose_show = false" /> -->
    <SelectTask v-if="choose_show" @fn="choose_show = false" />
    <TipPopup v-if="isTipShow" @confirmTip="confirm" @closeTip="close" />
  </div>
</template>

<script>
import CloudBar from '../station-control';
import Track from './track';
import Imagecpt from './imagecpt';
import LogCpt from './logcpt';
import Videocpt from './videocpt';
import ChoosePopup from './popup/choose.vue';
import TipPopup from './popup/tip.vue';
import SelectTask from './select-task';
import { flightTaskAPI } from '@/api';
import { mapMutations } from 'vuex';
import dayjs from 'dayjs';

import Qs from 'qs';

export default {
  components: {
    CloudBar,
    Track,
    Imagecpt,
    LogCpt,
    Videocpt,
    ChoosePopup,
    SelectTask,
    TipPopup
  },
  props: ['show_ms', 'flightInfo', 'nowData'],
  data() {
    return {
      value: false,
      nav_active: 1,
      choose_show: false,
      safe_swicth: false,
      frist_btn: true, // 用于判断是暂停or执行
      isFly: true, // 用于判断是否已经使用一键起飞的按钮
      isTipShow: false,
      aerialOperationList: [
        {
          id: 1,
          img: require('../../../../assets/image/icons/1.png'),
          label: '定位'
        },
        {
          id: 2,
          img: require('../../../../assets/image/icons/1.png'),
          label: '轨迹'
        },
        {
          id: 3,
          img: require('../../../../assets/image/icons/1.png'),
          label: '手动'
        },
        {
          id: 4,
          img: require('../../../../assets/image/icons/1.png'),
          label: '暂停飞行'
        },
        {
          id: 5,
          img: require('../../../../assets/image/icons/1.png'),
          label: '结束飞行'
        },
        {
          id: 6,
          img: require('../../../../assets/image/icons/1.png'),
          label: '指点飞行'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    ...mapMutations(['SET_LOGS']),
    // 指点飞行
    pointingFlight() {
      this.fn(14, '指点飞行', 123, 321);
    },

    // 自适应挂载
    handle_change_ptz_mode(value) {
      const buffer = MMC_Gimbal_Z40.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_zoom(value) {
      let buffer = MMC_Gimbal_Z40.zoom(this.zoom + value);
      this.commit_directive(buffer);
      buffer = MMC_Gimbal_Z40.zoom((this.zoom = 1));
      this.commit_directive(buffer);
    },
    handle_change_pitch() {
      const buffer = MMC_Gimbal_Z40.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      const buffer = MMC_Gimbal_Z40.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    handle_change_camera_mode(value) {
      const buffer = MMC_Gimbal_Z40.camera_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_take_photo() {
      const { position } = this;
      const buffer = MMC_Gimbal_Z40.take_photo(position || {});
      this.commit_directive(buffer);
    },
    handle_record() {
      this.record = !this.record;
      const buffer = MMC_Gimbal_Z40.record(this.record);
      this.commit_directive(buffer);
    },

    async clickFly() {
      this.isFly = false;
      const data = await flightTaskAPI.taskStart({
        id: this.flightInfo.id
      });
      if (data.code == 200) {
        this.$bus.$emit('refreshList');
        this.flightInfo.taskStatus = 2;
        this.fn(10, '一键起飞');
        this.isTipShow = false;
        this.$message.success('一键起飞成功！');
      } else {
        this.$message.error(data.message || '一键起飞失败！');
        this.isTipShow = false;
      }
    },
    confirm() {
      this.clickFly();
    },
    close() {
      this.isTipShow = false;
    },
    fn(e, data) {
      if (this.flightInfo.deviceStatus) {
        const time = dayjs().format('YYYY/MM/DD HH:mm:ss');
        this.SET_LOGS({ time, log: data });
        this.$emit('fn', e);
      }
    },
    async endTask() {
      const { id } = this.flightInfo;
      const data = await flightTaskAPI.taskEnd({
        id
      });
      if (data.code == 200) {
        this.$message({
          type: 'success',
          message: '任务已完成',
          duration: 1000
        });
        this.$bus.$emit('refreshList');
        this.flightInfo.taskStatus = 3;
        const time = dayjs().format('YYYY/MM/DD HH:mm:ss');
        this.SET_LOGS({ time, log: '确认完成' });
        this.isFly = true;
        this.safe_swicth = false;
      } else {
        this.$message({
          type: 'error',
          message: data.msg,
          duration: 1000
        });
      }
    },
    async daochubaogao() {
      const { id } = this.flightInfo;

      const baogao = await flightTaskAPI
        .getExportToWord({
          taskId: id
        })
        .then((res) => {
          const content = res;

          const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'
          });
          const fileName = '任务概览列表';
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .catch((err) => {});
    }
  },
  watch: {
    flightInfo(val) {
      this.safe_swicth = false;
      this.isFly = false;
      if (val.taskStatus == 2) {
        this.isFly = false;
      } else {
        this.isFly = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.cpt-observe-mspace-dashboard-control {
  // height: 128px;
  width: 377px;
  border-radius: 2px;
  // padding: 0 12px;
  // box-sizing: border-box;
  background-color: #080c0b;
  padding: 10px;
  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // line-height: 36px;
    height: 36px;
    background: #090b0c;
    padding: 0 12px;
    box-sizing: border-box;
    // opacity: 0.4;
    .title-right {
      display: flex;
      color: #f8f8f8;
      .iconfont {
        font-size: 20px;
        font-family: MicrosoftYaHei;
        letter-spacing: 0;
        text-align: center;
        align-items: center;
        font-weight: 400;
      }
    }
  }
  .control-box {
    height: 92px;
    // padding: 0 63px;
    /* box-sizing: border-box; */
    background: #171c29;
    // margin-top: -5px;
    // padding-top: 5px;
    padding-top: 1px;
    display: flex;
    justify-content: center;
    .box-row {
      display: flex;
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      .box-item {
        background: #3a4252;
        border: 1px solid #3bc1e5;
        border-radius: 4px;
        height: 32px;
        width: 92px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #f8f8f8;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        text-align: center;
        line-height: 32px;
        // margin-left: ;
      }
    }
    .box-btn {
      border: 1px solid transparent;
      border-radius: 4px;
      width: 72px;
      height: 72px;
      display: flex;
      // flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      margin: 10px 10px 0px 10px;
      .context {
        color: #f8f8f8;
        font-size: 14px;
        margin-top: 4px;
      }
      .iconfont {
        color: #3bc1e5;
        font-size: 34px;
      }
      &:hover {
        border: 1px solid #3bc1e5;
      }
    }
  }
  .aerialOperation {
    background-color: #171c29;
    color: #b5bdc7;
    width: 100%;
    height: 225px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .aerialOperation-item {
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      .itme-box {
        height: 80px;
        width: 80px;
        border: 1px solid transparent;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        &:hover {
          border: 1px solid #3bc1e5;
        }
      }
    }
  }
}
.cloud_ms {
  position: relative;
  margin-top: 10px;
  height: 215px;
  .control_nav {
    width: 373px;
    height: 24px;
    background: #3bc1e5;
    position: absolute;
    top: -79px;
    left: -13px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #394963, #394963),
      linear-gradient(90deg, #34dfff, #1871d6);
    :hover {
      color: #ffffff !important;
    }
    .nav_item {
      flex: 1;
      text-align: center;
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      color: #b8b0b0;
      letter-spacing: 0;
      text-align: center;
      cursor: pointer;
      height: 24px;
      line-height: 24px;
    }
    .nav_item_active {
      background: #2a59c7;
      color: #ffffff !important;
      font-weight: 700;
    }
  }
}

// .box_ctn1 {
//   border: 1px solid transparent;
//   border-radius: 4px;
//   background-clip: padding-box, border-box;
//   background-origin: padding-box, border-box;
//   background-image: linear-gradient(to top, #394963, #394963),
//     linear-gradient(90deg, #34dfff, #1871d6);
// }
.box_ctn1 {
  border: solid 1px transparent;
  border-radius: 2px;
  background: linear-gradient(to top, #394963, #394963), linear-gradient(#34dfff, #1871d6);
  // box-sizing: border-box;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.box_ctn2 {
  border: 2px solid #242628;
  border-radius: 3px;
}

.right-task {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  .task-info {
    font-family: MicrosoftYaHei;
    color: #b3bbc5;
    letter-spacing: 0;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .task-title {
      width: 18%;
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      &:first-child {
        margin-bottom: 10px;
      }
    }
    .task-name {
      display: flex;
      width: 82%;
      font-size: 12px;
      .name-icon {
        cursor: pointer;
        width: 50px;
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
    }
  }
  .task-btns {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    .task-btn1 {
      width: 52px;
      height: 32px;
      background: #3a4252;
    }
    .task-btn2 {
      width: 76px;
      height: 32px;
      background: #3a4252;
    }
    .on {
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #f8f8f8;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
    }
    .off {
      background: #3a4252;
      border: 1px solid #979797;
      border-radius: 4px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #f8f8f8;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      opacity: 0.5;
      pointer-events: none;
      // cursor: not-allowed;
      filter: gray;
    }
  }
}
.gray {
  opacity: 0.5;
  pointer-events: none;
  filter: gray;
}

/*从左到右进入*/
.fadeInLeft {
  animation-name: fadeInLeft;
  -webkit-animation: fadeInLeft;
}

/*从左到右进入*/
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate(-1000px, 0);
    transform: stranslate(-1000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(10px, 0);
    transform: stranslate(10px, 0);
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate(-1000px, 0);
    transform: stranslate(-1000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(10px, 0);
    transform: stranslate(10px, 0);
  }
}
::v-deep {
  .el-select > .el-input {
    border: 1px solid;
    border-radius: 5px;
  }
}
</style>
