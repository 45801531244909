<template>
  <div class="cpt-app-station-control">
    <el-radio-group v-model="radioMs" @change="changeMs">
      <el-radio :label="1">指令</el-radio>
      <el-radio :label="2">自适应</el-radio>
    </el-radio-group>
    <div class="bd">
      <div class="content">
        <div class="move-control-box">
          <div class="control-box">
            <div class="control-inner-box">
              <div class="top">
                <div class="iconfont icon-shang" @click="fn(1, '向上')" />
              </div>
              <div class="middle">
                <div class="moddile-l-box">
                  <div class="iconfont icon-zuo" @click="fn(2, '向左')" />
                </div>
                <div class="moddile-r-box">
                  <div class="iconfont icon-you" @click="fn(3, '向右')" />
                </div>
              </div>
              <div class="bottom">
                <div class="iconfont icon-xia" @click="fn(4, '向下')" />
              </div>
            </div>
          </div>
        </div>
        <div class="camera-control-box">
          <div class="top">
            <div class="fn-box">
              <div class="fn" @click="fn(5, '放大')">
                <div class="icon-box">
                  <div class="iconfont icon-fangda" />
                </div>
                <div class="text">放大</div>
              </div>
              <div class="fn" @click="fn(6, '缩小')">
                <div class="icon-box">
                  <div class="iconfont icon-suoxiao" />
                </div>
                <div class="text">缩小</div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="fn-box">
              <div
                class="fn"
                @click="
                  fn(7, '拍照');
                  $bus.$emit('takePhotos', flight_info);
                "
              >
                <div class="icon-box">
                  <div class="iconfont icon-paizhao" />
                </div>
                <div class="text">拍照</div>
              </div>
              <div class="fn">
                <div class="icon-box" @click="fn(8, '录像')">
                  <div class="iconfont icon-luying" />
                </div>
                <div class="text">录像</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="foot" style="display: flex; justify-content: space-between !important">
      <!-- <div class="fn">锁定</div>
        <div class="fn">控制</div>
        <div class="fn">回中</div> -->
      <el-radio-group v-model="radio" @change="change">
        <el-radio :label="18">锁定</el-radio>
        <el-radio :label="19">控制</el-radio>
        <el-radio :label="20">回中</el-radio>
      </el-radio-group>
    </div>
    <!-- <audio id="take_photo_audio" muted src="./audio/takephoto.mp3"></audio>
        <audio id="record_audio" muted src="./audio/record.mp3"></audio> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import dayjs from 'dayjs';

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      radio: 19,
      radioMs: 1
    };
  },
  inject: ['control_show', 'flight_info'],
  methods: {
    ...mapMutations(['SET_LOGS']),
    changeMs(e) {
      this.control_show.isShow = e;
      this.$bus.$emit('changeBG');
      this.radioMs = 1;
    },
    change(e) {
      if (this.data.deviceStatus) {
        const time = dayjs().format('YYYY/MM/DD HH:mm:ss');
        if (e == 18) {
          this.SET_LOGS({ time, log: '锁定' });
        } else if (e == 19) {
          this.SET_LOGS({ time, log: '控制' });
        } else if (e == 20) {
          this.SET_LOGS({ time, log: '回中' });
        }
        this.$emit('fn', e);
      }
    },
    fn(e, data) {
      if (this.data.deviceStatus) {
        const time = dayjs().format('YYYY/MM/DD HH:mm:ss');
        this.SET_LOGS({ time, log: data });
        this.$emit('fn', e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-app-station-control {
  box-sizing: border-box;
  .hd {
    height: 48px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .title {
      font-family: Microsoft YaHei;
      font-size: 18px;
      color: #69ceff;
      letter-spacing: 1.12px;
      text-align: center;
      font-weight: 700;
    }
    .sub-title {
      font-family: Microsoft YaHei;
      font-size: 16px;
      color: #69ceff;
      letter-spacing: 1px;
      font-weight: 400;
    }
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(
        270deg,
        rgba(0, 117, 221, 0) 0%,
        #23b8ff 50%,
        rgba(0, 117, 221, 0) 100%
      );
    }
  }
  .bd {
    height: calc(100% - 48px);
    box-sizing: border-box;
    padding: 10px 20px;
    .content {
      // height: calc(100% - 26px);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .move-control-box {
        // flex: 1;
        display: flex;
        flex-direction: column;
        .title {
          font-family: MicrosoftYaHei;
          font-size: 16px;
          color: #69ceff;
          letter-spacing: 1px;
          font-weight: 400;
        }
        .control-box {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          .control-inner-box {
            height: 120px;
            width: 120px;
            display: flex;
            flex-direction: column;
            .iconfont {
              color: #b3bbc5;
              font-size: 32px;
              cursor: pointer;
            }
            .top {
              display: flex;
              align-items: center;
              flex-direction: column;
              flex: 1;
              justify-content: flex-start;
              position: relative;
              &::before {
                display: block;
                content: '上';
                font-family: Microsoft YaHei;
                font-size: 14px;
                color: #b3bbc5;
                letter-spacing: 0;
                font-weight: 400;
                position: absolute;
                bottom: -0.8em;
              }
            }
            .middle {
              display: flex;
              justify-content: space-between;
              flex: 1;
              .moddile-l-box {
                position: relative;
                &::before {
                  display: block;
                  content: '左';
                  font-family: Microsoft YaHei;
                  font-size: 14px;
                  color: #b3bbc5;
                  letter-spacing: 0;
                  font-weight: 400;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: -1em;
                }
              }
              .moddile-r-box {
                position: relative;
                &::before {
                  display: block;
                  content: '右';
                  font-family: Microsoft YaHei;
                  font-size: 14px;
                  color: #b3bbc5;
                  letter-spacing: 0;
                  font-weight: 400;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: -1em;
                }
              }
            }
            .bottom {
              display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: flex-end;
              flex: 1;
              position: relative;
              &::before {
                display: block;
                content: '下';
                font-family: Microsoft YaHei;
                font-size: 14px;
                color: #b3bbc5;
                letter-spacing: 0;
                font-weight: 400;
                position: absolute;
                top: -0.8em;
              }
            }
          }
        }
      }
      .camera-control-box {
        // flex: 1;
        // height: calc(100% + 20px);
        flex-direction: column;
        justify-content: space-between;
        .iconfont {
          font-size: 32px;
          color: #b3bbc5;
        }
        .top,
        .bottom {
          height: 50%;
          flex: 1;
          .title {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #69ceff;
            letter-spacing: 1px;
            font-weight: 400;
            margin-bottom: 8px;
          }
          .fn-box {
            display: flex;
            justify-content: space-around;
            .fn {
              margin-left: 40px;
              .icon-box {
                cursor: pointer;
              }
              .text {
                font-family: MicrosoftYaHei;
                font-size: 14px;
                color: #b3bbc5;
                letter-spacing: 0;
                font-weight: 400;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .foot {
      height: 26px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: space-between !important;

      .fn {
        cursor: pointer;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #b3bbc5;
        letter-spacing: 0;
        font-weight: 400;
        margin-right: 11px;
      }
    }
  }
}

.el-radio {
  margin-right: 10px !important;
}
</style>
