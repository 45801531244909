<script>
import MessageModal from '@/components/common/message-modal/index.vue';
import { Control_API } from '@/api';
import { executionStatusDictionary, getDateMonthList } from '@/utils/dictionary';
import { mapState } from 'vuex';

export default {
  name: 'Index',
  components: { MessageModal },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    currentTaskId: {
      type: Number,
      default: () => 0
    },
    currentTab: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      taskDetail: {},
      taskInfo: [
        {
          label: '任务名称',
          value: '',
          key: 'taskName'
        },
        {
          label: '任务类型',
          value: '',
          key: 'processName'
        },
        {
          label: '航线名称',
          value: '',
          key: 'routeName'
        },
        {
          label: '执行状态',
          value: '',
          key: 'taskStatus',
          optionList: executionStatusDictionary
        },
        {
          label: '周期类型',
          value: '',
          key: 'type',
          optionList: [
            {
              label: '每日',
              value: 2
            },
            {
              label: '每周',
              value: 3
            },
            {
              label: '每月',
              value: 4
            }
          ]
        },
        {
          label: '周期日历',
          value: '',
          key: 'dayOfWeek',
          optionList: [
            {
              value: '1',
              label: '周一'
            },
            {
              value: '2',
              label: '周二'
            },
            {
              value: '3',
              label: '周三'
            },
            {
              value: '4',
              label: '周四'
            },
            {
              value: '5',
              label: '周五'
            },
            {
              value: '6',
              label: '周六'
            },
            {
              value: '7',
              label: '周日'
            }
          ]
        },
        {
          label: '周期日历',
          value: '',
          key: 'dayOfMonth',
          optionList: getDateMonthList()
        },
        {
          label: '周期执行时间',
          value: '',
          key: 'expectedtime'
        },
        {
          label: '定时执行时间',
          value: '',
          key: 'expectedtime'
        },
        {
          label: '有效期限',
          value: '',
          key: 'scheduledDate'
        },
        {
          label: '责任机构',
          value: '',
          key: 'orgName'
        },
        {
          label: '创建时间',
          value: '',
          key: 'addtime'
        },
        {
          label: '任务描述',
          value: '',
          key: 'remark'
        }
      ]
    };
  },
  computed: {
    ...mapState(['openLeftNavSilder']),
    covertTaskInfo() {
      return this.taskInfo
        .filter(
          (v) =>
            (this.currentTab === 0 &&
              !['周期类型', '周期日历', '周期执行时间', '定时执行时间', '有效期限'].includes(
                v.label
              )) ||
            (this.currentTab === 1 &&
              !['周期类型', '周期日历', '周期执行时间', '有效期限'].includes(v.label)) ||
            (this.currentTab === 2 &&
              !['定时执行时间'].includes(v.label) &&
              !(this.taskDetail.type === 2
                ? ['周期日历'].includes(v.label)
                : this.taskDetail.type === 3
                ? ['dayOfMonth'].includes(v.key)
                : ['dayOfWeek'].includes(v.key)))
        )
        .map((v) => ({
          ...v,
          value: Object.keys(this.taskDetail).some((i) => i === v.key)
            ? v.optionList
              ? v.optionList.find((j) => j.value === this.taskDetail[v.key])?.label || ''
              : this.taskDetail[v.key] || ' '
            : ''
        }));
    }
  },
  mounted() {
    if (this.currentTaskId) {
      this.getTaskDetail();
    }
  },
  methods: {
    //  获取任务详情
    getTaskDetail() {
      Control_API.getTaskDetails({ id: this.currentTaskId }).then(({ data }) => {
        if (data) {
          this.taskDetail = data;
          this.taskDetail.scheduledDate = [
            this.taskDetail.scheduledStart,
            this.taskDetail.scheduledEnd
          ].join('~');
        }
      });
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<template>
  <message-modal
    :show="show"
    title="任务详情"
    :width="400"
    :position-top="525"
    :position-left="openLeftNavSilder ? 450 : 70"
    @close="close"
  >
    <div class="task-detail">
      <div v-for="(v, index) in covertTaskInfo" :key="index" class="content-form-item">
        <div class="item-label">{{ v.label }}</div>
        <div class="item-value" :class="{ 'item-value-last': index === covertTaskInfo.length - 1 }">
          {{ v.value }}
        </div>
      </div>
    </div>
  </message-modal>
</template>

<style scoped lang="scss">
.task-detail {
  background: #1e2026;
  padding: 10px;
  width: 400px;
  box-sizing: border-box;
  .content-form-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    .item-label {
      width: 100px;
      margin-top: 8px;
      font-size: 12px;
      color: #b3bbc5;
      line-height: 16px;
      white-space: nowrap;
    }
    .item-value {
      flex: 1;
      background: #16181b;
      border-radius: 8px;
      border: 1px solid #233a58;
      padding: 6px 10px;
      font-size: 14px;
      color: #d8dde8;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &-last {
        min-height: 67px;
        word-break: break-all;
        overflow: visible;
        text-align: left;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
